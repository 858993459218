import partition from 'lodash/partition';

import { getConditions, RuleExpression } from '@commandbar/internal/middleware/helpers/rules';
import { DEEP_LINK_PARAMS } from '../../../components/helphub/Doc';

import type { HelpHubDoc } from '..';
import type { ICommandType, IHelpDocHitType, IRecommendationSet } from '@commandbar/internal/middleware/types';

export const commandToHelpHubDoc = (command: ICommandType, hit?: IHelpDocHitType): HelpHubDoc => {
  switch (command.template.type) {
    case 'video':
      return {
        type: 'video',
        commandID: command.id,
        title: hit?.title || command.text,
        excerpt: hit?.excerpt || command.explanation,
        content: hit?.content || command.content,
        icon: command.icon,
        thumbnail: command.thumbnail,
        command,
        videoUrl: command.template.value,
      };
    case 'link':
      return {
        type: 'link',
        commandID: command.id,
        title: hit?.title || command.text,
        excerpt: hit?.excerpt || command.explanation,
        content: hit?.content || command.content,
        icon: command.icon,
        thumbnail: command.thumbnail,
        command,
      };
    case 'helpdoc':
      return {
        type: 'helpdoc',
        commandID: command.id,
        title: hit?.title || command.text,
        excerpt: hit?.excerpt || command.explanation,
        content: hit?.content || command.content,
        icon: command.icon,
        thumbnail: command.thumbnail,
        command,
      };
    default:
      return {
        type: 'other',
        commandID: command.id,
        title: hit?.title || command.text,
        excerpt: hit?.excerpt || command.explanation,
        content: hit?.content || command.content,
        icon: command.icon,
        thumbnail: command.thumbnail,
        command,
      };
  }
};

export const draftDocToFullDoc = (draftDoc: Omit<HelpHubDoc, 'type' | 'commandID'>): HelpHubDoc => {
  const fullDoc = { ...commandToHelpHubDoc(draftDoc.command), ...draftDoc };

  return fullDoc;
};

const countConditions = (expression: RuleExpression): number => {
  const conditions = getConditions(expression);
  return conditions.length;
};

const scoreRecommendationSet = (recommendationSet: IRecommendationSet) => {
  const audienceScore =
    recommendationSet.audience.type === 'rule_expression' ? countConditions(recommendationSet.audience.expression) : 0;

  const showExpressionScore = countConditions(recommendationSet.show_expression);

  return audienceScore + showExpressionScore;
};

const findHighestScoringSet = (recommendationSets: IRecommendationSet[]) => {
  if (recommendationSets.length === 0) {
    return null;
  }

  return recommendationSets.reduce((highestScoringSet, recommendationSet) => {
    const highestScore = scoreRecommendationSet(highestScoringSet);
    const currentScore = scoreRecommendationSet(recommendationSet);

    if (currentScore > highestScore) {
      return recommendationSet;
    } else if (currentScore === highestScore) {
      // In case of a tie, prefer a non-default set over the default one
      return highestScoringSet.default && !recommendationSet.default ? recommendationSet : highestScoringSet;
    } else {
      return highestScoringSet;
    }
  }, recommendationSets[0]);
};

const isLocationSpecificCondition = (expression: RuleExpression) => {
  const conditions = getConditions(expression);
  return conditions.some((condition) => condition.type === 'url' || condition.type === 'element');
};

/**
 * Finds the most specific recommendation set based on the following precedence criteria:
 * 1. Location-specific sets (with 'url' or 'element' conditions) have the highest precedence.
 * 2. General sets (without 'url' or 'element' conditions) have the next highest precedence.
 *
 * Within each of the categories, the set with the highest score (calculated based on the number
 * of conditions in the audience and show_expression) is selected.
 * In case of a tie, a non-default set is preferred over the default one.
 */
export const findMostSpecificRecommendationSet = (recommendationSets: IRecommendationSet[]) => {
  if (recommendationSets.length === 0) {
    return null;
  }

  const [locationSpecificSets, generalSets] = partition(
    recommendationSets,
    (recommendationSet) =>
      isLocationSpecificCondition(recommendationSet.show_expression) ||
      (recommendationSet.audience.type === 'rule_expression' &&
        isLocationSpecificCondition(recommendationSet.audience.expression)),
  );

  const highestScoringLocationSpecific = findHighestScoringSet(locationSpecificSets);
  if (highestScoringLocationSpecific) {
    return highestScoringLocationSpecific;
  }

  return findHighestScoringSet(generalSets);
};

export const removeDeepLinkParams = () => {
  const currentUrl = new URL(window.location.href);

  const urlParams = currentUrl.searchParams;
  urlParams.delete(DEEP_LINK_PARAMS.COMMAND_ID);
  urlParams.delete(DEEP_LINK_PARAMS.QUERY);
  urlParams.delete(DEEP_LINK_PARAMS.SCROLL);

  window.history.replaceState({}, '', currentUrl);
};
