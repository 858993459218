import * as Command from '@commandbar/internal/middleware/command';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import * as Organization from '@commandbar/internal/middleware/organization';
import {
  ICommandCategoryType,
  ICommandType,
  IConfigType,
  IGuideType,
  IResourceSettingsByContextKey,
} from '@commandbar/internal/middleware/types';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import Logger from '@commandbar/internal/util/Logger';
import Analytics from '../analytics/Analytics';
import * as Reporting from '../analytics/Reporting';
import {
  AddContextOptions,
  ArgumentOptions,
  DataRow,
  MultiSearchFunction,
  RecordOptions,
} from '@commandbar/internal/client/AddContextOptions';
import {
  CASE,
  getCase,
  getFirstType,
  getSecondType,
  getThirdType,
  SECOND_ARG_INITVALUE_TYPE,
} from '@commandbar/internal/client/addContextValidators';
import {
  BootOptions,
  ContextLoader,
  Metadata,
  CallbackMap,
  CallbackFunction,
  UserAttributes,
  InstanceAttributes,
  CommandBarClientSDK,
  ASYNC_METHODS_SNIPPET,
  ASYNC_METHODS,
  CommandDetails,
  DEFAULT_INSTANCE_ATTRIBUTES,
  FormFactorConfig,
  CustomComponent,
  DEFAULT_META_ATTRIBUTES,
  MetaAttributes,
  PRODUCTS,
} from '@commandbar/internal/client/CommandBarClientSDK';
import { CommandBarProxyGlobal, isProxySDK } from '@commandbar/internal/client/CommandBarProxySDK';
import { CommandBarSDK, CommandBarInternalSDK, _reloadTargets } from '@commandbar/internal/client/CommandBarSDK';
import { isDisposable, dispose } from '@commandbar/internal/util/Disposable';
import { getProxySDK, getSDK, SDK_INTERNAL_PREFIX } from '@commandbar/internal/client/globals';
import {
  _access,
  _configure,
  _configuration,
  _configUser,
  _dispose,
  _disposed,
  _instanceAttributes,
  _isProxy,
  _loadEditor,
  _onEditorPathChange,
  _perf,
  _programmaticTheme,
  _queue,
  _reload,
  _reloadCommands,
  _reloadOrganization,
  _reloadPlaceholders,
  _reloadNudges,
  _reloadHelpHub,
  _previewNudge,
  _stopNudgePreview,
  _previewChecklist,
  _reloadChecklists,
  _report,
  _search,
  _sentry,
  _setDashboard,
  _setPreviewMode,
  _setTestMode,
  _setEditorVisible,
  _shareConfig,
  _shareProgrammaticCommands,
  _shareContextSettings,
  _showGuide,
  _showMessage,
  _user,
  _userAttributes,
  _orgConfig,
  _eventSubscriptions,
  _metaAttributes,
  _shareEditorRouteWithBar,
  _shareInitialEditorPath,
  _stopChecklistPreview,
  _fingerprint,
  _updateEditorRoute,
  _startDebug,
  _stopDebug,
  _previewRecommendationSet,
  _stopRecommendationSetPreview,
  _getDebugSnapshot,
  _showNudgeStepMock,
  _closeNudgeMock,
} from '@commandbar/internal/client/symbols';
import { State } from '../store';
import { bindActions } from '../store/util/bindActions';
import * as App from '../store/app/actions';
import * as Engine from '../store/engine/actions';
import { dispatchCustomEvent, TUpdateEditorRouteDetails } from '@commandbar/internal/util/dispatchCustomEvent';
import { unmountComponentAtNode } from 'react-dom';
import { isHTML } from './utils';
import Hotkey from '@commandbar/internal/client/Hotkey';
import { SUMMON_HOTKEY_SLUG } from '../constants';
import getConfig from './config';
import { ref, snapshot } from 'valtio';
import type { StepType } from '../engine/step/Step';
import {
  getCommandById,
  getCommands,
  getContextForExternalUse,
  getContextSettings,
  selectDefaultSummonHotkey,
  updateHotloadedHelpdocCommands,
} from '../store/engine';
import { MultiSearch } from '../store/util/MultiSearch';
import { IContextArgument } from '@commandbar/internal/middleware/ICommandFromClientType';
import { EventSubscriber } from '@commandbar/internal/client/EventHandler';
import { createFingerprint } from '../store/engine/end-user/helpers';
import { commandToHelpHubDoc } from '../store/engine/help-hub/helpers';
import helpdocService from '../services/helpdocService';
import { queryHelpDocs } from './search';
import { getAllNudgeServices, getAllNudges, getNudgeById, getNudgeService } from '../store/engine/nudges/selectors';
import { getSentry } from '@commandbar/internal/util/sentry';

/** An array of SDK methods that should be tracked for analytics. */
const TRACK_METHODS: Array<keyof CommandBarSDK> = [
  _setDashboard,
  _showGuide,
  _showMessage,
  'addCallback',
  'addCommand',
  'addContext',
  'addEventHandler',
  'addRouter',
  'boot',
  'close',
  'execute',
  'generateDetailPreview',
  'isOpen',
  'open',
  'removeCallback',
  'removeCommand',
  'removeContext',
  'setContext',
  'setTheme',
  'shareCallbacks',
  'shareContext',
  'shutdown',
  'trackEvent',
];

/**
 * "Upgrades" the `window.CommandBar` global object by augmenting it with the implementation of the entire private SDK.
 * The fact that the global window declaration uses symbols and a limited interface (CommandBarClientSDK) ensures that
 * private SDK methods are nearly invisible to the end user.
 */

// ensure that initSDK is safe to call multiple times and only one initialization will be attempted at once
let initializing = false;
export async function initSDK(_: State) {
  if (initializing) return null;

  try {
    initializing = true;
    return _initSDK(_);
  } finally {
    initializing = false;
  }
}
async function _initSDK(_: State) {
  const proxy = getProxySDK();

  if (!isProxySDK(proxy)) return getSDK();

  Logger.info('initializing client SDK...');

  const app = bindActions(_, App);
  const engine = bindActions(_, Engine);

  let sdk: CommandBarSDK = null as unknown as CommandBarSDK;

  const externalSdkMixin: CommandBarClientSDK = {
    addCallback(callbackKey, callbackFn) {
      if (callbackKey.startsWith('commandbar-')) {
        Logger.warn('callback name is reserved');
        return;
      }
      engine.addCallbacks({ [callbackKey]: callbackFn });
    },
    addCallbacks(callbacks) {
      if (
        !!Object.keys(callbacks).find((n: string) => {
          return n.includes('commandbar-');
        })
      ) {
        Logger.warn('One or more of the callback names are reserved.');
        return;
      }
      engine.addCallbacks(callbacks);
    },
    async addCommand(command) {
      // Make sure command is specified correctly
      await Command.validateFromClient(command);

      // FIXME: we should add validators here
      // Some can be shared with the editor
      // (1) Ensure shortcut is invalid
      // (2) Ensure no command's shortcut collides with another command
      // Some are unique to programmatic commands
      // (1) Check that name is unique

      const shortcut_mac = command.shortcut_mac || [];
      const shortcut_win = command.shortcut_win || [];
      const hotkey_mac = command.hotkey_mac || Hotkey.toString(shortcut_mac, 'mac');
      const hotkey_win = command.hotkey_win || Hotkey.toString(shortcut_win, 'win');

      let category = command.category || null;
      if (typeof category === 'string') {
        const c = _.engine.categories.find((category) => category.name === command.category);
        if (c) {
          category = c.id;
        } else {
          category = app.addCategory(category);
        }
      }

      const formattedCommand: ICommandType = Command.decode({
        text: command.text,
        template: command.template,
        name: command.name,
        tags: command.tags || [],
        detail: command?.detail || null,
        content: command?.content || null,
        show_preview: command?.show_preview || false,
        shortcut_mac,
        shortcut_win,
        hotkey_mac,
        hotkey_win,
        explanation: command.explanation || '',
        heading: command.heading || '',
        sort_key: command.sort_key || null,
        arguments: command.arguments || {},
        availability_rules: command.availability_rules || [],
        recommend_rules: command.recommend_rules || [],
        confirm: '',
        shortcut: [],
        is_live: true,
        source: 'programmatic',
        id: Math.random(), // placeholder
        organization: -1,
        category,
        icon: command.icon || null,
        image: command.image || null,
        celebrate: command.celebrate || null,
        recommend_sort_key: null,
        extra: command.extra || null,
      });

      engine.addCommand(formattedCommand);
    },
    setCategoryConfig(category: string | number, config: Partial<ICommandCategoryType>) {
      let categoryId;
      if (typeof category === 'number') {
        categoryId = category;
      } else {
        categoryId = _.engine.categories.find((c) => c.name === category)?.id ?? app.addCategory(category);
      }

      app.setCategoryConfig(categoryId, config);
    },

    addArgumentChoices(key: string, initial: DataRow[], options?: ArgumentOptions) {
      const addContextOptions: AddContextOptions = {
        renderOptions: {
          labelKey: options?.labelKey,
          descriptionKey: options?.descriptionKey,
          defaultIcon: options?.defaultIcon,
          detail: options?.detail,
          content: options?.content,
          show_preview: options?.show_preview,
          renderAs: options?.renderAs,
          searchTabEnabled: options?.searchTabEnabled,
        },
        searchOptions: {
          fields: options?.searchableFields,
          searchFunction: options?.onInputChange,
        },
        quickFindOptions: {
          quickFind: false,
        },
      };

      this.addContext(key, initial, addContextOptions);
    },

    resetNudge(id: number, step?: number) {
      const service = getNudgeService(_, id);

      service?.send({
        type: 'RESET_STATE',
        step: step || 0,
      });

      engine.clearNudgeDataById(id, step);
    },

    addMetadata(key: string, initial: ContextLoader | unknown, addToUserProperties = false) {
      this.addContext(key, initial);

      if (addToUserProperties) {
        sdk[_userAttributes] = { ...sdk[_userAttributes], [key]: initial };
      }
    },

    addMetadataBatch(data, addToUserProperties = false) {
      this.addContext({ ...data });
      if (addToUserProperties) {
        sdk[_userAttributes] = { ...sdk[_userAttributes], ...data };
      }
    },
    trackEvent(key: string, _properties: Metadata) {
      if (key.startsWith('commandbar-')) {
        Logger.warn('event name is reserved');
        return;
      }

      Engine.trackEvent(_, key, 'app');
    },

    addMultiSearch(onInputChange: MultiSearchFunction, keys: string[]) {
      const multiSearch = new MultiSearch(onInputChange);

      keys.forEach((key) => {
        const searchFunction = multiSearch.createSearchFunction(key);

        const callbackKey = `commandbar-search-${key}`;
        engine.addCallbacks({ [callbackKey]: searchFunction });
      });
    },

    addContext(_key: string | Metadata, initialValue?: ContextLoader | unknown, options?: AddContextOptions) {
      const firstArgType = getFirstType(_key);
      const secondArgType = getSecondType(initialValue);
      const thirdArgType = getThirdType(options);

      const _case = getCase(firstArgType, secondArgType, thirdArgType);

      let context: Record<string, unknown> | string = {};
      const callbacks: CallbackMap = {};
      let callbackKey;

      switch (_case) {
        case CASE.OLD_ADD_CONTEXT:
          // OLD -- add object to context
          context = _key;
          engine.addContext(context as Record<string, unknown>);
          return;

        case CASE.NEW_ADD_CONTEXT:
          const key = _key as string;

          // Handle second argument: initialValue
          switch (secondArgType) {
            // Static initial value -> add to context
            case SECOND_ARG_INITVALUE_TYPE.STATIC:
              context[key] = initialValue;
              break;
            // Function iniital value -> add to callbacks. Set context to an empty array.
            case SECOND_ARG_INITVALUE_TYPE.FUNCTION:
              const initialValueFnKey = `commandbar-initialvalue-${key}`;
              callbacks[initialValueFnKey] = initialValue as CallbackFunction<unknown>;
              if (!_.engine.context.hasOwnProperty(key)) {
                const loaderFn = initialValue as ContextLoader;

                // Call loader eagerly if context isn't already set
                // using both promise & async/await because promise ensures it doesn't block the rest
                // of addContext, and async/await is friendly to loaders that are both sync and async
                new Promise(async (resolve, reject) => {
                  try {
                    const results = await loaderFn();
                    resolve(results);
                  } catch (e) {
                    reject(e);
                  }
                })
                  .then((results) => engine.addContext({ [key]: results }))
                  .catch((e: Error) => {
                    getSentry()?.captureException(e);

                    Logger.error(`Function loader caused an error. Key: ${key}`, e);

                    engine.addContext({ [key]: [] });
                  });
              }
              break;
          }

          // Handle third argument: meta options
          // Special case: search
          if (options?.searchOptions?.searchFunction) {
            const customSearchFunction = options?.searchOptions?.searchFunction;
            if (typeof customSearchFunction !== 'function') {
              Logger.error('CustomSearchFunction is not a function');
            }
            callbackKey = `commandbar-search-${key}`;
            callbacks[callbackKey] = customSearchFunction;
          }

          // FIXME: Valdiate type for meta options
          // Handle the rest of the options
          let partialContextSettings: IResourceSettingsByContextKey | undefined;
          if (options) {
            partialContextSettings = {
              [key]: {
                description_field: options?.renderOptions?.descriptionKey,
                label_field: options?.renderOptions?.labelKey,
                icon: options?.renderOptions?.defaultIcon,
                detail: options?.renderOptions?.detail,
                content: options?.renderOptions?.content,
                show_preview: options?.renderOptions?.show_preview,
                search: options?.quickFindOptions?.quickFind,
                unfurl: options?.quickFindOptions?.unfurl,
                auto_execute: options?.quickFindOptions?.autoExecute,
                name: options?.quickFindOptions?.categoryName,
                sort_key: options?.quickFindOptions?.categorySortKey,
                showResources: options?.quickFindOptions?.showInDefaultEmptyState,
                max_options_count: options?.quickFindOptions?.maxOptionsCount,
                search_fields: options?.searchOptions?.fields,
                sortFunction: options?.searchOptions?.sortFunction,
                onInputChangeOptions: {
                  applySort: options?.searchOptions?.onInputChangeOptions?.applySort,
                },
                render_as: options?.renderOptions?.renderAs,
                search_tab_enabled: options?.renderOptions?.searchTabEnabled,
              },
            };
          }

          engine.setContextState(callbacks, context, partialContextSettings);

          return;

        default:
          Logger.error(_case, _key, initialValue, options);
          return;
      }
    },

    async addEventHandler(eventHandler) {
      if (!!_.engine.organization?.allow_event_handlers) {
        engine.addCallbacks({ 'commandbar-event-handler': eventHandler });
      } else {
        Logger.warn('Event handlers are only available for enterprise customers. Please contact CommandBar.');
      }
    },

    async addEventSubscriber(listener: EventSubscriber) {
      const eventSubscriptions = sdk[_eventSubscriptions];
      if (!eventSubscriptions) throw new Error('SDK not initialized properly -- event subscriptions cannot be added');

      if (!_.engine.organization?.allow_event_handlers) {
        Logger.warn('Event handlers are only available for enterprise customers. Please contact CommandBar.');
        return () => {
          return;
        };
      }

      const s = Symbol();
      eventSubscriptions.set(s, listener);

      return () => {
        eventSubscriptions.delete(s);
      };
    },

    async addRecordAction(recordKey, action, isDefault, showInDefaultList = false) {
      await Command.validateFromClient(action);

      // ensure that "record" always has lowest order_key
      let order_key = 1;
      if (action.arguments) {
        Object.values(action.arguments || {}).forEach((a) => {
          if (order_key >= a.order_key) {
            order_key = a.order_key - 1;
          }
        });
      }

      const record: IContextArgument = {
        type: 'context',
        value: recordKey,
        order_key,
        label: 'Select from the list below',
        show_in_record_action_list: true,
        show_in_default_list: showInDefaultList,
      };

      const actionWithArguments = {
        ...action,
        arguments: {
          record,
          ...action.arguments,
        },
      };

      this.addCommand(actionWithArguments);

      // overwrite default command if record has no default or isDefault is true
      const hasDefaultCommand = !!getContextSettings(_.engine)[recordKey]?.default_command_id;
      if (isDefault || !hasDefaultCommand) {
        engine.updateLocalContextSetting(recordKey, 'default_command_id', action.name);
      }
    },

    addRecords(key: string, initial: DataRow[], options?: RecordOptions) {
      const addContextOptions: AddContextOptions = {
        renderOptions: {
          labelKey: options?.labelKey,
          descriptionKey: options?.descriptionKey,
          defaultIcon: options?.defaultIcon,
          detail: options?.detail,
          content: options?.content,
          show_preview: options?.show_preview,
          renderAs: options?.renderAs,
          searchTabEnabled: options?.searchTabEnabled,
        },
        searchOptions: {
          fields: options?.searchableFields,
          searchFunction: options?.onInputChange,
          onInputChangeOptions: {
            applySort: options?.onInputChangeOptions?.applySort,
          },
        },
        quickFindOptions: {
          categoryName: options?.recordOptions?.categoryName,
          unfurl: options?.recordOptions?.unfurl,
          categorySortKey: options?.recordOptions?.categorySortKey,
          showInDefaultEmptyState: options?.recordOptions?.showInDefaultEmptyState,
          maxOptionsCount: options?.recordOptions?.maxOptionsCount,
          quickFind: true,
        },
      };

      this.addContext(key, initial, addContextOptions);
    },

    addRouter(routerFn) {
      engine.addCallbacks({ 'commandbar-router': routerFn });
    },
    addSearch(name, func) {
      const callbackKey = `commandbar-search-${name.replace(/\s+/g, '')}`;
      engine.addCallbacks({ [callbackKey]: func });
    },
    async setFormFactor(formFactor: FormFactorConfig) {
      _.engine.formFactor = ref(formFactor); // formFactor can include an HTMLElement, so we need to use ref

      // this is used by Analytics.tsx
      sdk[_instanceAttributes].formFactor = formFactor;
    },
    async boot(
      opts: BootOptions,
      userAttributes?: UserAttributes,
      instanceAttributes?: Partial<InstanceAttributes>,
      metaAttributes: Partial<MetaAttributes> = {},
    ) {
      Logger.info('booting...', opts, userAttributes, instanceAttributes);
      // Boot examples:
      //   Anonymous user: .boot()
      //   Old: .boot({id: 'someid', org: 'someorg'})
      //   New: .boot('someid')
      //   New w/ event attrs: .boot('someid', {org: 'someorg'})

      if (sdk[_configuration].uuid === undefined) {
        throw new Error(
          'CommandBar: Organization ID is undefined. Call config() to set the organization ID before calling boot().',
        );
      }

      let userId: string | null | undefined;
      let ctx = {};
      if (!opts) {
        userId = undefined;
      } else if (typeof opts === 'string') {
        userId = opts;
        ctx = { ...userAttributes };
      } else if (typeof opts === 'object') {
        ({ id: userId, ...ctx } = opts);
        if (typeof opts?.id === 'string') {
          userId = opts.id;
        } else {
          Logger.warn(
            "Booting anonymous user because provided boot object didn't include an id key with a string value",
          );
        }
      } else {
        Logger.error('Boot parameter should be a string or key-value pair');
      }

      // null means anonymous user
      if (!userId) {
        userId = null;
      }

      engine.addContext({ ...ctx, id: userId });

      // Activate the CommandBar
      _.active = true;

      // Store boot data to pass back to client eventHanlder
      sdk[_userAttributes] = { ...userAttributes };

      // Store instance attributes
      sdk[_instanceAttributes] = { ...DEFAULT_INSTANCE_ATTRIBUTES, ...instanceAttributes };

      // Store meta attributes -- e.g. version of WP Admin plugin (used for analytics)
      sdk[_metaAttributes] = { ...DEFAULT_META_ATTRIBUTES, ...metaAttributes };

      const LEGACY_INLINE_BAR: { [org_uuid: string]: boolean } = {
        '5725a2a3': true,
        ec420f00: true,
        dee37b08: true,
      };
      if (LEGACY_INLINE_BAR[sdk[_configuration].uuid]) {
        if (!instanceAttributes || !('formFactor' in instanceAttributes)) {
          sdk[_instanceAttributes] = {
            ...sdk[_instanceAttributes],
            formFactor: { type: 'inline', rootElement: 'commandbar-inline-root' },
          };
        }
      }

      _.engine.formFactor = ref(sdk[_instanceAttributes].formFactor); // formFactor can include an HTMLElement, so we need to use ref
      _.engine.products = sdk[_instanceAttributes].products;

      // CASE 0: Same user has been booted already. Don't re-load user
      if (sdk[_user] === userId) {
        Logger.info('Same user has been booted already. Not re-loading user');
        return Promise.resolve();
      }

      sdk[_user] = userId;
      Analytics.setBootStatus(true);

      // CASE 1: New identified user boot -> !!userId
      if (
        !!userId &&
        !sdk[_configuration].airgap &&
        (sdk[_orgConfig].silent === false || !!_.engine.organization?.end_user_shortcuts_enabled)
      ) {
        try {
          // Fetch (and create if required) the endUser from our DB
          const endUser = await Organization.userHasAccess(sdk[_configuration].uuid, userId);
          _.engine.endUser = { ...endUser, hmac: instanceAttributes?.hmac };
        } catch (e) {
          // Boot often can be called after login, right before a page redirect
          // Unless the client removes the onclick event from the source element (e.preventDefault)
          // then the request gets cancelled when the source element is removed from the page
          // We want to ignore these types of errors
        }
      } else {
        // Case 1: Anonymous user -> userId = null
        _.engine.endUser = null;
        sdk[_fingerprint] = createFingerprint();
      }

      // Wait until user has been fetched & created before we fire analytics events
      Reporting.startSession(sdk[_configuration].session);
      Analytics.identify({ user_attributes: JSON.stringify({ ...ctx, id: userId }) });
    },
    close() {
      app.closeBarAndReset();
    },
    closeAllNudges() {
      getAllNudgeServices(_)?.forEach((service) => {
        service?.send('DISMISS');
      });
    },
    execute(id) {
      const command = getCommandById(_.engine, id);

      const executeCommand = (command: ICommandType) => {
        engine.executeCommand(command, undefined, () => {
          // REVIEW: Should this be going to analytics or Sentry? It may be more appropriate to throw instead.
          Logger.error(`Command with id ${id} is unavailable.`);
        });
      };

      if (command) {
        executeCommand(command);
      } else if (_.engine.organization?.id) {
        helpdocService.getHelpdocCommand(_.engine.organization.id, id).then((command) => {
          if (command) {
            executeCommand(command);
          } else {
            // report to client that the command wasn't found
            // REVIEW: Should this be going to analytics or Sentry? It may be more appropriate to throw instead.
            Logger.error(`Command with id=${id} wasn't found.`);
          }
        });
      } else {
        Logger.error(`CommandBar wasn't initialized successfully`);
      }
    },

    generateDetailPreview(fn) {
      _.engine.detailPreviewGenerator = fn;
    },
    addComponent(key: string, name: string, component: CustomComponent) {
      _.engine.components[key] = { name, component };
    },
    removeComponent(key: string) {
      delete _.engine.components[key];
    },

    getCommands(filter = () => true) {
      const shortcutEntries: CommandDetails[] = [];

      getCommands(_.engine).forEach((command) => {
        let customShortcut: string | undefined = undefined;

        const cmdUID = Command.commandUID(command);
        if (!!_.engine.endUserStore.data.hotkeys?.[cmdUID]) {
          customShortcut = _.engine.endUserStore.data.hotkeys[cmdUID];
        }

        const platformString = _.engine.platform === 'mac' ? 'mac' : 'win';

        const commandDetails = {
          command: command?.id > 1 ? command.id : command.name,
          commandText: command.text,
          category: command.category,
          callbackKey: command.template.type === 'callback' ? command.template.value : undefined,
          source: command.source || 'standard',
          shortcut_mac: Hotkey.toPlatformSpecificString(command.hotkey_mac, 'mac'),
          shortcut_win: Hotkey.toPlatformSpecificString(command.hotkey_win, 'win'),
          customShortcut: customShortcut ? Hotkey.toPlatformSpecificString(customShortcut, platformString) : undefined,
        };

        if (filter(commandDetails)) {
          shortcutEntries.push(commandDetails);
        }
      });

      return shortcutEntries;
    },
    isOpen() {
      return _.engine.visible;
    },
    open(input, options) {
      app.openBarWithOptionalText('programmatic', {
        startingInput: input,
        categoryFilterID: options?.categoryFilter,
      });
    },
    openEditor() {
      App.openEditor(_);
    },

    async openHelpHub(options: { query?: string; articleId?: number | null } = {}) {
      const orgId = _.engine.organization?.id;
      let article;

      if (options.articleId && orgId) {
        const command =
          getCommandById(_.engine, options.articleId) ||
          (await helpdocService.getHelpdocCommand(orgId, options.articleId));
        if (command?.training_only) return;

        article = command ? commandToHelpHubDoc(command) : undefined;
      } else if (options.articleId === null) {
        article = null;
      }

      engine.setHelpHubVisible(true, { query: options.query, article });
    },

    closeHelpHub() {
      engine.setHelpHubVisible(false);
    },
    toggleHelpHub() {
      engine.setHelpHubVisible(!_.engine.helpHub.visible);
    },
    removeCallback(callbackKey) {
      engine.removeCallback(callbackKey);
    },
    removeCommand(commandName) {
      // opposite of addCommand -- only for programmatic commands

      // FIXME: what should we do if name doesn't correspond to a command?
      // TODO: We should also tell Proxy that it can remove the command
      engine.removeCommand(commandName);
    },
    removeContext(keyToRemove) {
      engine.removeContext(keyToRemove);
    },
    // if input is null we remove binding
    setSummonHotkey(hotkey) {
      let toSet = null;

      if (hotkey === null) {
        toSet = '';
      } else if (hotkey) {
        hotkey = Hotkey.toModString(Hotkey.normalize(hotkey), _.engine.platform === 'mac' ? 'mac' : 'win');
        if (Hotkey.validate(hotkey)) {
          toSet = hotkey;
        }
      }

      if (toSet === selectDefaultSummonHotkey(_)) {
        toSet = undefined;
      }

      if (toSet !== null) {
        const endUserShortcuts = engine.updateEndUserHotkey(SUMMON_HOTKEY_SLUG, toSet);
        return !!endUserShortcuts && (endUserShortcuts as any)[SUMMON_HOTKEY_SLUG] === toSet;
      }

      Logger.error(`[CommandBar] ${hotkey} is an invalid hotkey.`);
      return false;
    },

    setCustomComponent(slug, getComponent) {
      const organization = _.engine.organization;
      const allowCustomization = organization?.branding !== 'branded' || organization?.id === 'af6750c6';
      if (!!allowCustomization) {
        const validateComponent = (
          _getComponent?: (meta?: {
            step?: Exclude<StepType, StepType.Execute>;
            activeTab?: string;
          }) => string | CustomComponent,
          meta?: { step?: Exclude<StepType, StepType.Execute>; activeTab?: string },
        ) => {
          if (!_getComponent) return null;
          if (typeof _getComponent !== 'function') return null;

          const _component = _getComponent({ step: meta?.step, activeTab: meta?.activeTab });
          if (typeof _component === 'string' && !isHTML(_component)) {
            Logger.warn('Invalid HTML template.');
            return null;
          } else {
            return _component;
          }
        };

        const component = (meta?: { step?: Exclude<StepType, StepType.Execute>; activeTab?: string }) =>
          validateComponent(getComponent, { step: meta?.step, activeTab: meta?.activeTab });
        _.userDefinedCustomComponents[slug] = component;
      } else {
        Logger.warn('Custom UI components are only available for enterprise customers. Please contact CommandBar.');
      }
    },
    setContext(context, meta) {
      if (meta !== undefined) {
        if (meta?.useCustom && meta?.customID) {
          LocalStorage.set('customcontext', meta.customID);
        } else {
          LocalStorage.remove('customcontext');
        }
      } else {
        LocalStorage.remove('customcontext');
      }

      engine.setContext(context);
    },
    setTheme(theme, color) {
      sdk[_programmaticTheme] = typeof theme === 'string' ? theme : '';
      engine.setBaseTheme(theme, 'setThemeFunction', color);
    },
    shareCallbacks() {
      const callbacks = snapshot(_.engine.callbacks);

      return Object.fromEntries(
        Object.entries(callbacks).filter(
          ([key]) =>
            !key.startsWith(SDK_INTERNAL_PREFIX) && !key.startsWith('commandbar-initialvalue-' + SDK_INTERNAL_PREFIX),
        ),
      );
    },
    shareComponentNamesByKey() {
      const result: { [key: string]: string } = {};

      for (const [key, value] of Object.entries(_.engine.components)) {
        if (!value) continue;
        result[key] = value.name;
      }

      return result;
    },
    shareContext() {
      return getContextForExternalUse(_, true);
    },
    isUserVerified() {
      return _.engine.endUserStore.verified;
    },
    shareState() {
      return {
        isBooted: Analytics.hasBooted,
        currentInput: _.engine.inputText,
        config: {
          helphub_enabled: _.engine.organization?.helphub_enabled ?? false,
        },
        searchFilter: _.searchFilter?.inputTag,
      };
    },
    shutdown() {
      _.active = false;
      Analytics.setBootStatus(false);
      sdk[_user] = undefined;
      engine.setContext({});
    },
    triggerSearchFunctions() {
      const savedInput = _.engine.inputText;
      _.engine.inputText = ' ';
      _.engine.inputText = savedInput;
    },
    updateContextSettings(key, settings) {
      engine.setLocalContextSettings(key, { ...settings });
    },
    unmount() {
      const container = document.getElementById('commandbar');
      if (container) unmountComponentAtNode(container);
    },
  };
  const internalSDKMixin: CommandBarInternalSDK = {
    [_access]: undefined,
    async [_configure](uid) {
      const airgap = !!sdk[_configuration].config;
      sdk[_configuration].airgap = airgap;
      sdk[_configuration].uuid = uid;

      const org_id_takeover = localStorage.getItem('commandbar.takeover');
      if (org_id_takeover) {
        sdk[_configuration].uuid = org_id_takeover;
      }

      _.env = sdk[_configuration].environment ?? null;
      _.version = sdk[_configuration].version ?? null;
      _.airgap = airgap;

      await sdk[_configUser]();
      dispatchCustomEvent('commandbar-configure-complete', {});
    },
    async [_configUser]() {
      const access = (window as any)?.CommandBarProxy?._access;

      if (!!access) {
        LocalStorage.set('access', access);
        // FIXME
        // Clone of /internal/middleware/network::Auth.user
        const { success, user } = await axiosInstance
          .get('/auth/current/')
          .then((result: any) => {
            return { success: true, user: result.data, error: undefined };
          })
          .catch((err: any) => {
            const error = err?.response?.data?.detail ?? 'Invalid.';
            return { success: false, error, user: undefined };
          });

        if (!success) {
          LocalStorage.remove('access');
          (window as any).CommandBarProxy._access = '';
        }

        if (user?.organization === sdk[_configuration].uuid) {
          engine.setIsAdmin(true);
          const _testMode = !!LocalStorage.get('testMode', '');
          engine.setTestMode(_testMode);

          try {
            app.setEnvOverride(JSON.parse(LocalStorage.get('envOverride', 'null') as string));
          } catch (e) {
            getSentry()?.captureException(e);

            console.warn('Failed to parse env override from LocalStorage', { error: e });
          }
        } else {
          engine.setIsAdmin(false);
        }
      } else {
        engine.setIsAdmin(false);
      }

      const {
        commands,
        categories,
        organization,
        environments,
        placeholders,
        nudges,
        checklists,
        tabs,
        helphub_recommendation_sets,
        helphub_additional_resources,
      } = await getConfig(_);
      engine.setCommands(commands);
      engine.setTabs(tabs);
      _.engine.environments = environments ?? null;
      app.setCategories(categories);
      engine.setOrganization(organization, 'initial_load');
      engine.setPlaceholders(placeholders);
      engine.initNudges(nudges || []);
      engine.setChecklists(checklists || []);
      engine.setHelpHubRecommendationSets(helphub_recommendation_sets || []);
      _.engine.helpHub.additionalResources = helphub_additional_resources ?? null;
    },
    [_configuration]: {
      api: '',
      editor: '',
      proxy: '',
      session: '',
      uuid: '',
      airgap: false,
      ...proxy[_configuration],
    },
    [_dispose]() {
      if (proxy[_disposed]) return;
      proxy[_disposed] = true;
      // Empty the SDK call queue if being disposed shortly after boot (this can easily happen in test suites)
      proxy[_queue].length = 0;
      Object.keys(proxy).forEach((k) => {
        const v = proxy[k];
        if (isDisposable(v)) dispose(v);
        delete proxy[k];
      });

      // In test runs this prevents errors from happening with search calls resolving while another test is busy executing
      _.searchOptionsDebouncer.cancel();
      Object.assign(window, { CommandBar: undefined });
    },
    [_disposed]: false,
    [_userAttributes]: undefined,
    [_instanceAttributes]: DEFAULT_INSTANCE_ATTRIBUTES,
    [_metaAttributes]: DEFAULT_META_ATTRIBUTES,
    [_isProxy]: false,
    [_loadEditor](path?: string) {
      const configuration = sdk[_configuration];
      if (!configuration || !configuration.api || !configuration.uuid || !configuration.proxy) {
        Logger.warn('configuration details are missing; cannot open editor');
        return;
      }
      if (!!document.getElementById('commandbar-proxy-wrapper')) {
        Logger.warn('proxy wrapper not present; cannot open editor');
        return;
      }

      let override = '';
      let domain = configuration?.api ?? 'https://api.commandbar.com';
      if (configuration.proxy !== 'https://frames-proxy-prod.commandbar.com') {
        override = `?source=${configuration.proxy}`;

        if (configuration.proxy.includes('localhost')) {
          domain = 'http://localhost:8000';
        }
      }

      const endpoint = `${domain}/latest_aux/${configuration.uuid}${override}`;
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = endpoint;
      if (endpoint.includes('localhost')) s.crossOrigin = 'anonymous';
      const x = document.getElementsByTagName('script')[0];
      x.parentNode && x.parentNode.insertBefore(s, x);

      // (optional) initial path which will be used when editor is loaded
      _.initialEditorPath = path ?? null;
    },
    [_shareInitialEditorPath]() {
      // used by Editor to get the initial path (via Proxy)
      return _.initialEditorPath;
    },
    [_shareEditorRouteWithBar](path: string) {
      _.editorPathChangeListeners.forEach((l) => l(path));
    },
    [_onEditorPathChange](notify: (path: string) => void) {
      _.editorPathChangeListeners.push(notify);
      return () => {
        _.editorPathChangeListeners = _.editorPathChangeListeners.filter((l) => l !== notify);
      };
    },
    [_orgConfig]: {
      ...proxy[_orgConfig],
      silent: !!localStorage.getItem('commandbar.takeover') ? true : proxy[_orgConfig]?.['silent'],
    },
    // REVIEW: What is the right way to initialize this? It will always equal false as-is.
    [_perf]: false,
    [_programmaticTheme]: undefined,
    [_eventSubscriptions]: new Map(),
    async [_reload](reloadTargets: (typeof _reloadTargets)[number][] = _reloadTargets) {
      const config = await getConfig(_);

      for (const method of reloadTargets) {
        const symbol = Symbol.for(`CommandBar::${String(method)}` as any) as
          | typeof _reloadCommands
          | typeof _reloadOrganization
          | typeof _reloadPlaceholders
          | typeof _reloadNudges
          | typeof _reloadChecklists
          | typeof _reloadHelpHub;

        const fn = sdk[symbol];

        if (fn instanceof Function) {
          fn(config);
        }
      }
    },
    async [_reloadCommands](preLoadedConfig?: IConfigType) {
      const config = preLoadedConfig || (await getConfig(_));
      const { commands, categories, organization, environments } = config;
      engine.setCommands(commands);

      _.engine.environments = environments ?? null;
      app.setCategories(categories);
      engine.setOrganization(organization, 'initial_load');
    },
    async [_reloadOrganization](preLoadedConfig?: IConfigType) {
      sdk[_programmaticTheme] = undefined;
      const { organization, environments, helphub_additional_resources, helphub_recommendation_sets } =
        preLoadedConfig || (await getConfig(_));
      engine.setOrganization(organization, 'reload_organization');
      _.engine.helpHub.additionalResources = helphub_additional_resources ?? null;
      _.engine.helpHub.recommendationSets = helphub_recommendation_sets ?? null;
      _.engine.environments = environments ?? null;
    },
    async [_reloadPlaceholders](preLoadedConfig?: IConfigType) {
      const { placeholders } = preLoadedConfig || (await getConfig(_));
      engine.setPlaceholders(placeholders);
    },
    async [_reloadNudges](preLoadedConfig?: IConfigType) {
      const { nudges } = preLoadedConfig || (await getConfig(_));
      engine.initNudges(nudges || []);
    },
    async [_reloadHelpHub](preLoadedConfig?: IConfigType) {
      const { visible, hubDoc, query } = _.engine.helpHub;
      const orgId = _.engine.organization?.id;

      const { helphub_recommendation_sets } = preLoadedConfig || (await getConfig(_));
      _.engine.helpHub.recommendationSets = helphub_recommendation_sets ?? null;

      if (orgId) {
        // if we don't have a recommendation set yet, then the results will be populated from
        // search results. We need to refresh the search results to populate changes from the editor.
        if (_.engine.helpHub.recommendationSets?.length < 1) {
          const docs = await queryHelpDocs(orgId, query ?? '', _.engine?.endUser);
          _.engine.helpHub.searchResults = docs;
        }

        updateHotloadedHelpdocCommands(_);

        if (hubDoc) {
          const doc = await helpdocService.getHelpdoc(orgId, hubDoc.commandID);

          if (doc) {
            engine.setHelpHubVisible(visible, {
              query: query ?? undefined,
              article: doc,
            });
          } else {
            engine.setHelpHubDoc(null);
          }
        }
      }
    },
    [_previewNudge](data) {
      const { nudge } = data;

      // TODO: remove this when we implement simulate mode
      engine.clearNudgeData(nudge);

      if (window.CommandBar.isOpen()) {
        window.CommandBar.close();
      }

      engine.previewNudge(nudge);
    },
    [_stopNudgePreview]() {
      _.engine.nudgeManager?.send('STOP_PREVIEW');
    },
    [_showNudgeStepMock](data) {
      if (window.CommandBar.isOpen()) {
        window.CommandBar.close();
      }

      engine.showStepMock(data.nudge, data.stepIndex);
    },
    [_closeNudgeMock](data) {
      engine.closeNudgeMock(data.nudge, true);
    },
    [_previewChecklist](data) {
      const { checklist, clearData } = data;
      if (clearData) {
        engine.clearChecklistData(checklist);
      }
      if (window.CommandBar.isOpen()) {
        window.CommandBar.close();
      }

      engine.showChecklist(checklist);
    },
    [_stopChecklistPreview]() {
      engine.hideChecklist();
    },
    [_previewRecommendationSet](data) {
      if (typeof data?.recommendationSetId !== 'undefined') {
        engine.setPreviewRecommendationSet(data.recommendationSetId);
      } else {
        engine.setPreviewRecommendationSet(null);
      }
    },
    [_stopRecommendationSetPreview]() {
      engine.setPreviewRecommendationSet(null);
    },
    async [_reloadChecklists]() {
      const { checklists } = await getConfig(_);
      engine.setChecklists(checklists || []);
    },
    [_report](event, data) {
      Analytics.log(event, { ...data, user_event: true });
    },
    [_search]: '',
    [_sentry]: proxy[_sentry],
    [_setDashboard](_element) {
      // DEPRECATED
      // only track events for non-admins
      // engine.setVisible(true);
      // return;
      // app.setDashboard(element);
    },
    [_setPreviewMode](on) {
      if (_.engine.isAdmin) {
        _.previewMode = on;
      }
    },
    [_setTestMode](on) {
      // we could require admin authentication here
      // e.g. pass in a secret or admin credentials
      if (_.engine.isAdmin) {
        engine.setTestMode(on);
      }
    },
    [_setEditorVisible](visible) {
      _.isEditorVisible = visible;
    },
    [_shareConfig]() {
      return {
        commands: _.engine.commands,
        categories: _.engine.categories,
        organization: _.engine.organization || undefined,
        environments: _.engine.environments || undefined,
        placeholders: _.engine.placeholders,
        nudges: getAllNudges(_),
        checklists: _.engine.checklists,
        tabs: _.engine.tabs,
      };
    },
    [_shareProgrammaticCommands]() {
      return _.engine.programmaticCommands;
    },
    [_shareContextSettings]() {
      const _localContextSettings = snapshot(_.engine.localContextSettings) as typeof _.engine.localContextSettings;
      const serverContextSettings = snapshot(_.engine.serverContextSettings) as typeof _.engine.serverContextSettings;

      // filter internal context settings from local context settings
      const localContextSettings = Object.fromEntries(
        Object.entries(_localContextSettings).filter(([key]) => !key.startsWith(SDK_INTERNAL_PREFIX)),
      );

      return {
        local: localContextSettings,
        server: serverContextSettings,
      };
    },
    [_showGuide](eventName, preview) {
      let thisGuide = _.guides.find((guide: IGuideType) => {
        return guide.event === eventName;
      });

      if (thisGuide === undefined) {
        // FIXME: What should we do here?
        return;
      }

      if (preview) {
        thisGuide = { ...thisGuide, preview: true };
      }

      _.activeGuide = thisGuide;
    },
    [_showMessage](eventName, preview) {
      sdk[_showGuide](eventName, preview);
    },
    [_updateEditorRoute](data) {
      const event = new CustomEvent<TUpdateEditorRouteDetails>('updateEditorRoute', { detail: data });
      window.dispatchEvent(event);
    },
    [_startDebug](data) {
      const { product } = data;
      if (product && PRODUCTS.includes(product)) {
        if (product === 'nudges') {
          LocalStorage.set(`debug:${product}`, 'true');
          Logger.info(`Enabled debugging for ${product}. Restart the application.`);
        } else {
          // TODO: add support for other products
          Logger.error(`Debugging for ${product} is not supported. Supported products: nudges`);
        }
      } else {
        Logger.error('startDebug called without a valid product');
      }
    },
    [_stopDebug](product) {
      if (product) {
        LocalStorage.remove(`debug:${product}`);
        Logger.info(`Disabled debugging for ${product}. Restart the application.`);
      } else {
        for (const product of PRODUCTS) {
          LocalStorage.remove(`debug:${product}`);
        }
        Logger.info(`Disabled debugging for all products. Restart the application.`);
      }
    },
    [_getDebugSnapshot](data) {
      if (data?.nudgeId) {
        const nudge = getNudgeById(_, data?.nudgeId);

        if (nudge) {
          Engine.getDebugSnapshot(_, nudge, data?.stepIndex).then((snapshot) => {
            console.log(`Nudge snapshot: ${snapshot.nudge?.slug || snapshot.nudge?.id}`, snapshot);
          });
        }

        return;
      }

      const nudges = getAllNudges(_);

      for (const nudge of nudges) {
        Engine.getDebugSnapshot(_, nudge).then((snapshot) => {
          console.log(`Nudge snapshot: ${snapshot.nudge?.slug || snapshot.nudge?.id}`, snapshot);
        });
      }

      return;
    },
    [_user]: undefined,
    [_fingerprint]: undefined,
  };

  const sdkMixin: CommandBarSDK = { ...internalSDKMixin, ...externalSdkMixin };

  TRACK_METHODS.forEach((k) => {
    // NOTE: The type assertion is unavoidable here (even with the key remapping introduced in TS 4.1).
    const s = sdkMixin as unknown as Record<keyof CommandBarSDK, VoidFunction>;
    s[k] = clientWrapper(String(k), s[k]);
  });

  // _configuration is needed by getConfig, so we make it available on the proxy first
  proxy[_configuration] = sdkMixin[_configuration];

  sdk = Object.assign({}, proxy, sdkMixin);

  window._CommandBarTmpQueueProcessing = sdk;
  sdk = await processQueueAndInstallGlobalSDK(proxy, sdk);
  delete window._CommandBarTmpQueueProcessing;

  App.addBuiltInCallbacks(_);
  App.addBuiltInEventSubscriptions(_);

  return sdk;
}

/* Go through queue that was created on startup and perform each operations */
const processQueueAndInstallGlobalSDK = async (
  proxy: CommandBarProxyGlobal,
  sdk: CommandBarSDK,
): Promise<CommandBarSDK> => {
  const queue = proxy[_queue];
  const call = async (item: any) => {
    const args = [...item];

    if (sdk[_disposed]) return;

    const fnName = args[0] as keyof CommandBarClientSDK;
    /* Needs to be in sync with the snippet for backwards compatibility */
    if (ASYNC_METHODS_SNIPPET.includes(fnName)) {
      args.shift();

      if (args[0] instanceof Function) {
        // args == [resolve, reject, ...sdkMethodArgs]
        // resolve & reject are put in the front of the args by the snippet so a client can await the result
        const resolve = args.shift() as (x: unknown) => void;
        const reject = args.shift() as (x: unknown) => void;
        try {
          await (sdk[fnName] as (...args: unknown[]) => Promise<unknown>)(...args).then(resolve, reject);
        } catch (err: any) {
          proxy[_sentry]?.captureException(err);
        }
      } else {
        await (sdk[fnName] as Function)(...args);
      }
    } else {
      await (sdk[fnName] as Function)(...args.slice(1));
    }
  };

  const callAll = async (fnName: string | symbol) => {
    do {
      const i = queue.findIndex((item) => item?.[0] === fnName);
      if (i === -1) break;
      const item = queue.splice(i, 1)[0];
      await call(item);
    } while (true);
  };

  // Run all _configure first
  await callAll(_configure);

  // Then boot calls second
  const bootFinished = callAll('boot');

  // While we are calling boot ^, the queue is going to keep processing
  // If we hit a boot first, then let's just process it later in order to preserve order
  const lingeringBootCalls: any[] = [];
  // PROCESSQUEUE: Run the rest of the queue in order
  while (queue.length > 0) {
    const item = queue.shift();
    if (!item) continue;

    const fnName = item[0] as keyof CommandBarClientSDK;

    if (fnName === 'boot') {
      lingeringBootCalls.push(item);
      continue;
    }

    if (fnName === 'shutdown') {
      // per the docs; calling `shutdown` before `boot` has no effect, so we ignore calls to `shutdown` here
      continue;
    }

    if (!(fnName in sdk)) {
      proxy[_sentry]?.captureException(`CommandBar SDK method ${String(fnName)} is not defined.`);
      continue;
    }
    if (ASYNC_METHODS.includes(fnName)) {
      await call(item);
    } else {
      call(item);
    }
  }

  queue.length = 0;

  // We need to make sure that there are no awaits after the PROCESSQUEUE loop
  // Otherwise some calls might get orphaned in the queue without getting processed
  bootFinished.then(async () => {
    for (let idx = 0; idx < lingeringBootCalls.length; idx++) {
      const item = lingeringBootCalls[idx];
      await call(item);
    }
    dispatchCustomEvent('commandbar-boot-ready', {});
  });

  // install the SDK into the global window.CommandBar
  sdk = Object.assign(proxy, sdk);

  return sdk;
};

// Wrapper function that (a) logs each use of client functions (b) catches any errors and reports them
const clientWrapper = <T extends unknown[], U>(name: string, fn: (...args: T) => U) => {
  return (...args: T) => {
    // Checking the presence of a local storage token because we want to log all command events if
    // the editor is in the process of logging in
    if (!!LocalStorage.get('editor', '')) {
      (window as any).CommandBarLogs = (window as any).CommandBarLogs || [];
      (window as any).CommandBarLogs.push({ name, args, url: window.location.href, time: Date.now() });
    }
    try {
      return fn(...args);
    } catch (e) {
      getSentry()?.captureException(e);
    }
  };
};
