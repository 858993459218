import { IChecklist, IChecklistItem } from '@commandbar/internal/middleware/types';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import styles, { CHECKLIST_WIDTH } from './checklist-styles';
import { useCommandBarTheme } from '../../hooks/useCommandBarTheme';
import * as Engine from '../../store/engine/actions';
import { ReactComponent as Check } from './check.svg';
import { useStore } from '../../hooks/useStore';
import { useAction } from '../../hooks/useAction';
import {
  getChecklistProgress,
  hasIncompleteItems,
  isExpanded,
  isItemCompleted,
} from '../../store/engine/checklists/selectors';
import { navigateToBrandingSite, showBranding } from '../../util/branding';
import { Accordion, Collapse } from './Collapse';
import * as Reporting from '../../analytics/Reporting';
import { interpolateObject } from '../../engine/Interpolate';
import { dispatchCustomEvent } from '@commandbar/internal/util/dispatchCustomEvent';
import { isStandaloneEditor } from '@commandbar/internal/util/location';
import { translate } from '../../util/languages';
import { ReactComponent as PoweredBySvg } from '../../img/powered.svg';
import { DraftFooter } from '../DraftFooter';
import useDraggable from '../../hooks/useDraggable';

/**
 * [X] Add checklist types
 * [X] Add mock data
 * [X] Create dummy components for:
 *         - ChecklistContainer (logic of portal, which one to show)
 *         - Checklist (individual checklist, title, description, progress)
 *         - ChecklistItem (Block with checkbox, title, description, cta)
 * [X] Add basic styling to dummy components
 * [x] Add ability to expand and close each checklist item
 * [x] Enable to be usable:
 *      [x] Add subscription to figure out active checklist, add checklist to  state
 *      [x] Make CTA work
 * [x] Polish:
 *      [x] Styling v2
 *      [x] Auto-open checklist items
 *      [x] Expand / collapse checklist
 *      [x] Usable progress bar
 *      [x] Animations
 *      [x] Positioning
 */

const ChecklistContainer = () => {
  // handle logic of showing
  const { engine } = useStore();

  const questlistRef = React.useRef<HTMLDivElement>(null);
  useDraggable(questlistRef.current, true, false);

  const activeChecklist = engine.activeChecklist;
  if (!activeChecklist) return null;

  return <Checklist questlistRef={questlistRef} checklist={activeChecklist} />;
};

const Checklist = (props: { checklist: IChecklist; questlistRef?: React.RefObject<HTMLDivElement> }) => {
  const { checklist, questlistRef } = props;
  const _ = useStore();

  const theme = useCommandBarTheme();

  const markCompleted = useAction((_) => Engine.markChecklistAsCompleted(_, checklist));
  const markSkipped = useAction((_) => Engine.markChecklistAsSkipped(_, checklist));
  const markExpanded = useAction((_) => Engine.setChecklistExpandedState(_, checklist, true));
  const markCollapsed = useAction((_) => Engine.setChecklistExpandedState(_, checklist, false));
  const markSeen = useAction((_) => Engine.markChecklistAsSeen(_, checklist));

  useEffect(() => {
    markSeen();
  }, []);

  const addGoalListeners = useAction(Engine.addGoalListeners);

  const [open, setOpen] = useState(checklist._editorState?.expanded ?? isExpanded(_, checklist));

  useEffect(() => {
    setOpen(checklist._editorState?.expanded ?? isExpanded(_, checklist));
  }, [checklist._editorState?.expanded]);

  const isIncomplete = hasIncompleteItems(_, checklist);

  React.useEffect(() => {
    const removeGoalListeners = addGoalListeners(checklist, checklist.items);
    return removeGoalListeners;
  }, [checklist.items, _.engine.endUserStore.data.checklist_interactions]);

  const firstIncompleteIdx = React.useMemo(
    () => checklist.items.findIndex((i) => !isItemCompleted(_, checklist, i)),
    [checklist, _.engine.endUserStore.data.checklist_interactions],
  );

  let defaultOpenIdx = firstIncompleteIdx;

  if (typeof checklist?._editorState?.initial_active_item === 'number') {
    defaultOpenIdx = checklist._editorState.initial_active_item;
  }

  const { completedItems, totalItems } = React.useMemo(
    () => getChecklistProgress(_, checklist),
    [checklist, _.engine.endUserStore.data.checklist_interactions],
  );
  const onExpandedItemChange = (idx: number) => {
    Reporting.checklistItemEngagement(checklist.items[idx], checklist.id, !isIncomplete, 'expanded');
  };

  React.useEffect(() => {
    dispatchCustomEvent('commandbar-checklist-shown', {});
  }, [checklist.position]);

  const onOpenChange = (open: boolean) => {
    Reporting.checklistEngagement(checklist, completedItems, open ? 'expanded' : 'collapsed', !isIncomplete);

    if (open) {
      markExpanded();
    } else {
      markCollapsed();
    }

    setOpen(open);
  };

  const interpolatedChecklistTitle = useMemo(() => {
    return interpolateObject({
      s: checklist.title,
      engine: _.engine,
      interpolateContext: true,
      interpolateArgs: false,
    });
  }, [checklist.title, _.engine]);

  const interpolatedChecklistDescription = useMemo(() => {
    if (!checklist.description) {
      return '';
    }

    return interpolateObject({
      s: checklist.description,
      engine: _.engine,
      interpolateContext: true,
      interpolateArgs: false,
    });
  }, [checklist.description, _.engine]);

  const commandBarWrapper = document.getElementById('commandbar-wrapper')?.getBoundingClientRect();

  return (
    <div
      ref={questlistRef}
      className="commandbar-checklist"
      style={{
        ...styles.checklist.container(theme, checklist.position),
        ...(isStandaloneEditor &&
          commandBarWrapper && {
            left: commandBarWrapper.left + commandBarWrapper.width / 2 - CHECKLIST_WIDTH / 2,
            right: 0,
            bottom: 24,
          }),
      }}
      data-testid={`commandbar-checklist-${interpolatedChecklistTitle}`}
    >
      <Collapse
        open={open}
        editorTimeStamp={checklist._editorState?.timestamp}
        onChange={onOpenChange}
        footer={
          <div style={styles.checklist.footer(theme)}>
            {showBranding(_.engine.organization) ? (
              <button
                role="link"
                aria-label="powered-by-commandbar"
                style={{
                  ...styles.helpers.noBtnStyle,
                  ...styles.checklist.brandingText(theme),
                }}
                onClick={() => navigateToBrandingSite(_.engine.organization)}
              >
                <PoweredBySvg role="img" focusable={false} style={{ marginRight: '5px', opacity: 0.5 }} />
                <span style={{ fontSize: '14px', color: theme.main.color, opacity: 0.5 }}>
                  {translate('Powered by CommandBar')}
                </span>
              </button>
            ) : (
              <div />
            )}
            {hasIncompleteItems(_, checklist) ? (
              checklist.skippable && (
                <button style={styles.checklist.secondaryBtn(theme)} onClick={markSkipped}>
                  Skip
                </button>
              )
            ) : (
              <button style={styles.checklist.primaryBtn(theme)} onClick={markCompleted}>
                Done <Check style={{ color: '#A2A2A9' }} />
              </button>
            )}
          </div>
        }
        header={(isOpen) => (
          <>
            <div style={styles.checklist.textContainer(theme)} data-draggable="drag-zone">
              <div style={{ display: 'flex', alignItems: 'center', color: '#A2A2A9', fontSize: 8 }}>
                <div style={styles.checklist.title(theme)}>{interpolatedChecklistTitle}</div>
                <div style={{ marginLeft: 8, cursor: 'pointer' }}>
                  {isOpen ? <CaretUpOutlined /> : <CaretDownOutlined />}
                </div>
              </div>

              {interpolatedChecklistDescription && (
                <div style={styles.checklist.description(theme)}>{interpolatedChecklistDescription}</div>
              )}
            </div>
            <ProgressBar numCompleted={completedItems} numTotal={totalItems} />
          </>
        )}
      >
        <div style={styles.checklist.divider(theme)} />
        <Accordion
          containerStyles={styles.checklist.itemsContainer(theme)}
          onExpandedItemChange={onExpandedItemChange}
          items={checklist.items.map((item) => ({
            header: (isOpen) => (
              <ChecklistHeader
                isExpanded={!!isOpen}
                item={item}
                isCompleted={isItemCompleted(_, checklist, item)}
                key={`checklist-item-header-${item.id}`}
              />
            ),
            children: (
              <ChecklistBody
                item={item}
                key={`checklist-item-body-${item.id}`}
                isCompleted={isItemCompleted(_, checklist, item)}
              />
            ),
            divider: <div style={styles.checklist.divider(theme)} />,
          }))}
          defaultOpenIdx={defaultOpenIdx}
          editorTimeStamp={checklist._editorState?.timestamp}
          divider={<div style={styles.checklist.divider(theme)} />}
        />
      </Collapse>
      {!checklist.is_live && <DraftFooter details={{ type: 'checklist', checklistId: checklist.id }} />}
    </div>
  );
};

const ProgressBar = (props: { numCompleted: number; numTotal: number }) => {
  const theme = useCommandBarTheme();

  const MIN_PERCENTAGBE = 0.04 * 100;
  const percentCompleted = Math.max(MIN_PERCENTAGBE, Math.round((props.numCompleted * 100) / props.numTotal));

  return (
    <div style={styles.progressBar.container(theme)}>
      <div style={styles.progressBar.text(theme)}>{`${props.numCompleted}/${props.numTotal}`}</div>
      <div style={styles.progressBar.progressContainer(theme)}>
        <div style={{ ...styles.progressBar.progressFill(theme), width: `${percentCompleted}%` }} />
      </div>
    </div>
  );
};

const ChecklistHeader = (props: { item: IChecklistItem; isCompleted: boolean; isExpanded: boolean }) => {
  const _ = useStore();
  const { item, isCompleted, isExpanded } = props;
  const theme = useCommandBarTheme();

  const interpolatedItemTitle = useMemo(
    () =>
      interpolateObject({
        s: item.title,
        engine: _.engine,
        interpolateContext: true,
        interpolateArgs: false,
      }),
    [item.title, _.engine],
  );

  return (
    <div
      style={
        isExpanded ? styles.checklistItem.expandedTitleContainer(theme) : styles.checklistItem.titleContainer(theme)
      }
    >
      <div
        style={
          isCompleted ? styles.checklistItem.checkboxComplete(theme) : styles.checklistItem.checkboxIncomplete(theme)
        }
      >
        <Check stroke={isCompleted ? '#FFF' : '#CDCDD0'} />
      </div>
      <span style={isCompleted ? styles.checklistItem.titleCompleted(theme) : styles.checklistItem.title(theme)}>
        {interpolatedItemTitle}
      </span>
    </div>
  );
};

const ChecklistBody = (props: { item: IChecklistItem; isCompleted: boolean }) => {
  const { item, isCompleted } = props;
  const itemRef = React.useRef<IChecklistItem>(item);

  React.useEffect(() => {
    itemRef.current = props.item;
  }, [props.item.action]);

  const _ = useStore();
  const theme = useCommandBarTheme();

  const onSelect = useAction((_) => Engine.onChecklistItemSelect(_, itemRef.current));
  const onSkip = useAction((_) => Engine.onChecklistItemComplete(_, item, true));

  const interpolatedItemDescription = useMemo(() => {
    if (!item.description) {
      return '';
    }
    return interpolateObject({
      s: item.description,
      engine: _.engine,
      interpolateContext: true,
      interpolateArgs: false,
    });
  }, [item.description, _.engine]);
  return (
    <div style={styles.checklistItem.bodyContainer(theme)}>
      <div style={styles.checklistItem.description(theme)}>{interpolatedItemDescription}</div>
      {!!item.cta && !!onSelect && (
        <button
          style={{
            ...styles.checklistItem.ctaButton(theme),
            marginTop: !!interpolatedItemDescription ? '14px' : '4px',
          }}
          onClick={onSelect}
        >
          {item.cta}
        </button>
      )}
      {item.skippable && !isCompleted && (
        <button style={styles.checklistItem.textBtn(theme)} onClick={onSkip}>
          Skip
        </button>
      )}
    </div>
  );
};
export default ChecklistContainer;
