/** @jsx jsx  */
/** @jsxFrag */
import React, { useEffect, useRef } from 'react';
import { jsx } from '@emotion/core';
import dayjs from 'dayjs';

import { useStyles } from './useStyles';
import useTheme from '../../hooks/useTheme';

import Icon from '@commandbar/internal/client/Icon';
import {
  BookOpen01,
  LinkExternal01,
  Play,
  ChevronRight,
  Lightning01,
  BookmarkCheck,
} from '@commandbar/design-system/icons/react';
import { INPUT_METHOD } from '../../hooks/useKeyboardNavigation';

import type { HelpHubDoc } from '../../store/engine';
import { useStore } from '../../hooks/useStore';
import { ITheme } from '@commandbar/internal/client/theme';

type CardComponentProps = {
  doc: HelpHubDoc;
  styles: ReturnType<typeof useStyles>;
  active: boolean;
  iconComponent: React.ReactElement;
};

const CardComponent = ({ styles, doc, active, iconComponent }: CardComponentProps) => {
  const { theme } = useTheme();

  const background = doc.thumbnail
    ? `url(${encodeURI(doc.thumbnail.src)}) center center / cover no-repeat border-box`
    : theme.helpHubGridItem.background;
  const hoverBackground = !doc.thumbnail ? theme.helpHubGridItem.hoverBackground : background;

  const activeStyle = active
    ? {
        ...styles.gridItemPreviewActive,
        background: hoverBackground,
      }
    : {};

  const ariaLabel = doc.thumbnail ? doc.title : undefined;
  const title = !doc.thumbnail && <span css={styles.gridItemTitle}>{doc.title}</span>;

  if (doc?.thumbnail?.src) {
    return (
      <div
        style={{
          ...styles.gridItemPreview,
          padding: 0,
          overflow: 'hidden',
          width: '100%',
          backgroundImage: doc?.thumbnail?.src ? `url(${doc.thumbnail.src})` : '',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <div css={styles.gridItemPreviewButton} aria-hidden>
          {iconComponent}
        </div>
      </div>
    );
  }

  return (
    <div
      css={{
        ...styles.gridItemPreview,
        background,
        ...activeStyle,
      }}
      aria-label={ariaLabel}
    >
      {title}
      <div css={styles.gridItemPreviewButton} aria-hidden>
        {iconComponent}
      </div>
    </div>
  );
};

export const LeftIcon = ({ doc }: { doc: HelpHubDoc }) => {
  const { theme } = useTheme();

  const {
    engine: { categories },
  } = useStore();
  const category = categories.find((c) => c.id === doc.command.category);
  const customIcon = category && category.icon ? category.icon : doc.command.icon;
  const customIconColor = category && category.icon_color ? category.icon_color : doc.command.icon_color;

  if (customIcon) {
    return (
      <Icon
        size={theme.helpHubListItem.iconSize}
        icon={customIcon}
        color={customIconColor}
        useDefaultSVGColor={!customIconColor}
      />
    );
  }

  switch (doc.type) {
    case 'video':
      return <Play width={parseInt(theme.helpHubListItem.iconSize, 10)} />;
    case 'link':
    case 'helpdoc':
      return doc.command.template.type === 'helpdoc' && doc.command.template.doc_type === 'answer' ? (
        <BookmarkCheck width={parseInt(theme.helpHubListItem.iconSize, 10)} color={'#2755F0'} />
      ) : (
        <BookOpen01 width={parseInt(theme.helpHubListItem.iconSize, 10)} />
      );
    default:
      return <Lightning01 width={parseInt(theme.helpHubListItem.iconSize, 10)} />;
  }
};

interface MetaHeaderProps {
  metadata: { content_type?: string; date?: string };
}

// INFO: these meta fields are currently only in use by the Vanilla Forums integration
export const MetaHeader = ({ metadata }: MetaHeaderProps) => {
  const styles = useStyles();

  return (
    <div css={styles.resultCardMeta}>
      {metadata.content_type && (
        <div css={styles.resultCardContentType}>
          <span>{metadata.content_type}</span>
        </div>
      )}
      {metadata.date && (
        <div css={styles.resultCardDate}>
          <span>{dayjs(metadata.date).format('MMMM D YYYY')}</span>
        </div>
      )}
    </div>
  );
};

export const renderRightIcon = (doc: HelpHubDoc, theme: ITheme) => {
  switch (doc.type) {
    case 'link':
      return <LinkExternal01 width={parseInt(theme.helpHubListItem.iconSize, 10)} />;
    case 'video':
    case 'helpdoc':
      return <ChevronRight width={parseInt(theme.helpHubListItem.iconSize, 10)} />;
    default:
      return null;
  }
};

type RecommendationCardProps = {
  doc: HelpHubDoc;
  active: boolean;
  selectionMethod: INPUT_METHOD;
  onClick: () => void;
  onMouseMove: () => void;
  grid?: boolean;
};

const RecommendationCard = ({ doc, active, selectionMethod, onClick, onMouseMove, grid }: RecommendationCardProps) => {
  const { theme } = useTheme();
  const styles = useStyles();
  const activeRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (active && selectionMethod === INPUT_METHOD.Keyboard) {
      activeRef?.current && activeRef.current.scrollIntoView({ block: 'center' });
    }
  }, [active, selectionMethod]);

  const renderGridCardContent = () => {
    const iconComponentMap = {
      video: (
        <Play width={parseInt(theme.helpHubGridItem.iconSize)} color={theme.helpHubGridItem.ctaButtonForeground} />
      ),
      link: (
        <LinkExternal01
          width={parseInt(theme.helpHubGridItem.iconSize)}
          color={theme.helpHubGridItem.ctaButtonForeground}
        />
      ),
      helpdoc: (
        <BookOpen01
          width={parseInt(theme.helpHubGridItem.iconSize)}
          color={theme.helpHubGridItem.ctaButtonForeground}
        />
      ),
      other: (
        <Lightning01
          width={parseInt(theme.helpHubGridItem.iconSize)}
          color={theme.helpHubGridItem.ctaButtonForeground}
        />
      ),
    };

    return <CardComponent styles={styles} doc={doc} active={active} iconComponent={iconComponentMap[doc.type]} />;
  };

  const renderCardContent = () =>
    grid ? (
      renderGridCardContent()
    ) : (
      <>
        <div css={styles.recommendedListItemIcon} aria-hidden>
          <LeftIcon doc={doc} />
        </div>

        <div css={styles.resultCardTextContainer}>
          {doc.command.template.type === 'helpdoc' && doc.command.template.doc_metadata && (
            <MetaHeader metadata={doc.command.template.doc_metadata} />
          )}
          <div css={styles.recommendedListItemText}>
            <span css={styles.recommendedListItemTitle}>{doc.title}</span>
            {doc.excerpt && <span css={styles.recommendedListItemDescription}>{doc.excerpt}</span>}
          </div>
        </div>

        <div css={{ ...styles.recommendedListItemIcon, opacity: active ? '1' : '0' }}>
          {renderRightIcon(doc, theme)}
        </div>
      </>
    );

  return (
    <button
      ref={activeRef}
      key={doc.commandID}
      css={{
        ...(grid ? styles.gridItem : styles.recommendedListItem),
        ...(active && !grid ? styles.recommendedListItemActive : {}),
      }}
      onClick={onClick}
      onMouseMove={onMouseMove}
      aria-label={doc.title}
    >
      {renderCardContent()}
    </button>
  );
};

export default RecommendationCard;
