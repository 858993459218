/*******************************************************************************/
/* Imports
/*******************************************************************************/

/* React imports */
import * as React from 'react';
import Z from '@commandbar/internal/client/Z';
import { IOrganizationType } from '@commandbar/internal/middleware/types';

/*******************************************************************************/
/* Render
/*******************************************************************************/

const useLauncherPosition = (isAdmin: boolean, organization: IOrganizationType) => {
  const [offsetX, setOffsetX] = React.useState(organization.launcher_offset_x);
  const [offsetY, setOffsetY] = React.useState(organization.launcher_offset_y);

  React.useEffect(() => {
    setOffsetX(organization.launcher_offset_x);
    setOffsetY(organization.launcher_offset_y);
  }, [organization.launcher_offset_x, organization.launcher_offset_y]);

  const marginX = 35 + offsetX;
  const marginY = 35 + offsetY;

  const fixedStyles = {
    zIndex: Z.Z_LAUNCHER,
    position: 'fixed',
    userSelect: 'none',
  };

  let variableStyles = {};

  switch (organization.launcher_position) {
    case 'topRight':
      variableStyles = {
        top: marginY,
        right: marginX,
      };
      break;
    case 'topLeft':
      variableStyles = {
        top: marginY,
        left: marginX,
      };
      break;
    case 'bottomRight':
      variableStyles = {
        bottom: marginY,
        right: marginX,
      };
      break;
    case 'bottomLeft':
      variableStyles = {
        bottom: marginY,
        left: marginX,
      };
      break;
  }

  return {
    ...fixedStyles,
    ...variableStyles,
  };
  // }
};

export const useHelpHubLauncherPosition = (isAdmin: boolean, organization: IOrganizationType) => {
  return React.useMemo(() => {
    const marginX = 35 + organization.helphub_launcher_offset_x;
    const marginY = 35 + organization.helphub_launcher_offset_y;

    const fixedStyles = {
      zIndex: Z.Z_HELPHUB_LAUNCHER,
      position: 'fixed',
      userSelect: 'none',
    };

    let variableStyles = {};

    switch (organization.helphub_launcher_position) {
      case 'topRight':
        variableStyles = {
          top: marginY,
          right: marginX,
        };
        break;
      case 'topLeft':
        variableStyles = {
          top: marginY,
          left: marginX,
        };
        break;
      case 'bottomRight':
        variableStyles = {
          bottom: marginY,
          right: marginX,
        };
        break;
      case 'bottomLeft':
        variableStyles = {
          bottom: marginY,
          left: marginX,
        };
        break;
    }

    return {
      ...fixedStyles,
      ...variableStyles,
    };
  }, [
    organization.helphub_launcher_offset_x,
    organization.helphub_launcher_offset_y,
    organization.helphub_launcher_position,
  ]);
};

export default useLauncherPosition;
