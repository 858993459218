import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';

import { runBooleanExpression } from '../../../engine/option/OptionValidate';
import helpdocService from '../../../services/helpdocService';
import { getCommandById } from '../selectors';
import { initCommandOption } from '../../../engine/option/CommandOption';
import { getElement } from '@commandbar/internal/util/dom';
import LocalStorage from '@commandbar/internal/util/LocalStorage';

import type { EngineState } from '../state';
import type {
  INudgeStepContentButtonBlockType,
  INudgeType,
  NudgeInteractionState,
} from '@commandbar/internal/middleware/types';
import type { EndUserStore } from '../end-user/state';
import type { TriggerEvent } from './nudgeManagerMachine';
import type { NudgeContext } from './nudgeMachine';

// TODO: make this configurable
export const MAX_RENDERED_NUDGES = 1;

export const getAllNudgeServices = (_: EngineState) => _.engine.nudgeManager?.getSnapshot()?.context.nudgeMachines;

export const getNudgeService = (_: EngineState, id: INudgeType['id']) => {
  const previewMachine = _.engine.nudgeManager?.getSnapshot()?.context.previewMachine;

  if (previewMachine) return previewMachine;

  return _.engine.nudgeManager?.getSnapshot().context.nudgeMachines.get(id.toString());
};

export const getNudgeServiceSnapshot = (_: EngineState, id: INudgeType['id']) => getNudgeService(_, id)?.getSnapshot();

export const getNudgeById = (_: EngineState, id: INudgeType['id']): INudgeType | undefined =>
  getNudgeServiceSnapshot(_, id)?.context.nudge;

export const getAllNudges = (_: EngineState): Array<INudgeType> => {
  const nudgeServices = getAllNudgeServices(_);
  return Array.from(nudgeServices?.values() ?? [])
    .map((service) => service.getSnapshot()?.context?.nudge)
    .filter((nudge): nudge is INudgeType => Boolean(nudge));
};

export const isNudgeDismissible = (nudge: INudgeType): boolean =>
  nudge.dismissible === undefined ? true : nudge.dismissible;

export const passesMaxRenderedNudges = (_: EngineState): boolean => {
  const nudgesServices = getAllNudgeServices(_);
  const nudgesInRenderLoop = Array.from(nudgesServices?.values() ?? []).filter((service) => {
    const nudgeMachine = service.getSnapshot();
    return nudgeMachine?.matches('Step.Render Loop');
  });

  return nudgesInRenderLoop.length < MAX_RENDERED_NUDGES;
};

export const passesGlobalLimit = (_: EngineState): boolean => {
  const limit = _.engine.organization?.nudge_rate_limit;
  const period = _.engine.organization?.nudge_rate_period;

  if (limit === null || limit === undefined || !period) return true;

  if (period === 'session') {
    const allNudgeServices = getAllNudgeServices(_);

    if (allNudgeServices) {
      const allNudgeSeenTs = Array.from(allNudgeServices?.values()).flatMap(
        (service) => service.getSnapshot()?.context?.nudgeSeenThisSessionTs,
      );

      return (allNudgeSeenTs?.length ?? 0) < limit;
    }

    return true;
  }

  const allNudgesSeen = Object.values(_.engine.endUserStore.data.nudges_interactions ?? {})
    .flatMap((nudgeData) => nudgeData.seenTs)
    .filter(Boolean);

  const nudgesSeenInPeriod = allNudgesSeen.filter((timeSeen) => {
    const timeSeenMoment = dayjs(timeSeen);
    const now = dayjs();
    const diff = now.diff(timeSeenMoment, period);

    return diff <= 1;
  }).length;

  return nudgesSeenInPeriod < limit;
};

export const passesStatus = (nudge: INudgeType): boolean => nudge.is_live;

export const passesFrequencyCondition = (_: EngineState, nudge: INudgeType): boolean => {
  switch (nudge.frequency_limit) {
    case 'no_limit':
      return true;
    case 'once_per_session':
      return !getNudgeServiceSnapshot(_, nudge.id)?.context?.nudgeSeenThisSessionTs.length;
    case 'once_per_user':
      return !getNudgeDataFromUserStore(_, nudge.id)?.nudgeSeen;
    case 'until_interaction':
      return !getNudgeDataFromUserStore(_, nudge.id)?.nudgeInteracted;
  }
};

export const passesAudienceConditions = (_: EngineState, nudge: INudgeType): boolean => {
  if (nudge.audience) {
    switch (nudge.audience.type) {
      case 'all_users':
        return true;
      case 'rule_expression':
        return runBooleanExpression(nudge.audience.expression, _.engine, '').passed;
      default:
        return false;
    }
  }
  return true;
};

export const passesPageConditions = (_: EngineState, nudge: INudgeType): boolean =>
  !nudge.show_expression || runBooleanExpression(nudge.show_expression, _.engine, '').passed;

export const passesFlip = (
  _: EngineState,
  prevPassedConditions: NudgeContext['prevPassedConditions'],
  triggerEvent: NudgeContext['triggerEvent'],
) => {
  if (prevPassedConditions && triggerEvent?.trigger.type === 'when_conditions_pass') {
    return false;
  }

  return true;
};

export const getCommandByIdIncludingHelpdocCommands = (_: EngineState) => async (commandId: string) => {
  let command = getCommandById(_.engine, commandId) ?? null;

  if (!command) {
    if (_.engine.organization?.id)
      command = await helpdocService.getHelpdocCommand(_.engine.organization?.id, commandId);
  }

  return command;
};

export const passesTriggerMatch = (_: EngineState, nudge: INudgeType, triggerEvent: TriggerEvent | null) => {
  const nudgeState = getNudgeServiceSnapshot(_, nudge.id);

  if (triggerEvent?.nudgeId && nudge.id !== triggerEvent.nudgeId) {
    return false;
  }

  if (!nudgeState?.done && isEqual(triggerEvent?.trigger, nudgeState?.context.nudge.trigger)) {
    return true;
  }

  return false;
};

export const stepHasTargetElement = (nudge: INudgeType, stepIndex: NudgeContext['stepIndex']) =>
  nudge.steps[stepIndex].form_factor.type === 'pin';

export const stepHasCommand = (nudge: INudgeType, stepIndex: NudgeContext['stepIndex']) =>
  nudge.steps[stepIndex].content.find((block): block is INudgeStepContentButtonBlockType => block.type === 'button')
    ?.meta?.action?.type === 'execute_command';

export const hasRemainingSteps =
  (nudge: INudgeType) =>
  ({ stepIndex }: NudgeContext) =>
    stepIndex < nudge.steps.length - 1;

export const getAllNudgeDataFromUserStore = (_: EngineState): EndUserStore['data']['nudges_interactions'] => {
  if (_.engine.endUserStore.data.nudges_interactions) {
    return _.engine.endUserStore.data.nudges_interactions;
  }

  const savedContext = LocalStorage.get('nudges', false);
  const parsedSavedContext = typeof savedContext === 'string' ? JSON.parse(savedContext) : null;

  return parsedSavedContext;
};

export const getNudgeDataFromUserStore = (
  _: EngineState,
  nudgeId: INudgeType['id'],
): NudgeInteractionState | undefined => getAllNudgeDataFromUserStore(_)?.[Number(nudgeId)];

export const passesCommandCheck = async (_: EngineState, nudge: INudgeType, stepIndex: number) => {
  const action = nudge.steps[stepIndex].content.find(
    (block): block is INudgeStepContentButtonBlockType => block.type === 'button',
  )?.meta?.action;

  if (action?.type === 'execute_command') {
    const command = await getCommandByIdIncludingHelpdocCommands(_)(action.meta.command);

    if (!command) return true;

    const option = initCommandOption(_, command);

    // NOTE: allow draft commands in "test mode", but command must always be executable for nudge to be shown
    return !option.optionDisabled.isDisabled && (command.is_live || _.engine.testMode);
  }

  return true;
};

export const passesPinnedElement = (_: EngineState, nudge: INudgeType, stepIndex: number) => {
  const step = nudge.steps[stepIndex];

  if (step.form_factor.type === 'pin') {
    return !step.form_factor.anchor.length ? false : !!getElement(step.form_factor.anchor);
  }

  return true;
};

export const isLikelyAdmin = !!LocalStorage.get('editor', false);
