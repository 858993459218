/** @jsx jsx  */
import React from 'react';
import { jsx } from '@emotion/core';
import {
  Link01,
  Lightning01,
  LinkExternal01,
  CheckSquareBroken,
  NotificationBox,
} from '@commandbar/design-system/icons/react';
import { useStyles } from '../helphub/useStyles';

type ActionButtonVariant = 'primary' | 'secondary' | 'continuation';

const icons = {
  Link01,
  Lightning01,
  LinkExternal01,
  CheckSquareBroken,
  NotificationBox,
} as const;

export type ActionButtonProps = {
  variant?: ActionButtonVariant;
  children: React.ReactNode;
  icon?: keyof typeof icons;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

export const ActionButton: React.FC<ActionButtonProps> = ({
  variant = 'primary',
  children,
  icon,
  style,
  disabled,
  ...rest
}) => {
  const styles = useStyles();

  const Icon = icon ? icons[icon] : null;

  return (
    <button
      disabled={disabled}
      css={[styles.actionButtons, styles.actionButtons[disabled ? 'disabled' : variant]]}
      style={style}
      {...rest}
    >
      <div
        style={{
          maxWidth: '100%',
          fontSize: '14px',
          fontWeight: 500,
          letterSpacing: 0,
          lineHeight: '16px',
          position: 'relative',
          textAlign: 'left',
        }}
      >
        {children}
      </div>

      {Icon && <Icon width={14} height={14} style={{ color: 'currentcolor', opacity: '50%' }} />}
    </button>
  );
};

export default ActionButton;
