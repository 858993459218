import React from 'react';
import { useStore } from '../../hooks/useStore';
import { ChatMessage } from '../../client_api/search';
import { AnimationTransition, builtinKeyframes } from '../../hooks/useDelayUnmount';
import { IInstantAnswerType, LabeledAction } from '@commandbar/internal/middleware/types';
import { useAction } from '../../hooks/useAction';
import * as Engine from '../../store/engine/actions';
import { isAction } from '@commandbar/internal/middleware/helpers/actions';

import { ActionButton } from './ActionButton';

type MessageActionsProps = {
  liveAnswer?: IInstantAnswerType | null;
  history?: ChatMessage[];
};

export const MessageActions: React.FC<MessageActionsProps> = (props) => {
  const { engine } = useStore();
  const { history } = props;
  const toggleHelpHubVisible = useAction(Engine.toggleHelpHubVisible);

  const executeAction = useAction(
    (_, action: LabeledAction['action'], toggleHelpHub: boolean, e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      Engine.executeAction(_, action, e);

      if (toggleHelpHub) {
        toggleHelpHubVisible();
      }
    },
  );

  const lastMessage = history && !!history.length && history[history.length - 1].message;
  const noAnswer =
    history && !!history.length && lastMessage && typeof lastMessage !== 'string' && lastMessage?.no_answer;

  const hasFallbackAction = engine.organization?.helphub_chat_fallback_actions.some(
    (action) => action.cta && action.action,
  );

  const shouldShowFallbackActions = noAnswer && !props.liveAnswer?.experiences?.length && hasFallbackAction;

  const nextSteps = props?.liveAnswer?.doc?.command.next_steps || [];

  return (
    <>
      <AnimationTransition
        entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
        isMounted={!!shouldShowFallbackActions}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '0px',
            alignItems: 'flex-start',
          }}
        >
          {engine.organization?.helphub_chat_fallback_actions.map(({ cta, action }, index) => (
            <ActionButton
              key={index}
              onClick={(e) => {
                executeAction(action, true, e);
              }}
            >
              {cta}
            </ActionButton>
          ))}
        </div>
      </AnimationTransition>

      <AnimationTransition
        entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
        isMounted={nextSteps.length > 0 && nextSteps.some((nextStep) => isAction(nextStep) && !!nextStep.cta)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '0px',
            alignItems: 'flex-start',
          }}
        >
          {nextSteps.map(
            (nextStep, index) =>
              !!nextStep &&
              isAction(nextStep) &&
              !!nextStep.cta && (
                <ActionButton
                  key={index}
                  icon="LinkExternal01"
                  onClick={(e) => {
                    executeAction(nextStep.action, false, e);
                  }}
                >
                  {nextStep.cta}
                </ActionButton>
              ),
          )}
        </div>
      </AnimationTransition>
    </>
  );
};

export default MessageActions;
