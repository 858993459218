import { interpret } from 'xstate';
import { ref } from 'valtio';
import toast from 'react-hot-toast';

import Logger from '@commandbar/internal/util/Logger';
import { updateEndUserStore } from '../end-user/actions';
import { deconstructShareLink } from '@commandbar/internal/proxy-editor/share_links';
import { getElement } from '@commandbar/internal/util/dom';
import { isStandaloneEditor } from '@commandbar/internal/util/location';
import { openChatExecutable, linkExecutable, clickExecutable } from '../steps/executables';
import { executeCommand } from '../actions';
import {
  MAX_RENDERED_NUDGES,
  getCommandByIdIncludingHelpdocCommands,
  getNudgeById,
  getNudgeDataFromUserStore,
  getNudgeServiceSnapshot,
  isLikelyAdmin,
  passesAudienceConditions,
  passesCommandCheck,
  passesFlip,
  passesFrequencyCondition,
  passesGlobalLimit,
  passesMaxRenderedNudges,
  passesPageConditions,
  passesPinnedElement,
  passesStatus,
} from './selectors';
import { RenderMode, renderNudge } from '../../../components/nudges/RenderNudge';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { isEditorOpen } from '../../util/editorUtils';
import NudgeManagerMachine from './nudgeManagerMachine';

import type { EngineState } from '../state';
import type {
  INudgeStepContentButtonBlockType,
  INudgeStepContentHelpDocBlockType,
  INudgeType,
  NudgeInteractions,
} from '@commandbar/internal/middleware/types';
import type { NudgeContext, NudgeEvents } from './nudgeMachine';
import type { TriggerEvent } from './nudgeManagerMachine';
import { getSentry } from '@commandbar/internal/util/sentry';

const shouldDebugNudges = !!LocalStorage.get('debug:nudges', false);

export const initNudges = (_: EngineState, nudges: INudgeType[], isAdmin?: boolean) => {
  if (!_.engine.products.includes('nudges') || _.engine.nudgeManager) return;

  const service = interpret(NudgeManagerMachine(_, nudges, isAdmin ?? isLikelyAdmin), { devTools: shouldDebugNudges });
  _.engine.nudgeManager = ref(service);
  _.engine.nudgeManager.start().onTransition((state) => {
    if (shouldDebugNudges) Logger.debug(`State transition: `, state);
  });

  triggerSharedLinkNudge(_);
  setupAndTriggerElementAppearNudges(_, nudges);
  sendTriggerToAllNudges(_, { type: 'when_conditions_pass' });
  sendTriggerToAllNudges(_, { type: 'when_page_reached', meta: { url: _.engine.location.href } });
};

export const sendTriggerToAllNudges = (
  _: EngineState,
  trigger: TriggerEvent['trigger'],
  overrides?: TriggerEvent['overrides'],
) => {
  _.engine.nudgeManager?.send({
    type: 'TRIGGER',
    trigger,
    overrides,
  });
};

/*
 * This function is used to trigger a specific nudge.
 * By default, triggers will go out to all nudges and will be rejected by nudges that don't have a matching trigger.
 * This function will only trigger the nudge that matches the nudgeId and is used for things like triggering previews
 * or nudges from the share link, bar, or trigger nudge actions.
 */
export const triggerSingleNudge = (_: EngineState, nudge: INudgeType, overrides?: TriggerEvent['overrides']) => {
  _.engine.nudgeManager?.send({
    type: 'TRIGGER',
    trigger: nudge.trigger,
    nudgeId: nudge.id,
    overrides,
  });
};

export const triggerSharedLinkNudge = (_: EngineState) => {
  const deconstructedShareLink = deconstructShareLink(_.engine.location.search);

  if (deconstructedShareLink) {
    const nudge = getNudgeById(_, deconstructedShareLink.id);

    if (nudge && _.engine.location.href.startsWith(nudge.share_page_url) && deconstructedShareLink?.type === 'nudge') {
      triggerSingleNudge(_, nudge, {
        admin: true,
        audience: true,
        frequency: true,
        globalLimit: true,
      });
    }
  }
};

const setupAndTriggerElementAppearNudges = (_: EngineState, nudges: Array<INudgeType>) => {
  for (const nudge of nudges) {
    if (nudge.trigger.type === 'when_element_appears') {
      const selector = nudge.trigger.meta.selector;
      _.engine.triggerableSelectors.push(selector);

      if (getElement(selector)) {
        sendTriggerToAllNudges(_, { type: 'when_element_appears', meta: { selector } });
      }
    }
  }
};

export const previewNudge = (_: EngineState, nudge: INudgeType) => {
  _.engine.nudgeManager?.send({ type: 'PREVIEW', nudge });
  triggerSingleNudge(_, nudge, {
    // The preview flag tells the nudge manager to send this event to the preview instead of the regular nudge machines
    // The preview machine handles a nudge from the editor while the regular nudge machine handles nudges from the config
    // You can have a nudge in both the preview and the regular nudge machine at the same time. This is useful for allowing
    // the preview of a nudge with unsaved changes in the editor.
    preview: true,
    audience: true,
    frequency: true,
    page: true,
    status: true,
    saveProgress: true,
    reporting: true,
    globalLimit: true,
    stepIndex: 0,
  });
};

export const showStepMock = (_: EngineState, nudge: INudgeType, stepIndex?: number) => {
  renderNudge(_, nudge, { stepIndex, renderMode: RenderMode.MOCK });
};

export const closeStepMock = (_: EngineState, nudge: INudgeType, stepIndex: number) => {
  const step = nudge.steps[stepIndex];

  if (step.form_factor.type === 'modal') {
    _.engine.currentModalNudge = null;
  } else {
    toast.remove(`${nudge.id}-${String(step.id)}`);
  }
};

export const closeNudgeMock = (_: EngineState, nudge: INudgeType, editingOnly = false) => {
  for (let i = 0; i < nudge.steps.length; i++) {
    if (editingOnly) {
      const step = nudge.steps[i];

      if (_.engine.currentModalNudge?.renderMode === RenderMode.MOCK) {
        _.engine.currentModalNudge = null;
      }
      toast.remove(`${nudge.id}-${String(step.id)}-mock`);
    } else {
      closeStepMock(_, nudge, i);
    }
  }
};

export const execStepAction = async (
  _: EngineState,
  nudge: INudgeType,
  stepIndex: NudgeContext['stepIndex'],
  event: NudgeEvents,
) => {
  const step = nudge.steps[stepIndex];

  if (event.type === 'ADVANCE' && event.button_type === 'help_doc') {
    const helpDocCommand = step.content.find(
      (block): block is INudgeStepContentHelpDocBlockType => block.type === 'help_doc_command',
    )?.meta.command;

    if (helpDocCommand) {
      const command = await getCommandByIdIncludingHelpdocCommands(_)(helpDocCommand);

      if (command) {
        executeCommand(_, command, undefined, undefined, { type: 'nudge', id: nudge.id });
        return;
      }
    }
  }

  const action = step.content.find(
    (block): block is INudgeStepContentButtonBlockType =>
      block.type === 'button' && event.type === 'ADVANCE' && block.meta?.button_type === event?.button_type,
  )?.meta?.action;

  if (action?.type === 'execute_command') {
    const command = await getCommandByIdIncludingHelpdocCommands(_)(action.meta.command);

    if (command) {
      executeCommand(_, command, undefined, undefined, { type: 'nudge', id: nudge.id });
      return;
    }
  }

  if (action?.type === 'click' && action.value.length) {
    if (step.form_factor.type === 'pin' && event.type === 'ADVANCE' && !!event.isPinClick) {
      const anchor = getElement(step.form_factor.anchor);
      const clickTarget = getElement(action.value);

      // if anchor contains the event target, do not execute the click action
      if (anchor?.contains(clickTarget as Node)) {
        return;
      }
    }

    clickExecutable(_.engine, {
      type: action.type,
      value: [action.value],
    })();
    return;
  }

  if (action?.type === 'link') {
    linkExecutable(_.engine, action)();
    return;
  }

  if (action?.type === 'open_chat') {
    if (isStandaloneEditor) {
      const upperCase = `${action?.meta?.type.charAt(0).toUpperCase()}${action?.meta?.type.slice(1)}`;
      _.engine.callbacks['__standalone-editor-cb_hh_cta'](upperCase);
      return;
    }

    openChatExecutable(action);
    return;
  }
};

export const saveProgressToEndUserStore = (
  _: EngineState,
  {
    nudge,
    stepIndex,
    nudgeInteracted,
    nudgeCompleted,
    nudgeDismissed,
    addSeenTime,
  }: {
    nudge: INudgeType;
    stepIndex: number;
    nudgeInteracted?: boolean;
    addSeenTime?: boolean;
    nudgeCompleted?: boolean;
    nudgeDismissed?: boolean;
  },
) => {
  const nudgeContext = getNudgeDataFromUserStore(_, nudge.id);

  const alreadySeen = nudgeContext?.nudgeSeen;
  const alreadyCompleted = nudgeContext?.nudgeCompleted;
  const alreadyDismissed = nudgeContext?.nudgeDismissed;
  const seenTs = nudgeContext?.seenTs;
  const nudgeCompletedTs = nudgeContext?.nudgeCompletedTs;
  const nudgeDismissedTs = nudgeContext?.nudgeDismissedTs;

  const updatedContext: NudgeInteractions = {
    [Number(nudge.id)]: {
      ...nudgeContext,
      currentStep: stepIndex,
      nudgeSeen: true,
      nudgeInteracted: alreadySeen || nudgeInteracted,
      seenTs: addSeenTime ? [...(seenTs ?? []), Date.now()] : seenTs,
      nudgeCompleted: alreadyCompleted || nudgeCompleted,
      nudgeDismissed: alreadyDismissed || nudgeDismissed,
      nudgeCompletedTs: nudgeCompleted ? [...(nudgeCompletedTs ?? []), Date.now()] : nudgeCompletedTs,
      nudgeDismissedTs: nudgeDismissed ? [...(nudgeDismissedTs ?? []), Date.now()] : nudgeDismissedTs,
    },
  };

  try {
    updateEndUserStore(_, updatedContext, 'nudges_interactions');
  } catch (e) {
    getSentry()?.captureException(e);
    Logger.error('unable to save', e);
  }
};

export const clearNudgeData = (_: EngineState, nudge: INudgeType) => {
  const id = Number(nudge.id);

  clearNudgeDataById(_, id);
};

export const clearNudgeDataById = (_: EngineState, id: number, step?: number) => {
  const allNudgeData = { ..._.engine.endUserStore.data.nudges_interactions } || {};

  if (step !== undefined && step > 0) {
    allNudgeData[id].currentStep = step;
  } else {
    allNudgeData[id] = {};
  }

  updateEndUserStore(_, allNudgeData, 'nudges_interactions');
};

type Checks = Record<string, { result: boolean; explanation: string; detail?: Record<string, unknown> }>;

export const getDebugSnapshot = async (_: EngineState, nudge: INudgeType, stepIndex?: number) => {
  const globalChecks: Checks = {
    notAdmin: {
      result: !isLikelyAdmin && !isEditorOpen(),
      explanation: 'Cannot trigger a nudge while logged into the editor.',
    },
    maxNudgesRendered: {
      result: passesMaxRenderedNudges(_),
      explanation: `The maximum number of nudges that can be rendered simultaneously is: ${MAX_RENDERED_NUDGES}.`,
    },
    globalLimit: {
      result: passesGlobalLimit(_),
      explanation: 'The limit for nudges shown in this period has been met.',
      detail: {
        limit: _.engine.organization?.nudge_rate_limit,
        period: _.engine.organization?.nudge_rate_period,
      },
    },
  };

  const nudgeChecks: Checks = {
    status: { result: passesStatus(nudge), explanation: 'Nudge has not been published.' },
    frequency: {
      result: passesFrequencyCondition(_, nudge),
      explanation: 'Nudge has been seen the maximum number of times.',
      detail: {
        frequency: nudge.frequency_limit,
      },
    },
    audience: {
      result: passesAudienceConditions(_, nudge),
      explanation: 'Booted user is not targeted by this nudge.',
      detail: {
        audience: nudge.audience,
      },
    },
    page: {
      result: passesPageConditions(_, nudge),
      explanation: 'Nudge is not shown on this page.',
      detail: {
        page: nudge.show_expression,
      },
    },
  };

  const nudgeServiceSnapshot = getNudgeServiceSnapshot(_, nudge.id)?.context;
  const currentStep = stepIndex ?? nudgeServiceSnapshot?.stepIndex ?? 0;
  const step = nudgeServiceSnapshot?.nudge.steps[currentStep];

  const stepChecks: Checks = {
    element: {
      result: passesPinnedElement(_, nudge, currentStep),
      explanation: 'Pinned element is not visible on the page.',
      detail: {
        element: step?.form_factor.type === 'pin' && step?.form_factor.anchor,
      },
    },
    command: {
      result: await passesCommandCheck(_, nudge, currentStep),
      explanation: 'Command associated with CTA is not available.',
      detail: {
        action: step?.content.find((b) => b.type === 'button')?.meta,
      },
    },
  };

  const flipCheck: Checks = nudgeServiceSnapshot
    ? {
        flip: {
          result: passesFlip(_, nudgeServiceSnapshot.prevPassedConditions, nudgeServiceSnapshot.triggerEvent),
          explanation: 'Nudge was shown and conditions have not changed.',
          detail: {
            prevPassedConditions: nudgeServiceSnapshot.prevPassedConditions,
          },
        },
      }
    : {};

  const snapShot: Checks = {
    ...globalChecks,
    ...nudgeChecks,
    ...stepChecks,
    ...flipCheck,
  };

  return {
    willRenderIfTriggered: Object.values(snapShot).every(({ result }) => result),
    checks: Object.fromEntries(
      Object.entries(snapShot).map(([key, value]) => [
        key,
        {
          ...value,
          result: value.result ? 'PASS' : 'FAIL',
        },
      ]),
    ),
    trigger: nudgeServiceSnapshot?.nudge.trigger,
    nudge: nudgeServiceSnapshot?.nudge,
    mostRecentTrigger: nudgeServiceSnapshot?.triggerEvent?.trigger,
  };
};
