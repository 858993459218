/** @jsx jsx */
import React, { CSSProperties } from 'react';
import { jsx } from '@emotion/core';

import type { INudgeStepContentButtonBlockType, INudgeStepType } from '@commandbar/internal/middleware/types';
import * as Reporting from '../../analytics/Reporting';
import Content from './Content';
import { useTheme } from 'emotion-theming';
import { ITheme } from '@commandbar/internal/client/theme';
import { SurveyResponseEvent } from '@commandbar/internal/client/EventHandler';
import CTAButton from './CTAButton';
import { ActorRefFrom } from 'xstate';
import NudgeMachine from '../../store/engine/nudges/nudgeMachine';
import { RenderMode } from './RenderNudge';

interface ContentContainerProps {
  type: 'modal' | 'popover' | 'pin';
  styleOverrides?: CSSProperties;
  step: INudgeStepType;
  service?: ActorRefFrom<typeof NudgeMachine>;
  markdownStyles: CSSProperties;
  primaryButtonStyles: CSSProperties;
  secondaryButtonStyles: CSSProperties;
  stepCountStyles: CSSProperties;
  renderMode: RenderMode;
  stepCount?: string;
}

const ContentContainer = ({
  type,
  service,
  styleOverrides,
  step,
  markdownStyles,
  primaryButtonStyles,
  secondaryButtonStyles,
  stepCountStyles,
  stepCount,
  renderMode,
}: ContentContainerProps) => {
  const { theme }: { theme: ITheme } = useTheme();

  const [surveyResponse, setSurveyResponse] = React.useState<SurveyResponseEvent['response'] | undefined>(undefined);

  const execNudgeAction = React.useCallback(
    (meta: INudgeStepContentButtonBlockType['meta']) => {
      if (!!step.content.find((block) => block?.type.startsWith('survey')) && surveyResponse !== undefined && service) {
        const context = service.getSnapshot()?.context;
        const isPreview = renderMode !== RenderMode.DEFAULT;

        /** Report all responses if the nudge is not live. Else, ignore preview responses to not clog up events for */
        if (context?.nudge && (!context.nudge.is_live || !isPreview)) {
          Reporting.surveyResponse(context.nudge, step, surveyResponse, isPreview);
        }
      }

      if (meta?.action?.type === 'dismiss') {
        service?.send({
          type: 'DISMISS',
        });
      } else {
        service?.send({
          type: 'ADVANCE',
          button_type: meta?.button_type,
        });
      }
    },
    [service, surveyResponse, step.id],
  );

  const lastStep = step.content[step.content.length - 1];

  const ctas = step.content
    .filter((block) => block.type === 'button')
    .sort((_a, b) => (b.type === 'button' && b.meta?.button_type === 'secondary' ? 1 : -1));
  let contentItems = step.content;
  if (!!ctas.length) {
    contentItems = step.content.filter((block) => block.type !== 'button');
  }

  return (
    <React.Fragment>
      {contentItems.map((block, idx) => (
        <Content
          key={idx}
          type={type}
          contentBlock={block}
          service={service}
          helpDocStyles={{
            color: theme.nudgeModal.helpDocPreviewColor,
            background: theme.nudgeModal.helpDocPreviewBackground,
            mixBlendMode: theme.nudgeModal.helpDocPreviewMixBlendMode as CSSProperties['mixBlendMode'],
          }}
          buttonStyles={primaryButtonStyles}
          styleOverrides={styleOverrides}
          markdownStyles={markdownStyles}
          setSurveyResponse={setSurveyResponse}
        />
      ))}

      {!!ctas.length && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {stepCount && <div style={stepCountStyles}>{stepCount}</div>}
          <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end', width: '100%' }}>
            {ctas.map((block, idx) => {
              if (block.type === 'button' && block?.meta?.label) {
                return (
                  <CTAButton
                    key={idx}
                    onClick={() => execNudgeAction(block.meta)}
                    styleOverrides={{
                      ...(block.meta.button_type === 'secondary' ? secondaryButtonStyles : primaryButtonStyles),
                      ...(!!stepCount ? { display: 'inline-flex', width: 'auto', maxWidth: '50%' } : {}),
                      ...(ctas.length > 1 && { maxWidth: '30%' }),
                    }}
                  >
                    {block.meta.label}
                  </CTAButton>
                );
              }
              return null;
            })}
          </div>
        </div>
      )}
      {!(lastStep?.type === 'button' && (lastStep.meta as any).label) && stepCount && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px' }}>
          <div style={stepCountStyles}>{stepCount}</div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ContentContainer;
