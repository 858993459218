import { getSDK } from '@commandbar/internal/client/globals';
import { _configuration } from '@commandbar/internal/client/symbols';
import * as Command from '@commandbar/internal/middleware/command';
import { Tab } from '@commandbar/internal/middleware/tab';
import { CommandCategory } from '@commandbar/internal/middleware/commandCategory';
import { getBaseURL, get } from '@commandbar/internal/middleware/network';
import * as Organization from '@commandbar/internal/middleware/organization';
import { Environment } from '@commandbar/internal/middleware/environment';
import { Nudge } from '@commandbar/internal/middleware/nudge';
import { Checklist } from '@commandbar/internal/middleware/checklist';
import * as AdditionalResource from '@commandbar/internal/middleware/additionalResource';
import { RecommendationSet } from '@commandbar/internal/middleware/recommendationSet';

import { IConfigEndpointResponse, IConfigType } from '@commandbar/internal/middleware/types';
import Logger from '@commandbar/internal/util/Logger';

import { State } from '../store';
import { clearEnvOverride } from '../store/app';
import { getSentry } from '@commandbar/internal/util/sentry';

export default function getConfig(_: State): Promise<IConfigType> {
  const sdk = getSDK();
  const { uuid, version, config } = sdk[_configuration];
  const {
    envOverride,
    env,
    engine: { isAdmin },
  } = _;

  return _getConfig({
    orgID: uuid,
    envOverride,
    env,
    version,
    config,
    clearEnvOverride: () => clearEnvOverride(_),
    isAdmin,
  });
}

export function getEnvOrVersion({
  envOverride,
  env,
  version,
}: {
  envOverride?: { env: string } | { version: string } | null;
  env?: string | null;
  version?: string | null;
}): { env: string } | { version: string } {
  // NOTE: pinning to a specific version will supercede environment. "envOverride" overries both.
  if (envOverride) {
    return envOverride;
  } else if (version) {
    if (typeof version === 'number') {
      return { version: `v${version}` };
    }
    return { version };
  } else if (env) {
    return { env };
  }

  return { env: 'latest' };
}

/**
 * This function is used to guard against decoding errors. If a decoding error occurs, we will log the error and return the default value.
 */
function guard<T>(meta: { field: string; orgID: string }, fn: () => T | undefined, defaultValue: T): T {
  try {
    const result = fn();
    if (result === undefined) return defaultValue;
    return result;
  } catch (e) {
    getSentry()?.captureException(e);

    Logger.error(`Error decoding config response (${meta.orgID}). Using default value for '${meta.field}'`, {
      error: e,
      field: meta.field,
      defaultValue,
    });

    return defaultValue;
  }
}

/**
 * This function is used to guard async functions against decoding errors. If a decoding error occurs, we will log the error and return the default value.
 */
async function guardAsync<T>(
  meta: { field: string; orgID: string },
  fn: () => Promise<T | undefined>,
  defaultValue: T,
): Promise<T> {
  try {
    const result = await fn();
    if (result === undefined) return defaultValue;
    return result;
  } catch (e) {
    getSentry()?.captureException(e);

    Logger.error(`Error decoding config response (${meta.orgID}). Using default value for '${meta.field}'`, {
      error: e,
      field: meta.field,
      defaultValue,
    });

    return defaultValue;
  }
}

export async function _getConfig({
  orgID,
  envOverride,
  env,
  version,
  config,
  clearEnvOverride,
  isAdmin,
}: {
  orgID: string;
  envOverride?: { env: string } | { version: string } | null;
  env: string | null;
  version?: string;
  config?: IConfigEndpointResponse;
  clearEnvOverride: () => void;
  isAdmin: boolean;
}): Promise<IConfigType> {
  const endpoint = (() => {
    const path = `organizations/${orgID}/${isAdmin ? 'config_for_admin' : 'config'}/`;

    // Organization takeover
    if (localStorage.getItem('commandbar.takeover')) {
      return path;
    }

    const envOrVersion = getEnvOrVersion({ envOverride, env, version });

    let queryString = '';
    if ('env' in envOrVersion) {
      queryString = `?env=${envOrVersion.env}`;
    } else if ('version' in envOrVersion) {
      queryString = `?version=${envOrVersion.version}`;
    }

    return `${path}${queryString}`;
  })();

  let data;
  try {
    if (config) {
      data = config;
    } else {
      data = (await get(endpoint)).data;
    }
  } catch (e) {
    Logger.error('Error loading config', { error: e, url: `${getBaseURL()}/${endpoint}` });
    clearEnvOverride();

    throw e;
  }

  const {
    commands,
    categories,
    organization,
    environments_with_versions,
    placeholders,
    nudges_v2,
    tabs,
    checklists,
    helphub_additional_resources,
    helphub_recommendation_sets,
  } = (data || {}) as Partial<IConfigEndpointResponse>;

  const decodedNudges = nudges_v2?.flatMap((nudge) => {
    try {
      return [Nudge.decode(nudge)];
    } catch (e) {
      getSentry()?.captureException(e);

      Logger.error('Error decoding nudge', { error: e, nudge });

      return [];
    }
  });

  return {
    commands: (commands ?? []).flatMap((command) =>
      guard({ orgID, field: `command[id=${command?.id}]` }, () => [Command.decode(command)], []),
    ),
    categories: (categories ?? []).flatMap((category) =>
      guard({ orgID, field: `categories[id=${category?.id}]` }, () => [CommandCategory.decode(category)], []),
    ),
    organization: guard(
      { orgID, field: 'organization' },
      () => Organization.decode(organization),
      Organization.unknownOrganization,
    ),
    environments: (environments_with_versions ?? []).flatMap((env) =>
      guard({ orgID, field: `environments_with_versions[id=${env?.id}]` }, () => [Environment.decode(env)], []),
    ),
    placeholders: await guardAsync(
      { orgID, field: 'placeholders' },
      async () => placeholders || (await Organization.listPlaceholders(orgID)),
      [],
    ),
    nudges: (decodedNudges ?? []).flatMap((nudge) =>
      guard({ orgID, field: `nudges[id=${nudge?.id}]` }, () => [nudge], []),
    ),
    checklists: (checklists ?? []).flatMap((checklist) =>
      guard({ orgID, field: `checklists[id=${checklist?.id}]` }, () => [Checklist.decode(checklist)], []),
    ),
    helphub_additional_resources: (helphub_additional_resources ?? []).flatMap((additional_resource) =>
      guard(
        { orgID, field: `helphub_additional_resources[id=${additional_resource?.id}]` },
        () => [AdditionalResource.decode(additional_resource)],
        [],
      ),
    ),
    helphub_recommendation_sets: (helphub_recommendation_sets ?? []).flatMap((recommendation_set) =>
      guard(
        { orgID, field: `helphub_recommendation_sets[id=${recommendation_set?.id}]` },
        () => [RecommendationSet.decode(recommendation_set)],
        [],
      ),
    ),
    tabs: (tabs ?? []).flatMap((tab) => guard({ orgID, field: `tabs[id=${tab?.id}]` }, () => [Tab.decode(tab)], [])),
  };
}
