import { dangerouslyRunJS, runRequestCommandAndReportFailure } from './../../../engine/ExecutionPath';
import * as Command from '@commandbar/internal/middleware/command';
import { ICommandType, RequestType } from '@commandbar/internal/middleware/types';
import * as Reporting from '../../../analytics/Reporting';
import { releaseConfetti } from '../../../components/Confetti';
import { InternalError } from '../../../engine/Errors';
import ExecutionPath, { runAndReportFailure } from '../../../engine/ExecutionPath';
import { interpolate, interpolateObject } from '../../../engine/Interpolate';
import { ExecuteStep, Step } from '../../../engine/step';
import { CommandOption } from '../../../engine/option';
import featureFlag from '../../../util/featureFlags';
import { EngineState } from '../state';
import * as EndUserStore from '../end-user/actions';
import * as EngineActions from '../../engine/actions';
import Logger from '@commandbar/internal/util/Logger';
import { isStandaloneEditor } from '@commandbar/internal/util/location';
import { linkExecutable, clickExecutable, helpDocExecutable } from './executables';
import { snapshot } from 'valtio';
import { SDK_INTERNAL_PREFIX } from '@commandbar/internal/client/globals';
import { getSentry } from '@commandbar/internal/util/sentry';

export const getContextForExternalUse = (_: EngineState, includeLegacyKeys = true) => {
  const context = snapshot(_.engine.context);
  const records = snapshot(_.engine.records);

  const recordsWithLegacyKeys = includeLegacyKeys
    ? Object.fromEntries(Object.entries(records).map(([key, value]) => [`commandbar-search-${key}`, value.records]))
    : {};

  // preserve compatibility with old context format
  return Object.fromEntries(
    Object.entries({
      ...context,
      ...recordsWithLegacyKeys,
    }).filter(([key]) => !key.startsWith(SDK_INTERNAL_PREFIX)),
  );
};

export const executeCallback = (
  _: EngineState,
  selections: { [argName: string]: any },
  s: ExecuteStep,
  callback: (...args: unknown[]) => unknown,
) => {
  const context = getContextForExternalUse(_, false);

  if (s.command.confirm) {
    if (window.confirm(s.command.confirm)) {
      runAndReportFailure(callback, selections, context, s.command);
    }
  } else {
    runAndReportFailure(callback, selections, context, s.command);
  }
};

export const fulfillExecuteStep = (_: EngineState, s: ExecuteStep): Step => {
  if (s.completed) {
    return s;
  }

  /**
   * Execute behavior is called after a timeout, but engine state is reset the moment the step is completed
   * Selections will be stale if we select them at the point of execution, instead of before execution
   */
  const selections = ExecutionPath.selections(_.engine);
  const getExecutable = (command: ICommandType, engine: EngineState['engine']): (() => any) => {
    // Overwrite callback, click, help, and link command functionality on standalone editor
    if (isStandaloneEditor) {
      switch (command.template.type) {
        case 'helpdoc':
          return helpDocExecutable(engine, command.template, command);
        case 'link':
          try {
            const _url = interpolate(command.template.value, engine, true, true, true);
            return () => engine.callbacks['commandbar-router'](_url);
          } catch (err) {
            getSentry()?.captureException(err);
            console.error('Could not interpolate context: ', command.template.value);
            return () => engine.callbacks['commandbar-router'](command.template.value);
          }
        case 'admin':
        case 'builtin':
        case 'callback':
        case 'clickByXpath':
        case 'clickBySelector':
        case 'click':
          return () => {
            executeCallback({ engine }, selections, s, engine.callbacks['__standalone-editor-cb']);
          };
        default:
          // Fallback to regular functionality for all other command types
          break;
      }
    }

    switch (command.template.type) {
      case 'helpdoc':
        return helpDocExecutable(engine, command.template, command);
      case 'link':
        return linkExecutable(engine, command.template, s?.alternateBehavior?.openLinkInNewTab);
      case 'admin':
      case 'builtin':
      case 'callback':
        const callbackName = command.template.value;
        const callback = engine.callbacks[callbackName];
        if (!callback) {
          throw new InternalError(`Callback is not available: [${callbackName}]`);
        }
        return () => {
          executeCallback({ engine }, selections, s, callback);
        };
      case 'clickByXpath':
      case 'clickBySelector':
      case 'click':
        return clickExecutable(engine, command.template);
      case 'appcues':
        if (!(window as any).Appcues || !(window as any).Appcues.show) {
          throw new InternalError('Appcues is not available');
        }
        return () => {
          const contentId = command.template.value.toString();
          (window as any).Appcues.show(contentId);
        };
      case 'request':
        const {
          method = 'get',
          url,
          headers,
          body,
          // onSend,
          onSuccess,
          onError,
        } = command.template.value as RequestType;

        const interpolatedRequest = interpolateObject({
          s: {
            url,
            headers,
            body,
          },
          engine,
          interpolateContext: true,
          interpolateArgs: true,
        }) as Omit<RequestType, 'method'>;

        const requestFn = (req: RequestType) => {
          const headers = !!req.headers ? new Headers(req.headers as HeadersInit) : undefined;
          const requestOptions = {
            method: req.method,
            headers,
            body: ['patch', 'put', 'post'].includes(req.method) ? JSON.stringify(req.body) : undefined,
          };

          return fetch(req.url, requestOptions);
        };

        let onSuccessFunc: any = undefined;
        if (onSuccess) {
          onSuccessFunc = engine.callbacks[onSuccess];
          if (!onSuccessFunc) {
            Logger.error(`onSuccess callback for request is not available, [${onSuccess}]`);
          }
        }

        let onErrorFunc: any = undefined;
        if (onError) {
          onErrorFunc = engine.callbacks[onError];
          if (!onErrorFunc) {
            Logger.error(`onError callback for request is not available, [${onError}]`);
          }
        }

        return () => {
          const request = () =>
            requestFn({
              method,
              ...interpolatedRequest,
            });

          runRequestCommandAndReportFailure(request, command, onSuccessFunc, onErrorFunc);
        };
      case 'webhook':
        return () => {
          const webhook = command.template.value.toString();
          const context = getContextForExternalUse(_, false);
          const args = selections;

          const payload = {
            args,
            context,
          };

          fetch(webhook, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });
        };
      case 'video':
        return () => {
          /** No-op */
        };
      case 'trigger':
        return () => {
          /** No-op */
        };
      case 'script':
        return () => {
          let script = '';
          const org_uid = engine?.organization?.id ? engine?.organization?.id.toString() : '';

          const context = getContextForExternalUse(_, false);
          const args = selections;

          // FIXME: feature flag
          // catchandrelease
          if (['8eafe599'].includes(org_uid)) {
            script = command.template.value.toString();
          }
          dangerouslyRunJS(script, args, context);
        };
      default:
        throw new InternalError('Invalid command execute type.');
    }
  };

  // potential changes here

  const executable = getExecutable(s.command, _.engine);

  // FIXME
  // Executing a command may have side-effects on the underlying app
  // The most relevant side-effect is the underlying app calling other
  // Command Bar APIs (like addContext)
  //
  // This timeout allows the ExecutionPathReducer to complete it's current
  // step before updating anything else.
  //
  // A more robust solution here would be to set a flag to wait for all Executes to
  // finish before allowing any API calls (add them to a pending queue while the flag is on)
  // That flag would probably live on window (we can't set it in the Reducer)
  //
  // This will probably also break if we are chaining multiple Execute steps together
  if (!_.engine.simulation) {
    const inputToLog = s.triggeredByShortcut ? _.engine.inputText : _.engine.inputText || _.engine.previousInputText;
    const meta = {
      ...(s.triggeredByShortcut && { shortcut: true }),
      customShortcut: _.engine.endUserStore.data.hotkeys[Command.commandUID(s.command)],
      ranking: _.engine.sortedOptions.findIndex((o) => {
        const c = o as CommandOption;
        return c?.command ? Command.commandUID(c.command) === Command.commandUID(s.command) : false;
      }),
      previousCommands: _.engine.executionHistory,
    };

    EndUserStore.storeRecentsOnExecute(_, s);
    const metaWithSelections = { ...meta, selections };

    const metaToReport = featureFlag({
      organization: _.engine.organization,
      orgIds: ['5725a2a3'],
      activeState: metaWithSelections,
      defaultState: meta,
    });
    Reporting.execution(
      s.command,
      inputToLog,
      {
        ...metaToReport,
        categoryText: _.engine.categories.find((c) => c.id === s.command.category)?.name,
      },
      s.eventSource,
    );
    _.engine.executionHistory.unshift(Command.commandUID(s.command));

    EngineActions.sendTriggerToAllNudges(_, {
      type: 'on_command_execution',
      meta: { command: Command.commandUID(s.command) },
    });

    EngineActions.triggerChecklists(_, {
      type: 'on_command_execution',
      meta: { command: Command.commandUID(s.command) },
    });

    if (!!s.command.celebrate) {
      releaseConfetti(s.command.celebrate);
    }

    setTimeout(() => {
      executable();
    }, 5);
  }

  s.completed = true;
  return s;
};
