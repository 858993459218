import { createMachine, assign, spawn, send, type ActorRefFrom, type Interpreter } from 'xstate';
import { pure } from 'xstate/lib/actions';
import isEqual from 'lodash/isEqual';

import { passesGlobalLimit, passesMaxRenderedNudges } from './selectors';
import NudgeMachine from './nudgeMachine';

import type { INudgeType } from '@commandbar/internal/middleware/types';
import type { EngineState } from '../state';

export type TriggerEvent = {
  type: 'TRIGGER';
  trigger: INudgeType['trigger'];
  nudgeId?: INudgeType['id'];
  nudge?: INudgeType;
  overrides?: {
    admin?: boolean;
    preview?: boolean;
    globalLimit?: boolean;
    maxRenderedNudges?: boolean;
    frequency?: boolean;
    audience?: boolean;
    page?: boolean;
    pinnedElement?: boolean;
    ctaAction?: boolean;
    reporting?: boolean;
    saveProgress?: boolean;
    status?: boolean;
    stepIndex?: number;
  };
};

type NudgeManagerEvents =
  | TriggerEvent
  | { type: 'END_USER_STORE_LOADED' }
  | { type: 'PREVIEW'; nudge: INudgeType }
  | { type: 'STOP_PREVIEW' };

type NudgeManagerContext = {
  nudgeMachines: Map<string, ActorRefFrom<typeof NudgeMachine>>;
  previewMachine: ActorRefFrom<typeof NudgeMachine> | null;
  triggerEventQueue: Array<TriggerEvent>;
  triggerEvent: TriggerEvent | null;
};

export type NudgeManagerService = Interpreter<
  NudgeManagerContext,
  any,
  NudgeManagerEvents,
  { value: any; context: NudgeManagerContext },
  any
>;

const NudgeManagerMachine = (_: EngineState, nudges: Array<INudgeType>, isAdmin: boolean) => {
  const nudgeManagerContext: NudgeManagerContext = {
    nudgeMachines: new Map(),
    previewMachine: null,
    triggerEventQueue: [],
    triggerEvent: null,
  };

  return createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QDkCu0wAICyBDAdrjAE4DEAygCoDyACgPq0BKAogGoCSLA6gNoAMAXUSgADgHtYASwAuU8fhEgAHogCM-AOz8AdAE4AzIYAcmtQBY9etQCY1AGhABPRDYPmda4zf4Hv5835vADYAX1DHNAwcAiIwMmZ2Lj4hJQlpOQUlVQQNYwMdbys9Y3MDYLcAVnNHFwQ-G301Sr1zOw0tb3DI9BgYwhIdAEEAd1xZKXwoTABVWHjMchlxYjBSSiYOAHEtliYBYSQQdImso5zq3StjC3NNc3y9Ftr1YI9rCoNKtTUDe-4nt0QFE+ngBvFhmMJlNZvNiItlqtSCxkAARegzch7ehUaisegAGWoQ1RLFRBzSklOinOrmMlR05jUmhsphsZgq92CLwQNkqDOalWMAPMwTM2kqQJBWDBcWIOgAYisxsQIJNppRiFIoCRYDoCVJYDIwPh1etNjs9hSjidMjTQDlLI1Kuz6fxgs0Kt8eZVNMFPHp2pY-nZqlLejLYoMlcQVWqYZrtbqdImdfEzdaxFS7dlEGz9H5AizKr5bDVnOpKgZdD87AZ2XZhZpw9FZdHlbhVerMKnkwBhAAWYAAxgBrbtbAA24gARrhJ5gDQBbWSkTPHbPye0qRB3AotXwlj2VL0OCu5QLGfQVB6B-jsqs2FugqMQmNx7u9+J6wcj8cwqdZ3nRcpBXGQ1zUQ4swyLdcwQR4dHddwtD5UsbHLOoLCCa90OMO8H3rZ9I3BeV307eMNS1NNiB-Icx27PBlEwJgTQgeJIDXVIbU3M4HV3ModCFN4AnKE8+TPTDPR0GwfGQ0x+HvX4iP6OVFQ7LsEyo-s6P-aZGOY1j2IgCCoI3GDeJ3eCjEQ4JkOLNCMPUKSZN8S9tEUgxlLbN91IonstO-HRf3omE5niTjTNtWDaQQJ0dGCUotHdflvBuHlrEaTR+XdGTyiZX0vNfUjfM-AKaKCnTuzCsheEgylzO3HIEKQotUIMMt0tsHQsqPXK3maZsgXwcQ2PgI5pRUkh6upOC3A8Cwfna4xgjEktjB5NwdCMLwxOZENDE8iJgQjSaIVGcY5FCuEERWMBppzGL2v9ZkfkMF01E5dweQGwprHpesVqKdlCpItTY3I0qk2-e7or43lvE8Jlfi8FbTx5L4GUMPQxVZJkss0Q6elbIqwY-TSofKg0jRNdUYYsi5mm6m4soS1HxJ5SxdDKbG3QCNmQdUsiNMoim9S-LUpjpxrdzFTwQz5Y80fPZp+AFWxfgbLwtAF9tweF-zRYqv8J2nOcF2XWQpbggxq0RxaUdWiT1FwrbNE0fIWVMYxlsGomX1BoW-PF2jjZhfSWPwNjVggK3HtthbkeW1abHSvRNHim35YT4I9B1ny9aDsqQ5C6ZqtjuGbZrJGlrZ71zzMXQbFR9XkbeMJwlCIA */
      id: 'Nudge Manager',

      context: nudgeManagerContext,

      states: {
        'Awaiting User Store': {
          on: {
            TRIGGER: {
              actions: 'enqueueTrigger',
              target: 'Awaiting User Store',
              internal: true,
              description: "Only triggers that match any of the spawned nudge services' triggers will be enqueued.",
            },

            END_USER_STORE_LOADED: 'Forwarding Triggers',
          },
        },

        'Forwarding Triggers': {
          entry: ['unloadTriggerQueue'],

          states: {
            Listening: {
              on: {
                TRIGGER: {
                  target: 'Checking User',
                  actions: 'setTriggerEvent',
                },
              },
            },

            Triggering: {
              entry: 'forwardTrigger',
              always: 'Listening',
            },

            'Checking Global Limit': {
              always: [
                {
                  target: 'Triggering',
                  cond: 'passesGlobalLimit',
                },
                'Listening',
              ],

              description: `If we've exceeded the maximum number of nudges seen within a period, abandon the trigger.`,
            },

            'Checking Max Rendered': {
              always: [
                {
                  target: 'Checking Global Limit',
                  cond: 'passesMaxNudgesRendered',
                },
                {
                  target: 'Listening',
                },
              ],

              description: `We only ever want to show MAX_RENDERED_NUDGES at a time. If we're already at that limit, abandon the trigger event.`,
            },

            'Checking User': {
              always: [
                {
                  target: 'Checking Max Rendered',
                  cond: 'passesUser',
                },
                'Listening',
              ],

              description: `By default, we will only forward triggers for non-admins. This prevents nudges from popping up while using the application while logged into the editor.

This can be bypassed by setting the preview or admin overrride on a trigger.`,
            },
          },

          initial: 'Listening',
        },
      },

      schema: { events: {} as NudgeManagerEvents, context: {} as NudgeManagerContext },
      predictableActionArguments: true,
      preserveActionOrder: true,
      tsTypes: {} as import('./nudgeManagerMachine.typegen').Typegen0,
      initial: 'Awaiting User Store',

      on: {
        STOP_PREVIEW: {
          target: '.Forwarding Triggers',
          actions: ['dismissNudges', 'stopPreviewNudgeMachine'],
        },

        PREVIEW: {
          target: '.Forwarding Triggers',
          actions: 'spawnPreviewMachine',
        },
      },

      entry: 'spawnNudgeMachines',
    },
    {
      actions: {
        spawnNudgeMachines: assign((context) => ({
          ...context,
          nudgeMachines: new Map(
            nudges.map((nudge) => [nudge.id.toString(), spawn(NudgeMachine(_, nudge), String(nudge.id))]),
          ),
        })),

        spawnPreviewMachine: assign({
          previewMachine: (_context, { nudge }) => spawn(NudgeMachine(_, nudge), String(nudge.id)),
        }),

        enqueueTrigger: assign({
          triggerEventQueue: ({ triggerEventQueue, nudgeMachines }, triggerEvent) => {
            const nudgeMachineTriggers = Array.from(nudgeMachines.values()).map(
              (nudgeMachine) => nudgeMachine.getSnapshot()?.context.nudge.trigger,
            );

            if (nudgeMachineTriggers.includes(triggerEvent.trigger)) {
              return [...triggerEventQueue, triggerEvent];
            }

            return triggerEventQueue;
          },
          triggerEvent: ({ triggerEventQueue }) => triggerEventQueue[0],
        }),

        unloadTriggerQueue: (context) => {
          const { triggerEventQueue, nudgeMachines } = context;

          for (const triggerEvent of triggerEventQueue) {
            for (const nudgeMachine of nudgeMachines.values()) {
              const nudge = nudgeMachine.getSnapshot()?.context.nudge;

              if (isEqual(triggerEvent.trigger, nudge?.trigger)) {
                nudgeMachine.send(triggerEvent);
              }
            }
          }

          return {
            ...context,
            triggerQueue: [],
          };
        },

        setTriggerEvent: assign({ triggerEvent: (_context, triggerEvent) => triggerEvent }),

        forwardTrigger: pure(({ triggerEvent, nudgeMachines, previewMachine }, event) => {
          if (triggerEvent?.overrides?.preview && previewMachine) {
            return send(triggerEvent ?? event, { to: previewMachine });
          }

          return Array.from(nudgeMachines.values()).map((machine) => send(triggerEvent ?? event, { to: machine }));
        }),

        dismissNudges: ({ nudgeMachines, previewMachine }) => {
          for (const nudgeMachine of nudgeMachines.values()) {
            nudgeMachine.send('DISMISS');
          }
          previewMachine?.send('DISMISS');
        },

        stopPreviewNudgeMachine: ({ previewMachine }, _event) => {
          previewMachine?.stop?.();
        },
      },
      guards: {
        passesUser: ({ triggerEvent }) =>
          triggerEvent?.overrides?.admin || triggerEvent?.overrides?.preview || !isAdmin,
        passesMaxNudgesRendered: ({ triggerEvent }) =>
          triggerEvent?.overrides?.maxRenderedNudges || passesMaxRenderedNudges(_),
        passesGlobalLimit: ({ triggerEvent }) => triggerEvent?.overrides?.globalLimit || passesGlobalLimit(_),
      },
    },
  );
};

export default NudgeManagerMachine;
