/* @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { ChatMessage as ChatMessageInterface } from '../../client_api/search';
import { HelpHubDoc } from '../../store/engine';
import { AnimationTransition, builtinKeyframes } from '../../hooks/useDelayUnmount';
import Icon from '@commandbar/internal/client/Icon';
import { useStore } from '../../hooks/useStore';
import useTheme from '../../hooks/useTheme';

import { BotMessage } from './BotMessage';
import { CB_COLORS } from '@commandbar/design-system/colors';
import BotAvatar from './BotAvatar';
import { IContinuationType } from '@commandbar/internal/middleware/types';

type ChatMessageProps = {
  isLoading: boolean;

  index: number;
  setCurrentDoc: (doc: HelpHubDoc) => void;
  addMessage: (message: ChatMessageInterface) => void;
  showContinuations: boolean;
  setSelectedContinuation: (continuation: IContinuationType) => void;
  chatId?: string;
  history?: ChatMessageInterface[];
  message: ChatMessageInterface;
};

export const ChatMessage = React.forwardRef<HTMLDivElement, ChatMessageProps>((props, ref) => {
  const {
    index,
    chatId,
    message,
    history = [],
    setCurrentDoc,
    addMessage,
    showContinuations,
    setSelectedContinuation,
    isLoading,
  } = props;

  const { engine } = useStore();
  const { theme } = useTheme();

  const isUserMessage = message.type === 'user';

  return (
    <AnimationTransition
      entry={
        index === 0
          ? { keyframes: builtinKeyframes.slideUp(17), durationMs: 300 }
          : { keyframes: builtinKeyframes.fadeInSlideDown, durationMs: 300 }
      }
      isMounted={true}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: '16px',
          gap: '16px',
          backgroundColor: isUserMessage ? 'rgba(120, 120, 120, 0.075)' : undefined,
          color: CB_COLORS.neutral800,
        }}
      >
        {isUserMessage ? (
          <div style={{ width: '40px', height: '40px', flexShrink: 0 }} />
        ) : engine.chatAvatar ? (
          <Icon
            icon={engine.chatAvatar}
            useDefaultSVGColor={true}
            size={'40px'}
            style={{ width: '40px', height: '40px', flexShrink: 0 }}
          />
        ) : (
          <BotAvatar />
        )}

        <div
          style={{
            flexGrow: 1,
            minWidth: 0,
            paddingTop: '8px',
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 500,
            fontFamily: theme.helpHub.fontFamily,
            fontStyle: 'normal',
          }}
        >
          {isUserMessage ? (
            <div ref={ref}>{message.message}</div>
          ) : (
            <BotMessage
              isLoading={isLoading}
              isFirstMessage={index === 0}
              setCurrentDoc={setCurrentDoc}
              addMessage={addMessage}
              chatID={chatId}
              history={history}
              showContinuations={showContinuations}
              setSelectedContinuation={setSelectedContinuation}
              liveAnswer={message.message}
            />
          )}
        </div>
      </div>
    </AnimationTransition>
  );
});

export default ChatMessage;
