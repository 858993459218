/** @jsx jsx  */
import { css, jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { HelpHubDoc } from '../../store/engine';
import { useStore } from '../../hooks/useStore';
import { ChatMessage, fetchContinuations, NO_ANSWER } from '../../client_api/search';
import { AnimationTransition, builtinKeyframes } from '../../hooks/useDelayUnmount';
import { IContinuationType, IInstantAnswerType } from '@commandbar/internal/middleware/types';
import * as Engine from '../../store/engine/actions';
import { DEFAULT_SUGGESTIONS_KEY } from '../helphub/HelpHub';
import useTheme from '../../hooks/useTheme';
import { useAction } from '../../hooks/useAction';
import { useStyles } from '../helphub/useStyles';

import { MessageActions } from './MessageActions';
import FeedbackButtons from './FeedbackButtons';
import { Continuations } from './Continuations';
import { LiveAnswer } from './LiveAnswer';
import { ExperienceButton } from './ExperienceButton';
import ActionButton from './ActionButton';
import { CB_COLORS } from '@commandbar/design-system/colors';

export type BotMessageProps = {
  isLoading: boolean;
  isFirstMessage: boolean;
  setCurrentDoc: (doc: HelpHubDoc) => void;
  addMessage: (message: ChatMessage) => void;
  showContinuations: boolean;
  setSelectedContinuation: (continuation: IContinuationType) => void;
  liveAnswer: IInstantAnswerType | null;
  chatID?: string;
  history?: ChatMessage[];
};

export const BotMessage: React.FC<BotMessageProps> = (props) => {
  const { engine } = useStore();
  const { theme } = useTheme();
  const { isLoading, isFirstMessage, liveAnswer, history, chatID } = props;

  const [continuations, setContinuations] = React.useState<IContinuationType[]>([]);
  const styles = useStyles();

  const copilotSessionState = chatID !== undefined ? engine.copilotSessionState[chatID] : undefined;

  const setCopilotSessionState = useAction(Engine.setCopilotSessionState);
  const assignContinuations = useAction(Engine.setContinuations);
  const executeAction = useAction(Engine.executeAction);
  const toggleHelpHubVisible = useAction(Engine.toggleHelpHubVisible);

  const isInitialBotMessage = history?.length === 1 && history[0].type === 'bot';

  const lastMessage = history && !!history.length && history[history.length - 1].message;
  const noAnswer =
    history && !!history.length && lastMessage && typeof lastMessage !== 'string' && lastMessage?.no_answer;

  useEffect(() => {
    if (isInitialBotMessage) {
      setContinuations(engine.helpHub.continuations[DEFAULT_SUGGESTIONS_KEY] || []);
    }
  }, [engine.helpHub.continuations]);

  const unmounted = React.useRef(false);

  useEffect(() => {
    const hasAnswerAndHistory = !!liveAnswer && !!history && !!chatID;

    if (
      !isInitialBotMessage &&
      !!engine.organization &&
      hasAnswerAndHistory &&
      !isLoading &&
      !noAnswer &&
      engine.organization.helphub_continuations_enabled
    ) {
      if (!!engine.helpHub.continuations[liveAnswer.answer]) {
        setContinuations(engine.helpHub.continuations[liveAnswer.answer].slice(0, 2));
        return;
      }

      fetchContinuations(
        engine.organization,
        chatID,
        history,
        liveAnswer.passage_id !== null ? [liveAnswer.passage_id] : [],
        liveAnswer.command_id !== null ? [liveAnswer.command_id] : [],
      ).then((continuations) => {
        const newContinuations = continuations?.filter((c) => {
          return !Object.values(engine.helpHub.continuations).some((existing) => existing.includes(c));
        });

        if (unmounted.current) return;

        assignContinuations(liveAnswer.answer, newContinuations ? newContinuations : []);
        setContinuations(newContinuations ? newContinuations.slice(0, 2) : []);
      });
    }
  }, [liveAnswer]);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const state: 'loading' | 'answer' | 'no-answer' | 'none' = (() => {
    if (isLoading) {
      return 'loading';
    } else if (liveAnswer?.answer === NO_ANSWER.answer) {
      return 'no-answer';
    } else if (!!liveAnswer && !!liveAnswer.answer) {
      return 'answer';
    }
    return 'none';
  })();

  const isAnswerEmpty = React.useMemo(() => {
    const strippedAnswer = liveAnswer?.answer?.replace(/<\/?[^>]+(>|$)/g, '').trim() || '';
    return strippedAnswer.length === 0;
  }, [props.liveAnswer?.answer]);

  if (state === 'none') {
    return null;
  }

  const experiences = props.liveAnswer?.experiences || null;

  return (
    <div style={{ maxWidth: '100%', minWidth: 0, display: 'flex', flexDirection: 'column' }}>
      <div css={[styles.chatMessage]}>
        <AnimationTransition
          entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
          isMounted={isAnswerEmpty && state !== 'no-answer'}
        >
          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', padding: '8px 0px' }}>
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                css={css`
                  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;

                  @keyframes pulse {
                    0%,
                    100% {
                      opacity: 1;
                    }
                    50% {
                      opacity: 0.5;
                    }
                  }
                `}
                style={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor: CB_COLORS.neutral600,
                  animationDelay: `${index * 0.25}s`,
                }}
              />
            ))}
          </div>
        </AnimationTransition>

        <AnimationTransition
          entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
          isMounted={state === 'answer' || (state === 'loading' && Boolean(props.liveAnswer?.answer))}
        >
          {(() => {
            // hide the "no answer" response if there are experiences and for as long as the experiences call is loading
            if (!(props.liveAnswer?.no_answer && (!experiences || experiences.length > 0))) return true;

            return false;
          })() ? (
            <React.Fragment>
              <LiveAnswer {...props} />
              {experiences && experiences.length > 0 && <br />}
            </React.Fragment>
          ) : null}

          {!isLoading &&
            experiences &&
            experiences.length > 0 &&
            experiences.map(
              (experience, index) =>
                !!experience && (
                  <React.Fragment>
                    <p>{experience.description}</p>
                    <ExperienceButton
                      chatID={props.chatID}
                      addMessage={props.addMessage}
                      experience={experience}
                      key={index}
                    ></ExperienceButton>
                    {index < experiences.length - 1 && (
                      <React.Fragment>
                        <br /> <br />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ),
            )}
        </AnimationTransition>

        <AnimationTransition
          entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
          isMounted={state === 'no-answer'}
        >
          <div
            style={{
              fontFamily: theme.helpHub.fontFamily,
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '20px',
              color: '#42424D',
            }}
          >
            We couldn't find the answer to your question. Please try again later.
          </div>
        </AnimationTransition>

        {!isLoading && <MessageActions history={history} liveAnswer={liveAnswer} />}

        {props.showContinuations && copilotSessionState?.state === 'collecting_arguments' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              padding: '0px',
              alignItems: 'flex-start',
              marginTop: '12px',
            }}
          >
            {props.liveAnswer?.copilot_argument_values && (
              <ActionButton
                onClick={(e) => {
                  executeAction(
                    { type: 'execute_command', meta: { command: copilotSessionState.command.id.toString() } },
                    e,
                    props.liveAnswer?.copilot_argument_values || {},
                  );
                  toggleHelpHubVisible();
                }}
              >
                Confirm
              </ActionButton>
            )}
            {chatID !== undefined && (
              <ActionButton
                variant="secondary"
                onClick={() => {
                  // abort argument-collecting and remove incomplete "bot" message
                  setCopilotSessionState(chatID, { state: 'initial' });
                  props.addMessage({
                    message: {
                      command_id: null,
                      command_title: '',
                      passage_id: null,
                      answer: `Got it, I won't continue with '${copilotSessionState.command.text}'. Is there anything else I can help you with?`,
                    },
                    type: 'bot',
                  });
                }}
              >
                Nevermind
              </ActionButton>
            )}
          </div>
        )}

        <AnimationTransition
          entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
          isMounted={continuations.length > 0 && props.showContinuations}
        >
          <Continuations
            continuations={continuations}
            isLoading={false}
            setSelectedContinuation={props.setSelectedContinuation}
          />
        </AnimationTransition>

        {!isFirstMessage && !isLoading && <FeedbackButtons liveAnswer={liveAnswer} />}
      </div>
    </div>
  );
};

export default BotMessage;
