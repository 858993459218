/** @jsx jsx  */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import debounce from 'lodash/debounce';

import { useAction } from '../../hooks/useAction';
import { useStore } from '../../hooks/useStore';
import { useStyles } from './useStyles';
import useTheme from '../../hooks/useTheme';

import * as Engine from '../../store/engine/actions';
import * as Reporting from '../../analytics/Reporting';

import Doc from './Doc';
import SearchResults from './SearchResults';
import Recommendations from './Recommendations';
import Input from './Input';
import AdditionalResources from './AdditionalResources';
import LoadingIndicator from '../select/input/LoadingIndicator';

import { AnimationTransition, builtinKeyframes } from '../../hooks/useDelayUnmount';
import Chat from '../copilot/Chat';
import { ChatMessage, fetchSearchSuggestions, NO_ANSWER } from '../../client_api/search';
import PoweredBy from './PoweredBy';
import ChatOnly from './ChatOnly';
import { isChatOnlyMode } from '../../store/engine/help-hub/selectors';

export const DEFAULT_SUGGESTIONS_KEY = '__commandbar_suggestions';

const HelpHub = () => {
  const { engine } = useStore();
  const { query, hubDoc: currentDoc, searchResults: docs, parsingUrlParams, loading: isLoading } = engine.helpHub;
  const setIsLoading = useAction((_, loading: boolean) => {
    _.engine.helpHub.loading = loading;
  });
  const [localQuery, setLocalQuery] = useState(query);
  const setHelpHubQuery = useAction(Engine.setHelpHubQuery);
  const setCurrentDoc = useAction(Engine.setHelpHubDoc);
  const { theme } = useTheme();
  const styles = useStyles();

  //** Local state for chat that we can move to engine if needed  */
  const [isChatMode, _setIsChatMode] = useState(false);
  const [chatID, setChatID] = React.useState<string | undefined>(undefined);
  const [chatHistory, setChatHistory] = React.useState<ChatMessage[]>([]);

  const setIsChatMode = (value: boolean) => {
    _setIsChatMode(value);
    if (value) {
      Reporting.helpHubEngagement({ query: localQuery }, 'chat_started');
    }
  };

  const chatOnlyMode = isChatOnlyMode(engine);

  const setSuggestions = useAction(Engine.setContinuations);
  const [suggestionState, setSuggestionState] = useState<'none' | 'loading' | 'loaded'>('none');

  React.useEffect(() => {
    if (engine.organization?.helphub_ai_enabled && engine.organization?.helphub_suggested_queries_enabled) {
      if (engine.organization.helphub_manual_suggested_queries.length > 0) {
        const randomizedItems = [...engine.organization.helphub_manual_suggested_queries]
          .sort(() => 0.5 - Math.random())
          .slice(0, 3);

        setSuggestions(DEFAULT_SUGGESTIONS_KEY, randomizedItems);
        setSuggestionState('loaded');
        return;
      }
      setSuggestionState('loading');
      fetchSearchSuggestions(engine.organization.id.toString()).then((resp) => {
        const suggestions = resp?.suggestions?.slice(0, 2) || [];

        setSuggestions(DEFAULT_SUGGESTIONS_KEY, suggestions);
        if (resp?.suggestions && resp?.suggestions?.length > 0) {
          setSuggestionState('loaded');
        } else {
          setSuggestionState('none');
        }
      });
    }
  }, [
    engine.organization?.id,
    engine.organization?.helphub_ai_enabled,
    engine.organization?.helphub_suggested_queries_enabled,
    engine.organization?.helphub_manual_suggested_queries,
  ]);

  const suggestions = engine.helpHub.continuations[DEFAULT_SUGGESTIONS_KEY] || [];

  const debounceOnChangeQuery = React.useCallback(
    debounce((value: string) => {
      setHelpHubQuery(value);
      setIsLoading(false);
      Reporting.helpHubEngagement({ query: value }, 'search');
    }, 500),
    [],
  );

  const onChangeQuery = (value: string) => {
    setLocalQuery(value);

    if (!engine?.organization?.helphub_ai_enabled) {
      setIsLoading(true);
      debounceOnChangeQuery(value);
    }
  };

  React.useEffect(() => {
    setLocalQuery(query);
  }, [query]);

  const submitQuery = (query: string) => {
    if (engine.helpHub.query === query) return;
    setHelpHubQuery(query || '');
    if (!query) return;
    setIsLoading(true);
    Reporting.helpHubEngagement({ query: query }, 'search');
  };

  type HELPHUB_STATE =
    | 'chatOnly'
    | 'parsingUrlParams'
    | 'empty'
    | 'search-loading'
    | 'search-finished'
    | 'doc'
    | 'chat';

  const helpHubStateRef = React.useRef<HELPHUB_STATE>();
  helpHubStateRef.current = (() => {
    /** tmp: explicit states for hh */
    if (chatOnlyMode) return 'chatOnly';
    if (parsingUrlParams) return 'parsingUrlParams'; /** fixme - might be able to remove this */
    if (isChatMode && engine.organization?.helphub_ai_enabled) return 'chat';
    if (currentDoc) return 'doc';
    if (!!chatHistory.length && engine.organization?.helphub_ai_enabled) return 'chat';
    if (isLoading) return 'search-loading';
    if (query) return 'search-finished';
    return 'empty';
  })();

  const helpHubState = helpHubStateRef.current;

  const containerStyles = {
    ...styles.contentContainer,
    background: theme.helpHub.background,
    position: 'relative' as const,
  };

  switch (helpHubState) {
    case 'chatOnly':
      return (
        <ChatOnly
          chatID={chatID}
          containerStyles={containerStyles}
          chatHistory={chatHistory}
          setChatID={setChatID}
          setChatHistory={setChatHistory}
          setIsChatMode={setIsChatMode}
        />
      );
    case 'parsingUrlParams':
      return (
        <div style={containerStyles}>
          <LoadingIndicator style={styles.initialLoadingIcon} size={40} isLoading />
        </div>
      );

    case 'doc':
      return (
        <div style={containerStyles}>
          {!!currentDoc ? (
            <Doc
              doc={currentDoc}
              setCurrentDoc={setCurrentDoc}
              setChatHistory={setChatHistory}
              setIsChatMode={setIsChatMode}
            />
          ) : null}
        </div>
      );
    case 'search-loading':
    case 'search-finished':
    case 'empty':
      const hasAdditionalResources =
        engine.helpHub.additionalResources && engine.helpHub.additionalResources.length > 0;

      return (
        <div style={containerStyles}>
          <AnimationTransition isMounted>
            <Input
              query={localQuery}
              onChangeQuery={onChangeQuery}
              onSubmit={submitQuery}
              suggestionsState={suggestionState}
              setIsChatMode={(isChatMode) => {
                if (isChatMode && !chatHistory.length && localQuery?.length === 0) {
                  setChatHistory([
                    {
                      message: {
                        ...NO_ANSWER,
                        answer:
                          engine.organization?.helphub_chat_welcome_message || 'Hey there! What can we help with?',
                      },
                      type: 'bot',
                    },
                  ]);
                } else if (isChatMode && !chatHistory.length && localQuery && localQuery?.length !== 0) {
                  setChatHistory((prev) => [...prev, { message: localQuery, type: 'user' }]);
                }
                setIsChatMode(isChatMode);
              }}
              children={
                !!suggestions?.length &&
                !!engine?.organization?.helphub_suggested_queries_enabled &&
                !!engine?.organization?.helphub_ai_enabled && (
                  <React.Fragment>
                    {suggestions.map((continuation) => {
                      const selectSuggestedQuery = () => {
                        setChatHistory((prev) => [...prev, { message: continuation, type: 'user' }]);
                        setIsChatMode(true);
                        Reporting.helpHubSuggestedQuery({ query: engine.helpHub.query }, continuation);
                      };

                      return (
                        <div
                          role="button"
                          tabIndex={0}
                          key={continuation}
                          css={styles.chatSuggestion}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.keyCode === 13) {
                              selectSuggestedQuery();
                            }
                          }}
                          onClick={() => {
                            selectSuggestedQuery();
                          }}
                        >
                          <span style={{ marginLeft: '5px' }}>{continuation}</span>
                        </div>
                      );
                    })}
                  </React.Fragment>
                )
              }
            />
          </AnimationTransition>
          <AnimationTransition
            entry={{ keyframes: builtinKeyframes.fadeInSlideDown }}
            isMounted={helpHubState === 'search-finished'}
            style={{ overflowY: 'auto' }}
          >
            <SearchResults docs={docs} setCurrentDoc={setCurrentDoc} />
          </AnimationTransition>
          <AnimationTransition
            entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 100 }}
            isMounted={helpHubState === 'empty'}
            style={{ overflowY: 'auto', height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Recommendations setCurrentDoc={setCurrentDoc} />
            {hasAdditionalResources && <AdditionalResources />}
            <PoweredBy
              style={{
                boxShadow: hasAdditionalResources ? 'none' : theme.helpHubAdditionalResources.boxShadow,
              }}
            />
          </AnimationTransition>
        </div>
      );
    case 'chat':
      return (
        <div style={containerStyles}>
          <AnimationTransition isMounted style={{ height: '100%' }}>
            <Chat
              chatID={chatID}
              chatHistory={chatHistory}
              setCurrentDoc={setCurrentDoc}
              setChatHistory={setChatHistory}
              setIsChatMode={setIsChatMode}
              setChatID={setChatID}
            />
          </AnimationTransition>
        </div>
      );
  }
};

export default HelpHub;
