/** @jsx jsx  */
import { jsx, css } from '@emotion/core';
import React, { Fragment, useEffect } from 'react';
import { IInstantAnswerType } from '@commandbar/internal/middleware/types';
import Logger from '@commandbar/internal/util/Logger';
import useTheme from '../../hooks/useTheme';
import { useStore } from '../../hooks/useStore';
import { HelpHubDoc } from '../../store/engine';
import { draftDocToFullDoc } from '../../store/engine/help-hub/helpers';
import { getSentry } from '@commandbar/internal/util/sentry';

type LiveAnswerProps = {
  isLoading: boolean;
  liveAnswer: IInstantAnswerType | null;
  setCurrentDoc: (doc: HelpHubDoc) => void;
};

function findLastTextNode(node: Node): Node | null {
  if (node.nodeType === Node.TEXT_NODE && node.nodeValue && /\S/.test(node.nodeValue)) {
    // Checks if the text node contains anything other than white space
    return node;
  }

  let lastTextNode = null;
  for (let i = node.childNodes.length - 1; i >= 0; i--) {
    lastTextNode = findLastTextNode(node.childNodes[i]);
    if (lastTextNode) break;
  }

  return lastTextNode;
}

export const LiveAnswer: React.FC<LiveAnswerProps> = ({ isLoading, liveAnswer, setCurrentDoc }) => {
  const { engine } = useStore();
  const { theme } = useTheme();

  const handleCodeCopyClick = (parent: Element) => async () => {
    const { children } = parent;
    const { innerText } = Array.from(children)[0] as HTMLElement;
    try {
      await navigator.clipboard.writeText(innerText);
    } catch (err) {
      getSentry()?.captureException(err);
      Logger.error('Failed to copy text to clipboard:', err);
    }
  };

  const sourceUrl =
    liveAnswer?.doc?.command.template.type === 'helpdoc' && !!liveAnswer.doc.command.template.value
      ? liveAnswer.doc.command.template.value
      : null;

  const shouldShowSource = liveAnswer?.doc?.command.training_only ? !!sourceUrl : true;

  useEffect(() => {
    const highlights = document.querySelectorAll('div.codehilite');

    highlights.forEach((div) => {
      // create the copy button
      const copy = document.createElement('button');
      copy.innerHTML = 'Copy';
      // add the event listener to each click
      copy.addEventListener('click', handleCodeCopyClick(div));
      // append the copy button to each code block
      div.append(copy);
    });
  }, [liveAnswer]);

  const appendSource = (answer: string) => {
    if (!!liveAnswer?.doc && engine.helpHub?.hubDoc?.command?.id !== liveAnswer?.doc.command.id && shouldShowSource) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = liveAnswer.answer;

      const lastTextNode = findLastTextNode(tempDiv);
      if (lastTextNode && lastTextNode instanceof Text) {
        const newSpan = document.createElement('span');
        newSpan.innerHTML = '1';
        newSpan.classList.add('cb-copilot-source');
        newSpan.title = liveAnswer.doc.title;
        newSpan.role = 'button';

        // Append it after the last text node
        lastTextNode.after(' ', newSpan);

        return tempDiv.innerHTML;
      }
    }

    return answer;
  };

  return (
    <Fragment>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div
        onClick={(e) => {
          // eslint-disable-next-line commandbar/no-event-target
          if (e.target instanceof HTMLElement && e.target.matches('.cb-copilot-source')) {
            if (!liveAnswer?.doc) return;
            e.preventDefault();

            if (liveAnswer.doc.command.training_only) {
              if (sourceUrl) window.open(sourceUrl, '_blank');
              return;
            }

            if ((e.metaKey || e.ctrlKey) && sourceUrl) {
              window.open(sourceUrl, '_blank');
            } else {
              setCurrentDoc(draftDocToFullDoc(liveAnswer.doc));
            }
          }
        }}
        style={{
          borderRadius: '0px 16px 16px 16px',
        }}
        css={css`
          & p {
            margin: 0 0 16px 0;
          }
          & p:last-child {
            margin: 0;
          }
          & pre {
            margin: 0;
          }
          & .codehilite {
            margin: 0 0 16px 0;
            overflow: auto;
            position: relative;
          }
          & ol {
            -12px;
          }
          & ul {
            -12px;
          }
          & .codehilite button {
            color: white;
            box-sizing: border-box;
            transition: ${theme.main.transitionTime} ease-out;
            cursor: pointer;
            user-select: none;
            background: rgba(255, 255, 255, 0.2);
            border: 1px solid rgba(0, 0, 0, 0);
            padding: 4px 8px;
            position: absolute;
            top: 3px;
            right: 3px;
            display: none;
            border-radius: 4px;
          }

          & .codehilite button {
            transition: all ${theme.main.transitionTime};
          }
          & .codehilite:hover button {
            display: block;
          }

          & .cb-copilot-source {
            width: 16px;
            height: 16px;
            background: #898989;
            color: white;
            border-radius: 8px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            vertical-align: middle;
            line-height: 24px;
            position: relative;
            bottom: 2px;
            user-select: none;
            cursor: pointer;
            transition: opacity 250ms ease-out;
            opacity: ${isLoading ? 0 : 1};
          }
        `}
        dangerouslySetInnerHTML={{ __html: appendSource(liveAnswer?.answer || '') }}
      ></div>
    </Fragment>
  );
};

export default LiveAnswer;
