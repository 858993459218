/** @jsx jsx  */
import { ChevronLeft } from '@commandbar/design-system/icons/react';
import { jsx } from '@emotion/core';
import React, { useEffect, useRef } from 'react';
import {
  ChatMessage as ChatMessageInterface,
  convertInternalChatHistoryToExternal,
  fetchAIChatAnswer,
  NO_ANSWER,
} from '../../client_api/search';
import * as Engine from '../../store/engine/actions';
import { useStore } from '../../hooks/useStore';
import { HelpHubDoc } from '../../store/engine/state';
import { useStyles } from '../helphub/useStyles';
import { INPUT_METHOD } from '../../hooks/useKeyboardNavigation';
import { isChatOnlyMode } from '../../store/engine/help-hub/selectors';
import { isHelpHubMarketingSite } from '@commandbar/internal/util/location';
import * as Reporting from '../../analytics/Reporting';
import { IContinuationType, IMessageType } from '@commandbar/internal/middleware/types';
import { Chat as ChatClient } from '@commandbar/internal/middleware/chat';

import PoweredBy from '../helphub/PoweredBy';
import { ResultCard } from '../helphub/SearchResults';
import { ChatMessage } from './ChatMessage';
import { MessageInput } from './MessageInput';
import { getSDK } from '@commandbar/internal/client/globals';
import { _user } from '@commandbar/internal/client/symbols';
import { useAction } from '../../hooks/useAction';
import { getSentry } from '@commandbar/internal/util/sentry';

const ANSWER_POLLING_INTERVAL_MS = 250;
const ANSWER_POLLING_REQUEST_TIMEOUT_MS = 25000;
const ANSWER_POLLING_TIMEOUT_MS = 60000;

function getChatMessageWithTimeout(
  chatId: string,
  messageId: string,
  timeout: number,
): Promise<IMessageType | 'timeout'> {
  const abort = new AbortController();

  return Promise.race([
    ChatClient.readMessage(chatId, messageId, abort.signal),
    new Promise<'timeout'>((resolve) =>
      setTimeout(() => {
        resolve('timeout');
        abort.abort();
      }, timeout),
    ),
  ]);
}

const Chat = ({
  chatID,
  chatHistory,
  setCurrentDoc,
  setChatHistory,
  setIsChatMode,
  setChatID,
}: {
  chatID: string | undefined;
  chatHistory: ChatMessageInterface[];
  setCurrentDoc: (doc: HelpHubDoc | null) => void;
  setIsChatMode: (isChatMode: boolean) => void;
  setChatHistory: React.Dispatch<React.SetStateAction<ChatMessageInterface[]>>;
  setChatID: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const { engine } = useStore();
  const styles = useStyles();

  const chatInput = useRef<HTMLTextAreaElement>(null);

  const lastUserMessageRef = React.useRef<HTMLDivElement>(null);
  const chatScrollContainerRef = React.useRef<HTMLDivElement>(null);

  const setCopilotSessionState = useAction(Engine.setCopilotSessionState);

  // when user posts a new message to chatHistory, scroll it into view
  useEffect(() => {
    setTimeout(() => {
      if (!lastUserMessageRef.current) return;
      if (!chatScrollContainerRef.current) return;

      chatScrollContainerRef.current.scrollTo({
        top:
          lastUserMessageRef.current.getBoundingClientRect().top -
          chatScrollContainerRef.current.getBoundingClientRect().top +
          chatScrollContainerRef.current.scrollTop -
          16,
        behavior: 'smooth',
      });
    }, 250);
  }, [chatHistory[chatHistory.length - 1]?.type === 'user']);

  const isLoading =
    chatHistory.length > 0 && ['user', 'bot-incomplete'].includes(chatHistory[chatHistory.length - 1].type);

  useEffect(() => {
    if (!isHelpHubMarketingSite) {
      chatInput.current?.focus();
    }
  }, [chatHistory]);

  const sendChat = (query: string) => {
    addMessage({ message: query, type: 'user' });

    if (chatInput.current) {
      chatInput.current.style.height = 'auto';
    }

    Reporting.helpHubChatMessage('user', query);
  };

  let lastUserMessageIdx: number | null = null;
  chatHistory.forEach((message, index) => {
    if (message.type === 'user') {
      lastUserMessageIdx = index;
    }
  });

  const addMessage = (message: ChatMessageInterface) => {
    if (unmounted.current) return;

    setChatHistory((chatHistory) => [...chatHistory, message]);
  };

  const updateMessage = (messageId: string, message: Partial<IMessageType>) => {
    if (unmounted.current) return;

    setChatHistory((chatHistory) => {
      if (
        !message.incomplete &&
        message.value?.copilot_argument_values_abort &&
        chatID &&
        engine.copilotSessionState[chatID]?.state === 'collecting_arguments'
      ) {
        // abort argument-collecting and remove incomplete "bot" message
        setCopilotSessionState(chatID, { state: 'initial' });

        return chatHistory.filter((h) => !('messageId' in h && h.messageId === messageId));
      }

      return chatHistory.map((h) => {
        if ('messageId' in h && h.messageId === messageId) {
          if (!message.incomplete)
            return {
              type: 'bot',
              message: {
                ...message.value,
                no_answer: message?.no_answer,
              },
            };
          else
            return {
              ...h,
              message: {
                ...message.value,
              },
            };
        }
        return h;
      });
    });
  };

  const resetChat = () => {
    setChatHistory([
      {
        message: {
          ...NO_ANSWER,
          answer: engine.organization?.helphub_chat_welcome_message || 'Hey there! What can we help with?',
        },
        type: 'bot',
      },
    ]);
  };

  const unmounted = React.useRef(false);

  React.useEffect(() => {
    const lastMessage = chatHistory[chatHistory.length - 1];

    if (chatHistory.length > 0 && lastMessage.type === 'user' && engine.organization) {
      const copilotSessionState = chatID !== undefined ? engine.copilotSessionState[chatID] : undefined;
      const answerPromise =
        copilotSessionState?.state === 'collecting_arguments'
          ? ChatClient.collectArgumentValues(
              engine.organization.id,
              chatID,
              copilotSessionState?.command.id,
              getSDK()[_user],
              lastMessage.message,
            )
          : fetchAIChatAnswer(
              engine.organization,
              lastMessage.message,
              chatID,
              chatHistory.slice(0, -1),
              engine.helpHub.hubDoc ? [engine.helpHub.hubDoc.command.id] : undefined,
            );

      answerPromise
        .then(async (response) => {
          if (unmounted.current) return;

          if ('error' in response) {
            addMessage({
              type: 'bot',
              message: {
                ...NO_ANSWER,
                answer: 'We are getting too many requests right now. Please try again in a moment.',
              },
            });

            return;
          }

          setChatID(response?.chat_id);
          addMessage({
            messageId: response.message_id,
            message: {
              answer: '',
              command_id: null,
              command_title: '',
              passage_id: null,
            },
            type: 'bot-incomplete',
          });

          const startTs = Date.now();
          try {
            while (true) {
              // timeout reached -- stop polling
              if (Date.now() - startTs > ANSWER_POLLING_TIMEOUT_MS) {
                throw new Error('Polling timeout reached');
              }

              const msg = await getChatMessageWithTimeout(
                response.chat_id,
                response.message_id,
                ANSWER_POLLING_REQUEST_TIMEOUT_MS,
              );

              if (msg === 'timeout') {
                // response took too long to return, will try again in ANSWER_POLLING_INTERVAL_MS
                continue;
              }

              if (unmounted.current) return;

              updateMessage(response.message_id, msg);

              // keep polling until message is marked completed by backend
              if (!msg.incomplete) {
                if (msg?.no_answer) {
                  Reporting.noChatResponse(convertInternalChatHistoryToExternal(chatHistory));
                }

                break;
              }

              await new Promise((resolve) => setTimeout(resolve, ANSWER_POLLING_INTERVAL_MS));
            }
          } catch (e) {
            // TODO: Log error to Sentry

            updateMessage(response.message_id, {
              value: {
                ...NO_ANSWER,
                answer: 'We are getting too many requests right now. Please try again in a moment.',
              },
            });
          }
        })
        .catch((e) => {
          if (unmounted.current) return;

          addMessage({
            type: 'bot',
            message: {
              ...NO_ANSWER,
              answer: 'We are getting too many requests right now. Please try again in a moment.',
            },
          });

          getSentry()?.captureException(e);
          return;
        });
    }
  }, [chatHistory]);

  React.useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '16px',
          color: '#51515C',
          alignItems: 'center',
        }}
      >
        <div style={{ flex: 1 }}>
          {!isChatOnlyMode(engine) && (
            <button
              css={{ ...styles.docHeaderButton }}
              onClick={() => {
                setIsChatMode(false);
                setCurrentDoc(null);
                setChatHistory([]);
                setChatID(undefined);
                Reporting.helpHubEngagement({ query: engine.helpHub.query }, 'chat_closed');
              }}
            >
              <ChevronLeft height={14} />
              <span>Back</span>
            </button>
          )}
        </div>
        <div css={{ fontSize: '14px', fontWeight: 500, color: '#000000' }}>{styles.strings.chatHeaderText}</div>
        <div style={{ flex: 1 }}></div>
      </div>

      {engine.helpHub.hubDoc && (
        <div css={styles.resultListContainer} style={{ padding: '0px 16px' }}>
          <ResultCard
            doc={engine.helpHub.hubDoc}
            selectionMethod={INPUT_METHOD.Mouse}
            active={false}
            onClick={() => setIsChatMode(false)}
          />
        </div>
      )}

      <div
        ref={chatScrollContainerRef}
        style={{
          overflowY: 'auto',
          overscrollBehaviorY: 'contain',
          flexGrow: '1',
        }}
      >
        <div>
          {chatHistory.map((message, index) => {
            return (
              <ChatMessage
                /* TODO: Include message ID for use as key */
                index={index}
                key={index}
                ref={index === lastUserMessageIdx ? lastUserMessageRef : undefined}
                setCurrentDoc={(doc: HelpHubDoc) => {
                  setCurrentDoc(doc);
                  setIsChatMode(false);
                }}
                isLoading={message.type === 'bot-incomplete'}
                chatId={chatID}
                history={chatHistory.slice(0, index + 1)}
                showContinuations={index === chatHistory.length - 1}
                addMessage={addMessage}
                setSelectedContinuation={(continuation: IContinuationType) => {
                  addMessage({
                    message: continuation,
                    type: 'user',
                  });
                  Reporting.helpHubContinuationClicked(
                    { query: engine.helpHub.query },
                    convertInternalChatHistoryToExternal(chatHistory),
                    continuation,
                  );
                }}
                message={message}
              />
            );
          })}
        </div>
      </div>

      <div style={{ width: '100%', flexShrink: '0' }}>
        <MessageInput isLoading={isLoading} onSubmit={sendChat} resetChat={resetChat} />
        <PoweredBy />
      </div>
    </div>
  );
};

export default Chat;
