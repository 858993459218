/** @jsx jsx  */
import React from 'react';
import { jsx } from '@emotion/core';
import ActionButton from './ActionButton';
import { IExperienceType, LabeledAction } from '@commandbar/internal/middleware/types';
import { getNudgeById } from '../../store/engine/nudges/selectors';
import { useStore } from '../../hooks/useStore';
import { getCommandById } from '../../store/engine';
import * as Engine from '../../store/engine/actions';

import { useAction } from '../../hooks/useAction';
import { ChatMessage } from 'commandbar/src/client_api/search';

export type ExperienceButtonButtonProps = {
  disabled?: boolean;
  experience: IExperienceType;
  addMessage: (message: ChatMessage) => void;
  chatID?: string;
} & React.HTMLAttributes<HTMLButtonElement>;

//trigger nudges and questlists
//http://localhost:3000/?cb-eid=n1

export const ExperienceButton: React.FC<ExperienceButtonButtonProps> = ({
  style,
  disabled,
  experience,
  addMessage,
  chatID,
  ...rest
}) => {
  const _ = useStore();

  const triggerShareLinkNudge = useAction(Engine.triggerSharedLinkNudge);
  const updateChecklists = useAction(Engine.triggerChecklists);
  const toggleHelpHubVisible = useAction(Engine.toggleHelpHubVisible);

  const executeAction = useAction(
    (
      _,
      action: LabeledAction['action'],
      toggleHelpHub: boolean,
      e?: React.MouseEvent<HTMLElement, MouseEvent>,
      args?: Record<string, any>,
    ) => {
      // might need to collect arguments
      if (action.type === 'execute_command' && args === undefined) {
        const command = getCommandById(_.engine, action.meta.command);
        if (command && Object.keys(command.arguments).length > 0) {
          if (!chatID) throw Error('Must have a Chat instance to execute an action');

          _.engine.copilotSessionState[chatID] = { state: 'collecting_arguments', command: command };

          addMessage({ message: command.text, type: 'user' });
          return;
        }
      }

      Engine.executeAction(_, action, e, args);
      if (toggleHelpHub) {
        toggleHelpHubVisible();
      }
    },
    [chatID],
  );

  if (experience.type === 'nudge') {
    const nudge = getNudgeById(_, experience.value);

    if (!nudge) return null;

    return (
      <ActionButton
        icon="NotificationBox"
        onClick={() => {
          if (_.engine.location.href.startsWith(nudge.share_page_url)) {
            window.history.replaceState(null, '', `?cb-eid=n${nudge.id}`);
            triggerShareLinkNudge();
          } else {
            window.open(`${nudge.share_page_url || _.engine.location.href}?cb-eid=n${nudge.id}`, '_blank');
          }
        }}
        {...rest}
      >
        {nudge.copilot_cta_label || nudge.steps[0].title}
      </ActionButton>
    );
  }

  if (experience.type === 'questlist') {
    const questlist = _.engine.checklists.find((questlist) => questlist.id === experience.value);

    if (!questlist) return null;

    return (
      <ActionButton
        icon="CheckSquareBroken"
        onClick={() => {
          if (_.engine.location.href.startsWith(questlist.share_page_url)) {
            window.history.replaceState(null, '', `?cb-eid=q${questlist.id}`);
            updateChecklists({ type: 'when_conditions_pass' });
          } else {
            window.open(`${questlist.share_page_url || _.engine.location.href}?cb-eid=n${questlist.id}`, '_blank');
          }
        }}
        {...rest}
      >
        {questlist.copilot_cta_label || questlist.title}
      </ActionButton>
    );
  }

  if (experience.type === 'command') {
    const command = getCommandById(_.engine, experience.value);

    if (!command) return null;

    return (
      <ActionButton
        icon="LinkExternal01"
        onClick={(e) => {
          executeAction({ type: 'execute_command', meta: { command: command.id.toString() } }, true, e);
        }}
        {...rest}
      >
        {command.copilot_cta_label || command.text}
      </ActionButton>
    );
  }

  return null;
};

export default ActionButton;
