/** @jsx jsx */
import React, { CSSProperties, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { jsx } from '@emotion/core';
import { useTheme } from 'emotion-theming';

import positionPin, { BEACON_INSET } from './helpers';
import { toPx } from '@commandbar/internal/client/theme';
import Z from '@commandbar/internal/client/Z';
import CloseButton from './CloseButton';
import { DraftFooter } from '../DraftFooter';
import type { ITheme } from '@commandbar/internal/client/theme';
import ContentContainer from './ContentContainer';
import { isMobile } from '@commandbar/internal/util/operatingSystem';
import type {
  INudgeStepContentButtonBlockType,
  INudgeStepType,
  INudgeType,
} from '@commandbar/internal/middleware/types';
import { isStandaloneEditor } from '@commandbar/internal/util/location';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { useAction } from '../../hooks/useAction';
import { RenderMode } from './RenderNudge';
import { getNudgeService, isNudgeDismissible } from '../../store/engine/nudges/selectors';
import { closeNudgeMock } from '../../store/engine';

type TPin = {
  nudge: INudgeType;
  step: INudgeStepType;
  targetEl?: Element;
  isOpenByDefault: boolean;
  offset: { x: string; y: string };
};

const BEACON_SIZE = 16;

const getStyles = (theme: ITheme, autoWidth: boolean): Record<string, CSSProperties> => ({
  pin: {
    position: 'absolute',
    display: 'flex',
    gap: '16px',
    pointerEvents: 'none',
    zIndex: Z.Z_COMMANDBAR - 3,
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: autoWidth ? 'auto' : theme.nudgePin.width,
    boxShadow: theme.nudgePin.boxShadow,
    borderRadius: theme.nudgePin.borderRadius,
    backgroundColor: theme.nudgePin.background,
    fontFamily: theme.nudgePin.fontFamily,
    color: theme.nudgePin.color,
    zIndex: Z.Z_COMMANDBAR - 4,
    pointerEvents: 'auto',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.nudgePin.padding,
    gap: theme.nudgePin.gap,
  },
  header: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px' },
  title: {
    fontFamily: theme.nudgePin.titleFontFamily,
    fontWeight: theme.nudgePin.titleFontWeight,
    fontSize: theme.nudgePin.titleFontSize,
    lineHeight: theme.nudgePin.titleLineHeight,
    color: theme.nudgePin.titleColor,
  },
  content: {
    fontFamily: theme.nudgePin.contentFontFamily,
    fontWeight: theme.nudgePin.contentFontWeight,
    fontSize: theme.nudgePin.contentFontSize,
    lineHeight: theme.nudgePin.contentLineHeight,
    color: theme.nudgePin.contentColor,
  },
  ctaButton: {
    padding: theme.nudgePin.ctaPadding,
    background: theme.nudgePin.ctaBackground,
    color: theme.nudgePin.ctaColor,
    border: theme.nudgePin.ctaBorder,
    boxShadow: theme.nudgePin.ctaBoxShadow,
    textShadow: theme.nudgePin.ctaTextShadow,
    borderRadius: theme.nudgePin.ctaBorderRadius,
    fontFamily: theme.nudgePin.ctaFontFamily,
    fontSize: theme.nudgePin.ctaFontSize,
    lineHeight: theme.nudgePin.ctaLineHeight,
    fontWeight: theme.nudgePin.ctaFontWeight,
  },
  ctaSecondaryButton: {
    fontFamily: theme.nudgePin.ctaSecondaryFontFamily,
    padding: theme.nudgePin.ctaSecondaryPadding,
    background: theme.nudgePin.ctaSecondaryBackground,
    color: theme.nudgePin.ctaSecondaryColor,
    border: theme.nudgePin.ctaSecondaryBorder,
    boxShadow: theme.nudgePin.ctaSecondaryBoxShadow,
    textShadow: theme.nudgePin.ctaSecondaryTextShadow,
    borderRadius: theme.nudgePin.ctaSecondaryBorderRadius,
    fontSize: theme.nudgePin.ctaSecondaryFontSize,
    lineHeight: theme.nudgePin.ctaSecondaryLineHeight,
    fontWeight: theme.nudgePin.ctaSecondaryFontWeight,
  },
  closeButtonOverrides: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  beaconOuter: {
    position: 'relative',
    borderRadius: '100%',
    width: toPx(BEACON_SIZE),
    height: toPx(BEACON_SIZE),
    backgroundColor: theme.nudgePin.beaconColor,
  },
  beaconInner: {
    borderRadius: '100%',
    width: toPx(BEACON_SIZE),
    height: toPx(BEACON_SIZE),
    backgroundColor: theme.nudgePin.beaconColor,
    position: 'absolute',
    animationDuration: '1.5s',
    animationTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
    animationDelay: '0s',
    animationIterationCount: 'infinite',
    animationDirection: 'normal',
    animationFillMode: 'none',
    animationPlayState: 'running',
  },
  stepCount: {
    color: theme.nudgePin.stepCountColor,
    fontFamily: theme.nudgePin.stepCountFontFamily,
    fontSize: theme.nudgePin.stepCountFontSize,
    fontWeight: theme.nudgePin.stepCountFontWeight,
  },
});

interface PinProps {
  pin: TPin;
  renderMode: RenderMode;
  stepCount?: string;
}

const Pin = ({ pin, stepCount, renderMode }: PinProps) => {
  const { theme }: { theme: ITheme } = useTheme();
  const getService = useAction(getNudgeService);
  const close = useAction(closeNudgeMock);
  const service = renderMode !== RenderMode.MOCK ? getService(pin.nudge.id) : undefined;

  const styles = getStyles(
    theme,
    !isMobile() && pin.step.content.some((block) => block?.type === 'survey_rating' && block.meta.options === 10),
  );
  const [isOpen, setIsOpen] = React.useState<boolean>(pin.isOpenByDefault);
  const beaconRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  const dismissNudge = React.useCallback(
    (e?: React.MouseEvent) => {
      e?.preventDefault();
      e?.stopPropagation();

      if (renderMode === RenderMode.MOCK) {
        close(pin.nudge, true);
      } else {
        service?.send('DISMISS');
      }
    },
    [service],
  );

  useEffect(() => {
    if (beaconRef.current && contentRef.current) {
      const cleanupMutationObserver = positionPin(pin, beaconRef.current, contentRef.current, isOpen, dismissNudge);

      return () => {
        if (cleanupMutationObserver) cleanupMutationObserver();
      };
    }
  }, [isOpen, pin]);

  const execNudgeAction = React.useCallback(() => {
    service?.send('ADVANCE');
  }, [service]);

  const handlePinTargetClick = React.useCallback(
    (event: MouseEvent) => {
      /* eslint-disable commandbar/no-event-target */
      if (event.target && pin.targetEl?.contains(event.target as Node)) {
        service?.send({
          type: 'ADVANCE',
          isPinClick: true,
        });
      }
    },
    [service],
  );

  const onNudgeClick = () => {
    const button = pin.step.content.find((block): block is INudgeStepContentButtonBlockType => block.type === 'button');
    if (!button?.meta?.label && window?.getSelection()?.type !== 'Range') {
      execNudgeAction();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handlePinTargetClick, true);
    return () => {
      document.removeEventListener('mousedown', handlePinTargetClick, true);
    };
  }, [handlePinTargetClick, pin.targetEl, renderMode]);

  if (renderMode === RenderMode.MOCK && (!pin.targetEl || isStandaloneEditor)) {
    const editorWidth = Number(LocalStorage.get('width', '590'));
    // We need to account for the width of the beacon when we center a pin with an open editor.
    // If we don't offset the pin by the beacon width, the nudge content will be off-center.
    const openEditorAdjustment = !isStandaloneEditor ? (editorWidth + (BEACON_SIZE * 2 + BEACON_INSET * 2)) / 2 : 0;

    return ReactDOM.createPortal(
      <div
        key={pin.step.title}
        className="commandbar-nudge-pin commandbar-unanchored-nudge-pin"
        style={{
          ...styles.pin,
          top: '50%',
          left: `calc(50% - ${openEditorAdjustment}px)`,
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div
          style={styles.beaconOuter}
          role="button"
          tabIndex={-1}
          onClick={() => {
            setIsOpen(true);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setIsOpen(true);
            }
          }}
          css={{
            cursor: isOpen ? 'auto' : 'pointer',
            pointerEvents: isOpen ? 'none' : 'all',
            visibility: 'visible',
          }}
        >
          <div
            style={styles.beaconInner}
            css={{
              animationName: 'ping',
              '@keyframes ping': {
                '75%, to': {
                  transform: `scale(${theme.nudgePin.beaconPulseScale})`,
                  opacity: 0,
                },
              },
            }}
          />
        </div>
        <div
          style={{
            ...styles.container,
            visibility: isOpen ? 'visible' : 'hidden',
            top: '8px',
          }}
          role="button"
          tabIndex={-1}
          onClick={(e) => {
            e.stopPropagation();
            onNudgeClick();
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onNudgeClick();
            }
          }}
        >
          <div style={styles.body}>
            <div style={styles.header}>
              <span style={styles.title}>{pin.step.title}</span>
              {isNudgeDismissible(pin.nudge) && <CloseButton onClick={dismissNudge} />}
            </div>

            <ContentContainer
              type="pin"
              step={pin.step}
              service={service}
              markdownStyles={styles.content}
              primaryButtonStyles={styles.ctaButton}
              secondaryButtonStyles={styles.ctaSecondaryButton}
              stepCountStyles={styles.stepCount}
              stepCount={stepCount}
              renderMode={renderMode}
            />
          </div>
          {!pin.step.is_live && renderMode === RenderMode.MOCK && (
            <DraftFooter details={{ type: 'nudge', nudgeId: pin.nudge.id, stepId: pin.step.id }} />
          )}
        </div>
      </div>,
      isStandaloneEditor
        ? (document.querySelector('main') as HTMLElement)
        : (document.getElementById('commandbar-wrapper') as HTMLDivElement),
    );
  }

  return ReactDOM.createPortal(
    <React.Fragment>
      <div
        ref={beaconRef}
        key={pin.step.title}
        className="commandbar-nudge-pin"
        style={{
          ...styles.pin,
          width: 'max-content',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <div
          style={styles.beaconOuter}
          role="button"
          tabIndex={-1}
          onClick={() => {
            setIsOpen(true);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setIsOpen(true);
            }
          }}
          css={{
            cursor: isOpen ? 'auto' : 'pointer',
            pointerEvents: isOpen ? 'none' : 'all',
          }}
        >
          <div
            style={styles.beaconInner}
            css={{
              animationName: 'ping',
              '@keyframes ping': {
                '75%, to': {
                  transform: `scale(${theme.nudgePin.beaconPulseScale})`,
                  opacity: 0,
                },
              },
            }}
          />
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      </div>
      <div
        ref={contentRef}
        style={{
          ...styles.container,
          visibility: isOpen ? 'visible' : 'hidden',
          position: 'absolute',
          top: !pin.targetEl ? '50%' : 0,
          left: !pin.targetEl ? '50%' : 0,
          transform: !pin.targetEl ? 'translate(-50%, -50%)' : undefined,
        }}
        role="button"
        tabIndex={-1}
        onClick={(e) => {
          e.stopPropagation();
          onNudgeClick();
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onNudgeClick();
          }
        }}
      >
        <div style={styles.body}>
          <div style={styles.header}>
            <span style={styles.title}>{pin.step.title}</span>
            {isNudgeDismissible(pin.nudge) && <CloseButton onClick={dismissNudge} />}
          </div>

          <ContentContainer
            type="pin"
            step={pin.step}
            service={service}
            markdownStyles={styles.content}
            primaryButtonStyles={styles.ctaButton}
            secondaryButtonStyles={styles.ctaSecondaryButton}
            stepCountStyles={styles.stepCount}
            stepCount={stepCount}
            renderMode={renderMode}
          />
        </div>
        {!pin.step.is_live && renderMode === RenderMode.MOCK && (
          <DraftFooter details={{ type: 'nudge', nudgeId: pin.nudge.id, stepId: pin.step.id }} />
        )}
      </div>
    </React.Fragment>,
    document.getElementById('commandbar-wrapper') as HTMLDivElement,
  );
};

export default Pin;
