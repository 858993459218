/** @jsx jsx  */
import React from 'react';
import { jsx } from '@emotion/core';

import { ActionButton } from './ActionButton';
import { IContinuationType } from '@commandbar/internal/middleware/types';
import {} from '../../store/engine';

type ContinuationProps = {
  isLoading: boolean;
  continuations: IContinuationType[];
  setSelectedContinuation: (continuation: IContinuationType) => void;
};

export const Continuations: React.FC<ContinuationProps> = (props) => {
  const { isLoading, continuations, setSelectedContinuation } = props;

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '0px',
        marginTop: '12px',
        alignItems: 'flex-start',
      }}
    >
      {continuations.slice(0, 2).map((continuation, index) => {
        if (!continuation) return null;

        return (
          <ActionButton
            variant="continuation"
            key={index}
            style={{ minWidth: 0, maxWidth: '100%' }}
            onClick={() => {
              if (isLoading) return;
              setSelectedContinuation(continuation);
            }}
          >
            {continuation}
          </ActionButton>
        );
      })}
    </div>
  );
};

export default Continuations;
