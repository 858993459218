import * as t from 'io-ts';

import { AxiosRequestConfig } from 'axios';
import * as axiosInstance from './network';
import { decodeToPromise } from './generics';
import {
  IAIAnswerPayloadType,
  IContinuationsPayloadType,
  ICreateAnswerFeedbackPayloadType,
  IOrganizationType,
  IQuestionSuggestionsPayloadType,
} from './types';
import { CommandV } from './command';

export const ContinuationV = t.string;

const sharedMessageFields = t.type({
  uuid: t.union([t.null, t.string]),
  error: t.string,
  incomplete: t.boolean,
  no_answer: t.boolean,
  used_fallback: t.boolean,
  feedback: t.union([t.null, t.type({ rating: t.union([t.literal(-1), t.literal(0), t.literal(1)]) })]),
  continuations: t.union([t.null, t.array(ContinuationV)]),
  fallback_ctas: t.union([t.null, t.array(t.string)]),
});

const CommandExperience = t.type({
  type: t.literal('command'),
  value: t.number, // command id
  description: t.string,
});
const NudgeExperience = t.type({
  type: t.literal('nudge'),
  value: t.number, // nudge id
  description: t.string,
});
const QuestlistExperience = t.type({
  type: t.literal('questlist'),
  value: t.number, // command id
  description: t.string,
});

export const ExperienceV = t.union([CommandExperience, NudgeExperience, QuestlistExperience]);

export const AIAnswerValue = t.intersection([
  t.type({
    answer: t.string,
    command_id: t.union([t.number, t.null]),
    command_title: t.string,
    passage_id: t.union([t.number, t.null]),
  }),
  t.partial({
    message_id: t.number,
    chat_id: t.string,
    doc: t.type({
      title: t.string,
      excerpt: t.string,
      content: t.string,
      command: CommandV,
    }),
    no_answer: t.boolean,
    copilot_argument_values: t.union([t.record(t.string, t.unknown), t.null]),
    copilot_argument_values_abort: t.boolean,
    experiences: t.union([t.null, t.array(ExperienceV)]),
  }),
]);

export const AIMessageV = t.type({
  message_type: t.literal('AI'),
  value: AIAnswerValue,
  ...sharedMessageFields.props,
});

export const UserMessageV = t.type({
  message_type: t.literal('USER'),
  value: t.type({
    question: t.string,
    command_ids: t.array(t.number),
  }),
  ...sharedMessageFields.props,
});

// deprecated message type
const ContinuationMessageV = t.type({
  message_type: t.literal('CONTINUATION'),
  value: t.any,
  ...sharedMessageFields.props,
});

// deprecated message type
const AnswerMessageV = t.type({
  message_type: t.literal('ANSWER'),
  value: t.any,
  ...sharedMessageFields.props,
});

export const MessageV = t.union([AIMessageV, UserMessageV, ContinuationMessageV, AnswerMessageV]);

export const QuestionSuggestionsV = t.type({
  suggestions: t.array(t.string),
});

export const QuestionSuggestionsPayloadV = t.partial({
  user: t.union([t.string, t.null, t.undefined]),
  page_context: t.type({
    title: t.string,
    description: t.union([t.string, t.null]),
    h1s: t.array(t.string),
    h2s: t.array(t.string),
    h3s: t.array(t.string),
  }),
});

export const ContinuationsPayloadV = t.type({
  command_ids: t.union([t.array(t.number), t.undefined]),
  passage_ids: t.union([t.array(t.number), t.undefined]),
  user: t.union([t.string, t.null, t.undefined]),
  chat_id: t.string,
});

export const AIAnswerPayloadV = t.type({
  command_ids: t.union([t.array(t.number), t.undefined]),
  query: t.string,
  user: t.union([t.string, t.null, t.undefined]),
  chat_id: t.union([t.string, t.undefined]),
});

export const AIAnswerV = t.type({
  chat_id: t.string,
  message_id: t.string,
});

export const CreateAnswerFeedbackPayloadV = t.type({
  message_id: t.number,
  rating: t.number,
});

export class Chat {
  public static listContinuations = async (orgUID: IOrganizationType['id'], payload: IContinuationsPayloadType) => {
    const result = await axiosInstance.post(`ml/answers/${orgUID}/continuations`, payload);
    return await decodeToPromise(t.array(ContinuationV), result.data);
  };

  public static createAnswerFeedback = async (
    orgUID: IOrganizationType['id'],
    payload: ICreateAnswerFeedbackPayloadType,
  ) => {
    const result = await axiosInstance.post(`ml/answers/${orgUID}/feedback`, payload);
    return await decodeToPromise(t.union([t.undefined, t.null, t.string]), result.data);
  };

  public static generateAIAnswer = async (
    orgUID: IOrganizationType['id'],
    payload: IAIAnswerPayloadType,
    config?: AxiosRequestConfig,
  ) => {
    const result = await axiosInstance.post(`ml/answers/${orgUID}/live`, payload, config);
    return await decodeToPromise(t.exact(AIAnswerV), result.data);
  };

  public static collectArgumentValues = async (
    orgUID: IOrganizationType['id'],
    chat_id: string | undefined,
    command_id: number,
    user: string | null | undefined,
    query: string,
  ) => {
    const result = await axiosInstance.post(`ml/answers/${orgUID}/arguments`, { command_id, chat_id, user, query });
    return await decodeToPromise(t.exact(AIAnswerV), result.data);
  };

  public static generateQuestionSuggestions = async (
    orgUID: IOrganizationType['id'],
    payload: IQuestionSuggestionsPayloadType,
  ) => {
    const result = await axiosInstance.post(`ml/question-suggestions/${orgUID}`, payload);
    return await decodeToPromise(t.exact(QuestionSuggestionsV), result.data);
  };

  public static readMessage = async (chatId: string, messageId: string, signal?: AbortSignal) => {
    const result = await axiosInstance.get(`ml/chats/${chatId}/${messageId}`, { signal: signal });

    return await decodeToPromise(MessageV, result.data);
  };
}
