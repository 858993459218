/** @jsx jsx */
import React, { CSSProperties } from 'react';
import { jsx } from '@emotion/core';
import { useTheme } from 'emotion-theming';

import { ITheme } from '@commandbar/internal/client/theme';
import Z from '@commandbar/internal/client/Z';
import CloseButton from './CloseButton';
import useDraggable from '../../hooks/useDraggable';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { isStandaloneEditor } from '@commandbar/internal/util/location';
import { DraftFooter } from '../DraftFooter';

import { INudgeStepContentButtonBlockType } from '@commandbar/internal/middleware/types';
import ContentContainer from './ContentContainer';
import { isMobile } from '@commandbar/internal/util/operatingSystem';
import { isEditorOpen } from '../../store/util/editorUtils';
import { RenderMode } from './RenderNudge';
import { getNudgeService, isNudgeDismissible } from '../../store/engine/nudges/selectors';
import { useAction } from '../../hooks/useAction';
import { closeNudgeMock } from '../../store/engine';

import type { INudgeType } from '@commandbar/internal/middleware/types';

const getStyles = (theme: ITheme, autoWidth: boolean): Record<string, CSSProperties> => ({
  container: {
    width: autoWidth ? 'auto' : theme.nudges.width,
    display: 'flex',
    flexDirection: 'column',
    background: theme.nudges.background,
    borderRadius: theme.nudges.borderRadius,
    color: theme.nudges.color,
    zIndex: Z.Z_COMMANDBAR - 2,
    boxShadow: theme.nudges.boxShadow,
    fontFamily: theme.nudges.fontFamily,
    transition: 'opacity 0.15s ease-in-out',
    pointerEvents: 'all',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    padding: `16px 16px ${theme.nudges.gap}`,
  },
  title: {
    fontFamily: theme.nudges.titleFontFamily,
    fontSize: theme.nudges.titleFontSize,
    fontWeight: theme.nudges.titleFontWeight,
    lineHeight: theme.nudges.titleLineHeight,
    color: theme.nudges.titleColor,
    zIndex: Z.Z_COMMANDBAR - 1,
    cursor: 'auto',
  },
  content: {
    fontFamily: theme.nudges.contentFontFamily,
    color: theme.nudges.contentColor,
    fontSize: theme.nudges.contentFontSize,
    fontWeight: theme.nudges.contentFontWeight,
    lineHeight: theme.nudges.contentLineHeight,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.nudges.padding,
    gap: theme.nudges.gap,
  },
  dragZone: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: parseInt(theme.nudges.titleFontSize, 10) + parseInt(theme.nudges.padding, 10) * 1.5,
    padding: theme.nudges.padding,
    borderTopLeftRadius: theme.nudges.borderRadius,
    borderTopRightRadius: theme.nudges.borderRadius,
    userSelect: 'none',
    cursor: 'move',
  },
  ctaButton: {
    fontFamily: theme.nudges.ctaFontFamily,
    padding: theme.nudges.ctaPadding,
    background: theme.nudges.ctaBackground,
    color: theme.nudges.ctaColor,
    border: theme.nudges.ctaBorder,
    boxShadow: theme.nudges.ctaBoxShadow,
    textShadow: theme.nudges.ctaTextShadow,
    borderRadius: theme.nudges.ctaBorderRadius,
    fontSize: theme.nudges.ctaFontSize,
    lineHeight: theme.nudges.ctaLineHeight,
    fontWeight: theme.nudges.ctaFontWeight,
  },
  ctaSecondaryButton: {
    fontFamily: theme.nudges.ctaSecondaryFontFamily,
    padding: theme.nudges.ctaSecondaryPadding,
    background: theme.nudges.ctaSecondaryBackground,
    color: theme.nudges.ctaSecondaryColor,
    border: theme.nudges.ctaSecondaryBorder,
    boxShadow: theme.nudges.ctaSecondaryBoxShadow,
    textShadow: theme.nudges.ctaSecondaryTextShadow,
    borderRadius: theme.nudges.ctaSecondaryBorderRadius,
    fontSize: theme.nudges.ctaSecondaryFontSize,
    lineHeight: theme.nudges.ctaSecondaryLineHeight,
    fontWeight: theme.nudges.ctaSecondaryFontWeight,
  },
  closeButtonOverrides: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  stepCount: {
    fontFamily: theme.nudges.stepCountFontFamily,
    color: theme.nudges.stepCountColor,
    fontSize: theme.nudges.stepCountFontSize,
    fontWeight: theme.nudges.stepCountFontWeight,
  },
});

export const Popover = ({
  renderMode,
  popoverRef,
  nudge,
  stepIndex,
  center,
  stepCount,
}: {
  popoverRef?: React.RefObject<HTMLDivElement>;
  center?: boolean;
} & DraggablePopoverProps) => {
  const { theme }: { theme: ITheme } = useTheme();
  const step = nudge.steps[stepIndex];
  const styles = getStyles(
    theme,
    !isMobile() && step.content.some((block) => block?.type === 'survey_rating' && block.meta.options === 10),
  );
  const getService = useAction(getNudgeService);
  const close = useAction(closeNudgeMock);
  const service = renderMode !== RenderMode.MOCK ? getService(nudge.id) : undefined;

  const dismissNudge = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (renderMode === RenderMode.MOCK) {
        close(nudge, true);
      } else {
        service?.send('DISMISS');
      }
    },
    [service],
  );

  const execNudgeAction = React.useCallback(() => {
    service?.send('ADVANCE');
  }, [service]);

  const editorWidth = Number(LocalStorage.get('width', '590'));
  const editorOpen = Number(LocalStorage.get('editor.open', false)) && isEditorOpen() && !isStandaloneEditor;

  if (step.form_factor.type === 'popover') {
    const className = (() => {
      const formFactor = step.form_factor;

      if (!isStandaloneEditor && formFactor.type === 'popover') {
        if (formFactor.position.includes('right')) {
          return 'commandbar-popover-nudge-right';
        } else if (formFactor.position === 'center') {
          return 'commandbar-popover-nudge-center';
        }
      }

      return '';
    })();

    const editorAdjustment: Record<'open' | 'closed', Record<string, React.CSSProperties>> = {
      open: {
        right: { right: `${editorWidth + 16}px` },
        center: {
          right: `${(editorWidth + 16) / 2}px`,
        },
      },
      closed: {
        right: { right: '0' },
        center: { right: undefined },
        standaloneEditor: {
          top: 'calc(50vh - 51px)',
          right: '50%',
          transform: 'translate(50%, -50%)',
          position: 'absolute',
        },
      },
    };

    const isRightPositioned = step.form_factor.position.includes('right');
    const isCenterPositioned = step.form_factor.position === 'center';

    const button = step.content.find((block): block is INudgeStepContentButtonBlockType => block.type === 'button');

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        key={step.id}
        ref={popoverRef}
        className={className}
        style={{
          position: 'relative',
          ...(center && {
            top: '50vh',
            transform: 'translate(0, -50%)',
          }),
          ...styles.container,
          ...editorAdjustment[editorOpen ? 'open' : 'closed'][
            isStandaloneEditor ? 'standaloneEditor' : isCenterPositioned ? 'center' : isRightPositioned ? 'right' : ''
          ],
        }}
      >
        <div style={styles.header}>
          <div data-draggable="drag-zone" style={styles.dragZone} />
          <span style={styles.title}>{step.title}</span>
          {isNudgeDismissible(nudge) && <CloseButton onClick={dismissNudge} />}
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          role={button?.meta?.action && !button.meta.label ? 'button' : undefined}
          style={{
            ...styles.body,
            padding: '0 16px 16px',
            cursor: button?.meta?.action && !button.meta.label ? 'pointer' : undefined,
          }}
          onClick={() => {
            if (button?.meta?.action && !button.meta.label && window.getSelection()?.type !== 'Range') {
              execNudgeAction();
            }
          }}
        >
          <ContentContainer
            type="popover"
            step={step}
            service={service}
            markdownStyles={styles.content}
            primaryButtonStyles={styles.ctaButton}
            secondaryButtonStyles={styles.ctaSecondaryButton}
            stepCountStyles={styles.stepCount}
            stepCount={stepCount}
            renderMode={renderMode}
          />
        </div>
        {!step.is_live && renderMode === RenderMode.MOCK && (
          <DraftFooter details={{ type: 'nudge', nudgeId: nudge.id, stepId: step.id }} />
        )}
      </div>
    );
  }

  return null;
};

interface DraggablePopoverProps {
  renderMode: RenderMode;
  nudge: INudgeType;
  stepIndex: number;
  center?: boolean;
  stepCount?: string;
}

export const DraggablePopover = (draggablePopoverProps: DraggablePopoverProps) => {
  const popoverRef = React.useRef<HTMLDivElement>(null);
  useDraggable(popoverRef.current);

  return <Popover popoverRef={popoverRef} {...draggablePopoverProps} />;
};
