import LocalStorage from '@commandbar/internal/util/LocalStorage';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import { getSDK, getProxySDK } from '@commandbar/internal/client/globals';
import {
  _search,
  _user,
  _configuration,
  _orgConfig,
  _userAttributes,
  _instanceAttributes,
  _eventSubscriptions,
  _metaAttributes,
  _fingerprint,
} from '@commandbar/internal/client/symbols';
import { EVENT_NAME } from '@commandbar/internal/client/AnalyticsEventTypes';
import { EVENT_TYPE, EVENT_CATEGORY, IEventPayload, stripEventAttributes, IEventAttributes } from './analytics-helpers';
import { EventType } from '@commandbar/internal/client/EventHandler';
import Logger from '@commandbar/internal/util/Logger';
import { getSentry } from '@commandbar/internal/util/sentry';

// Events to pass to client event handler
const CLIENT_HANDLER_EVENT_TYPES: {
  internal: EVENT_NAME;
  external: EventType;
}[] = [
  { internal: 'Abandoned search', external: 'abandoned_search' },
  { internal: 'Command suggestion', external: 'command_suggestion' },
  { internal: 'Command execution', external: 'command_execution' },
  { internal: 'New search', external: 'opened' },
  { internal: 'Exited', external: 'closed' },
  { internal: 'No results for query', external: 'no_results_for_query' },
  { internal: 'Client-Error', external: 'client_error' },
  { internal: 'User changed shortcut', external: 'shortcut_edited' },
  { internal: 'Preview shown', external: 'preview_shown' },
  { internal: 'Next step selected', external: 'next_step_selected' },
  { internal: 'Preview link opened', external: 'preview_link_opened' },
  { internal: 'Preview engagement', external: 'preview_engagement' },
  { internal: 'Nudge shown', external: 'nudge_shown' },
  { internal: 'Nudge clicked', external: 'nudge_clicked' },
  { internal: 'Nudge completed', external: 'nudge_completed' },
  { internal: 'Nudge dismissed', external: 'nudge_dismissed' },
  { internal: 'Questlist shown', external: 'questlist_shown' },
  { internal: 'Questlist engagement', external: 'questlist_engagement' },
  { internal: 'Questlist item engagement', external: 'questlist_item_engagement' },
  { internal: 'HelpHub opened', external: 'help_hub_opened' },
  { internal: 'HelpHub closed', external: 'help_hub_closed' },
  { internal: 'HelpHub engagement', external: 'help_hub_engagement' },
  { internal: 'HelpHub doc opened', external: 'help_hub_doc_opened' },
  { internal: 'HelpHub doc closed', external: 'help_hub_doc_closed' },
  { internal: 'HelpHub doc engagement', external: 'help_hub_doc_engagement' },
  { internal: 'Survey response', external: 'survey_response' },
  { internal: 'No chat response', external: 'no_chat_response' },
];

const getCurrentTimeStamp = (): string => {
  /** Spits out the same timestamp format is the backend event publisher
   *   self.created_timestamp = datetime.datetime.utcnow().strftime(
          "%Y-%m-%d %H:%M:%S.%f"
      )
   */
  const s = new Date().toISOString();
  return s.slice(0, 10) + ' ' + s.slice(11, 23);
};

class Analytics {
  private debugMode: boolean;
  private sendToServerInLocalHost: boolean;

  hasBooted = false;
  private isAdmin = false;

  private serverQueue: IEventPayload[] = [];
  private maxQueueSize = 5;

  constructor({ debugMode, sendToServerInLocalhost }: { debugMode: boolean; sendToServerInLocalhost: boolean }) {
    this.debugMode = debugMode;
    this.sendToServerInLocalHost = sendToServerInLocalhost;
  }

  private static isDevelopmentMode() {
    return process.env.NODE_ENV && (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test');
  }

  private userType() {
    if (this.isAdmin) return 'admin';
    if (!!LocalStorage.get('adm', '')) return 'likely-admin';
    return 'end_user';
  }

  /**
   * Types of events
   *
   * UserEvent: Triggered by user behavior (e.g, open, execution)
   * InternalEvent: Internal CommandBar tracking (e.g., availability of commands)
   **/
  private static isUserEvent(eventType: EVENT_TYPE) {
    return [EVENT_TYPE.Track, EVENT_TYPE.Identify, EVENT_TYPE.Log].includes(eventType);
  }

  private static isInternalEvent(eventType: EVENT_TYPE): boolean {
    return [EVENT_TYPE.Availability].includes(eventType);
  }

  private static getEventCategory(eventType: EVENT_TYPE): EVENT_CATEGORY {
    if (Analytics.isUserEvent(eventType)) return 'user';
    if (Analytics.isInternalEvent(eventType)) return 'internal';
    return 'unknown';
  }

  private shouldReport(eventType: EVENT_TYPE): boolean {
    const eventCategory = Analytics.getEventCategory(eventType);

    switch (eventCategory) {
      case 'user': {
        return this.hasBooted;
      }
      case 'internal': {
        return this.hasBooted;
      }
      case 'unknown':
      default: {
        return false;
      }
    }
  }

  /**
   * Event destination 1: CB Server
   *
   * Events reported to CB's server are put in a queue to reduce the number of network requests.
   * When the queue exceeds maxQueueSize, or an event has the forceFlush attribute, the queue is flushed.
   * Events are enriched with context information (url, device, userType, etc.) before they're sent
   **/
  private shouldReportToServer = (): boolean => {
    const notSilentMode = !this.isSilentMode();
    const canReportToServerInThisEnv =
      !Analytics.isDevelopmentMode() ||
      this.sendToServerInLocalHost ||
      !!LocalStorage.get('testAnalytics', false) ||
      this.debugMode;
    return notSilentMode && canReportToServerInThisEnv;
  };

  private enrichEvent = (type: EVENT_TYPE, name: EVENT_NAME, attrs: Partial<IEventAttributes>): IEventPayload => {
    const userType = this.userType();
    const userID = typeof getSDK()[_user] === 'string' ? getSDK()[_user] : null;
    const formFactor = getSDK()[_instanceAttributes]?.formFactor?.type;

    return {
      context: {
        page: {
          path: window.location?.pathname,
          title: document?.title,
          url: window.location?.href,
          search: window.location?.search,
        },
        userAgent: navigator?.userAgent,
        meta: getSDK()[_metaAttributes],
        groupId: getSDK()[_configuration].uuid,
        cbSource: getSDK()[_configuration],
      },
      clientEventTimestamp: getCurrentTimeStamp(),
      userType,
      type,
      attrs: { formFactor, ...attrs },
      name,
      id: userID,
      session: getSDK()[_configuration].session,
      search: getSDK()[_search],
      reportToSegment: this.shouldReportToEventHandler(name),
      ...(!userID && { fingerprint: getSDK()[_fingerprint] }),
    };
  };

  private addEventToServerQueue = (type: EVENT_TYPE, name: EVENT_NAME, attrs: Partial<IEventAttributes>) => {
    const eventAttributeBlockList = this.getEventAttributeBlockList();
    const enrichedEvent = this.enrichEvent(type, name, attrs);
    this.serverQueue.push(stripEventAttributes(enrichedEvent, eventAttributeBlockList));
  };

  flushServerQueue = () => {
    if (!this.shouldReportToServer()) return;
    const clientFlushedTimestamp = getCurrentTimeStamp();
    const body = JSON.stringify({
      events: this.serverQueue.map((e) => ({ ...e, clientFlushedTimestamp })),
      organization: getSDK()[_configuration].uuid,
      id: getSDK()[_user],
    });
    this.serverQueue = [];
    axiosInstance.post('/t/', body);
  };

  /**
   * Event Destination 2: Client event handler
   *
   * Client can add an event handler function using window.CommandBar.addEventHandler.
   * Once added, this sends a set of whitelisted events to the client provided function as they happen.
   **/

  private whitelistedEventHandlerEvents: EVENT_NAME[] = CLIENT_HANDLER_EVENT_TYPES.map((x) => x.internal);
  private shouldReportToEventHandler = (eventName: EVENT_NAME): boolean => {
    return this.whitelistedEventHandlerEvents.includes(eventName);
  };

  /**
   * Triage the event and send it to the respective destination(s)
   **/

  private onEvent(type: EVENT_TYPE, name: EVENT_NAME, attrs: Partial<IEventAttributes>, forceFlush?: boolean) {
    try {
      if (this.shouldReport(type)) {
        // Send to server
        if (this.debugMode) {
          console.log(
            '-- New Event:',
            name,
            type,
            '\nData',
            attrs,
            '\nFull payload',
            this.enrichEvent(type, name, attrs),
          );
        }

        const externalName = CLIENT_HANDLER_EVENT_TYPES.find((x) => x.internal === name)?.external;
        if (!!externalName) {
          attrs.type = externalName;
        }

        if (this.shouldReportToServer()) {
          this.addEventToServerQueue(type, name, attrs);
          if (this.serverQueue.length >= this.maxQueueSize || forceFlush) {
            this.flushServerQueue();
          }
        }

        // Send to eventHandler
        if (this.shouldReportToEventHandler(name)) {
          const userAttributes = getSDK()[_userAttributes] || {};
          if (!!externalName) {
            const eventHandler = getSDK().shareCallbacks()?.['commandbar-event-handler'];
            const context = { ...attrs, userAttributes };
            if (eventHandler) eventHandler(externalName, context);

            const eventSubscriptions = getSDK()[_eventSubscriptions];
            const iterator = eventSubscriptions instanceof Map ? eventSubscriptions.values() : undefined;
            if (iterator) {
              while (true) {
                const { value: subscriber, done } = iterator.next();
                if (done) break;
                subscriber(externalName as EventType, context as any);
              }
            }
          }
        }
      }
    } catch (error) {
      Logger.warn('Unexpected error logging event; ignoring', { type, name, error });

      getSentry()?.captureException(error);
    }
  }

  /**
   * API
   *
   * identify: [UserEvent] identify the user
   * log: [UserEvent] for remaining events
   * availability: [InternalEvent] reporting which commands are available
   *
   * identifyAsAdmin: tell Analytics that the user is an admin (for data enrichment)
   * userHasBooted: tell Analytics that the user has been booted, so start tracking events
   */
  log(eventName: EVENT_NAME, attrs: Partial<IEventAttributes>, forceFlush?: boolean) {
    getSentry()?.addBreadcrumb({
      category: 'log',
      message: eventName,
      data: attrs,
    });

    this.onEvent(EVENT_TYPE.Log, eventName, attrs, forceFlush);
  }

  identify(attrs: Partial<IEventAttributes>) {
    getSentry()?.addBreadcrumb({
      category: 'identify',
      message: 'Identify',
      data: attrs,
    });

    this.onEvent(EVENT_TYPE.Identify, 'Identify', attrs, true);
  }

  availability(commands: number[]) {
    const attrs = { commands };

    getSentry()?.addBreadcrumb({
      category: 'availability',
      message: 'Calculate availability',
      data: attrs,
    });

    this.onEvent(EVENT_TYPE.Availability, 'Internal-Event', attrs);
  }

  identifyAsAdmin() {
    this.isAdmin = true;
    LocalStorage.set('adm', true);
    this.identify({ isAdmin: true });
  }

  setBootStatus(hasBooted: boolean) {
    this.hasBooted = hasBooted;
  }

  isSilentMode() {
    const proxy = getProxySDK();
    const silent = proxy[_orgConfig]?.silent;

    if (silent === undefined) {
      getSentry()?.addBreadcrumb({
        message: 'Silent mode configuration is undefined',
        data: {
          configuration: proxy[_configuration],
          org: proxy[_configuration]?.uuid,
        },
      });
    }

    return silent !== false;
  }

  getEventAttributeBlockList(): string[] {
    const proxy = getProxySDK();
    const eventAttributeBlockList = proxy[_orgConfig]?.eventAttributeBlockList;

    if (!eventAttributeBlockList) {
      getSentry()?.addBreadcrumb({
        message: 'Event Attribute Block List is undefined',
        data: {
          configuration: proxy[_configuration],
          org: proxy[_configuration]?.uuid,
        },
      });
    }

    return eventAttributeBlockList || [];
  }
}

const analytics = new Analytics({
  /* Use commandbar.debugAnalytics to turn on debug mode */
  debugMode: !!LocalStorage.get('debugAnalytics', false),
  sendToServerInLocalhost: false,
});

export default analytics;
