import { State } from '..';
import {
  getCommands,
  isCommandOption,
  isUnfurledCommandOption,
  chooseOption,
  getContextSettings,
  isActiveRecord,
  EngineState,
  triggerSingleNudge,
} from '../engine';
import { getNudgeById, getAllNudgeServices, getAllNudgeDataFromUserStore } from '../engine/nudges/selectors';
import ExecutionPath from '../../engine/ExecutionPath';
import * as App from '../app/actions';
import * as Engine from '../engine/actions';
import { selectSlashFilter, selectSlashFilterGroups } from './selectors';
import { sub } from '../util/sub';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { logChanges } from '../util/logChanges';
import { getSDK, SDK_INTERNAL_PREFIX } from '@commandbar/internal/client/globals';
import { _reloadCommands } from '@commandbar/internal/client/symbols';
import { StepType } from '../../engine/step/Step';
import {
  initOptionGroupFromCommandCategory,
  initOptionGroupFromRecordCategory,
  initOptionGroupFromTab,
  initRecentsGroup,
  initRecommendedGroup,
  OptionGroup,
} from '../../engine/OptionGroup';

import slugify from '@commandbar/internal/util/slugify';
import { createSearchFilter } from '../../components/select/SearchTabs';
import { findOp, getPrevValue, Ops } from '../util/hasOp';
import isEqual from 'lodash/isEqual';
import {
  setupAlgoliaIntegration,
  setupHeapIntegration,
  tearDownAlgoliaIntegration,
} from '../../util/clientSideIntegrations';
import debounce from 'lodash/debounce';

import type { INudgeType } from '@commandbar/internal/middleware/types';

const MIN_LOADING_TIMEOUT = 1000;

const loadingIndicator = (_: State) => {
  const anyKeyIsLoading = Object.keys(_.loadingByKey).some((key) => !!_.loadingByKey[key]);
  _.showLoadingIndicator = anyKeyIsLoading || _.inMinLoadingWindow;
};

const autoChoose = (_: State) => {
  const updateSteps = Engine.updateSteps.bind(null, _);
  const { currentStep } = ExecutionPath.currentStepAndIndex(_.engine);

  if (
    currentStep &&
    currentStep.type === StepType.Select &&
    !currentStep.argument.allow_create &&
    currentStep.argument.auto_choose &&
    _.engine.initialOptions.length === 1
  ) {
    chooseOption(_, _.engine.initialOptions[0], updateSteps);

    return;
  }

  const activeResource = currentStep?.type === StepType.Base && currentStep.resource;
  if (!activeResource) return;
  const singleCommand = _.engine.initialOptions.length === 1 && _.engine.initialOptions[0];
  if (singleCommand) {
    chooseOption(_, singleCommand, updateSteps);
    return;
  }
  return;
};

const minLoadingTimer = (_: State) => {
  if (Object.keys(_.loadingByKey).length > 0) return;

  _.inMinLoadingWindow = true;

  if (_.minLoadingTimeout) clearTimeout(_.minLoadingTimeout);

  _.minLoadingTimeout = window.setTimeout(() => {
    _.inMinLoadingWindow = false;
  }, MIN_LOADING_TIMEOUT);
};

const commandLoader = (_: State) => {
  App.setLoading(_, 'internal-commandsLoaded', !_.engine.commandsLoaded);
};

const resetOnCloseDashboard = (_: State) => {
  if (_.dashboard === undefined && _.engine.inputText !== '' && getCommands(_.engine).length > 0) {
    Engine.setInputText(_, '');
    Engine.reset(_);
  }
};

const persistTestMode = (_: State) => {
  if (!_.engine.isAdmin) return;
  if (_.engine.testMode) {
    LocalStorage.set('testMode', true);
  } else {
    LocalStorage.remove('testMode');
  }
};

const viewLatestViaEnvOverrideOnEditorOpen = (_: State) => {
  if (_.isEditorVisible) {
    App.setEnvOverride(_, { env: 'latest' });
  }
};

const reloadCommandsOnEnvChange = (_: State) => {
  if (_.active) {
    getSDK()[_reloadCommands]();
  }
};

const refreshCurrentGroups = (_: State) => {
  const availableCommandCategories = new Set();
  const usedRecordKeys = new Set();

  _.engine.initialOptions.forEach((option) => {
    if (isCommandOption(option) || isUnfurledCommandOption(option)) {
      const categoryID = option.command?.category;

      if (!!categoryID) {
        availableCommandCategories.add(categoryID);
      }

      Object.keys(option.command.arguments).forEach((k) => {
        const value = option.command.arguments[k].value;
        if (typeof value === 'string') {
          usedRecordKeys.add(value);
        }
      });
    }
  });

  _.engine.categories
    .filter((c) => !!c.contains_hotloaded_commands)
    .forEach((c) => availableCommandCategories.add(c.id));

  const isValidRecord = (recordKey: string): boolean => {
    return usedRecordKeys.has(recordKey) && isActiveRecord(_.engine, recordKey);
  };

  const recordGroups = Object.entries(getContextSettings(_.engine))
    .filter(([k]) => isValidRecord(k))
    .map(([k, v]) => {
      const group = initOptionGroupFromRecordCategory(k, _.engine, v);
      return group;
    });

  const commandGroups: OptionGroup[] = _.engine.categories
    .filter((g) => availableCommandCategories.has(g.id))
    .map((c) => {
      const group = initOptionGroupFromCommandCategory(c, _.engine);
      return group;
    });

  const syntheticGroups: OptionGroup[] = [];

  if (_.engine.organization?.end_user_recents_enabled) {
    syntheticGroups.push({
      ...initRecentsGroup(_.engine),
      slash_filter_enabled: true,
      searchTabEnabled: _.engine.organization?.recents_tab_enabled || false,
    });
  }

  if (_.engine.organization?.recommendations_type !== 'None') {
    syntheticGroups.push({
      ...initRecommendedGroup(_.engine),
      slash_filter_enabled: true,
      searchTabEnabled: _.engine.organization?.recommended_tab_enabled || false,
    });
  }

  const tabs = _.engine.tabs.map((t) => initOptionGroupFromTab(t, _.engine));

  _.engine.currentGroups = [...commandGroups, ...recordGroups, ...syntheticGroups, ...tabs].map((group) => {
    if (!!!group.slash_filter_keyword) {
      group.slash_filter_keyword = slugify(group.name);
    }
    return group;
  });
};

// FIXME [SLASHFILTERS,OPTIONGROUPS]: can move this to `handleInputChange()` if _.searchFilter` is moved to `engine`
const applyFilterIfMatchesSlashFilter = (_: State) => {
  if (!_.engine.organization?.slash_filters_enabled) {
    return;
  }

  const { inputText, slashFilter } = selectSlashFilter(_);

  // if length is 1 then there is only the slash
  if (slashFilter.length <= 1) {
    return;
  }

  const slashFilterKeyword = slashFilter.substring(1);

  const labelAllTab = !!_.engine.theme ? slugify(_.engine.theme.searchTab.labelAllTab) : 'all';

  if (slashFilterKeyword.toLowerCase() === labelAllTab.toLowerCase()) {
    _.searchFilter = undefined;
    _.engine.rawInput = inputText;
    return;
  }

  const slashFilterGroups = selectSlashFilterGroups(_);

  const slashFilterGroup = slashFilterGroups.find((o: OptionGroup) => {
    if (o.slash_filter_keyword === slashFilterKeyword.toLowerCase()) {
      return true;
    }
    return false;
  });
  if (!!slashFilterGroup) {
    const filter = createSearchFilter(slashFilterGroup);

    App.setSearchFilter(_, filter);

    _.engine.rawInput = inputText;
    return;
  }
};

const categories = (_: State) => {
  _.engine.categories = [..._.localCategories, ..._.serverCategories].map((category) => ({
    ...category,
    ..._.categoryConfig[category.id],
  }));
};

const processClientSideIntegrations = (_: State, ops: Ops) => {
  const replacedOrg = getPrevValue<State['engine']['organization']>(findOp('set', ['engine', 'organization'], ops));

  if (isEqual(replacedOrg?.integrations, _.engine.organization?.integrations)) {
    return;
  } else {
    if (replacedOrg?.integrations) {
      tearDownAlgoliaIntegration(replacedOrg.integrations);
    }
  }

  if (!_.engine.organization?.integrations) return;

  setupHeapIntegration(_.engine.organization.integrations);

  return setupAlgoliaIntegration(_.engine.organization.integrations);
};

const completedChecklistRecords = (_: State) => {
  if (!_.active || _.engine.organization?.bar_hide_completed_nudges_questlists) return;

  const sdk = getSDK();

  // NOTE: prepending SDK_INTERNAL_PREFIX prevents these from being shown in the Editor UI
  const recordKey = SDK_INTERNAL_PREFIX + 'completed_checklist';
  const callbackKey = SDK_INTERNAL_PREFIX + 'show-checklist';
  const commandName = SDK_INTERNAL_PREFIX + 'show-checklist command';

  sdk.addCallback(callbackKey, (item) => {
    const checklistId = (item as any)?.record?.checklistId;
    if (checklistId == null) return;

    const checklist = _.engine.checklists.find((c) => c.id === checklistId);
    if (!checklist) return;

    _.engine.activeChecklist = checklist;
  });

  sdk.addRecords(
    recordKey,
    () => {
      // sorted by most recently dismissed first
      const dismissedChecklists = [
        ...Object.entries(_.engine.endUserStore.data.checklist_interactions.checklists || {})
          .filter(([, c]) => c.isSkipped || c.isCompleted)
          .map(([_id, checklistInteraction]) => {
            // NOTE: object keys returned from Object.entries are always strings so we need to convert back to number here
            const id = parseInt(_id, 10);

            const completedTimestamps = [
              ...Object.values(checklistInteraction.items || {}).map((x) => x.completedTS),
            ].sort();
            const lastCompletedTimestamp = completedTimestamps[completedTimestamps.length - 1];

            const checklist = _.engine.checklists.find((c) => c.id === id);

            return { checklist, lastCompletedTimestamp };
          }),
      ]
        .sort((a, b) => b.lastCompletedTimestamp?.localeCompare(a.lastCompletedTimestamp || '') || 0)
        .map((x) => x.checklist)
        .filter((x) => x?.is_live);

      const result = [];

      for (const checklist of dismissedChecklists) {
        if (!checklist) continue;
        if (checklist.title === '') continue;

        result.push({
          label: checklist.title,
          icon: _.engine.organization?.resource_options[recordKey]?.icon || 'checksquare',
          checklistId: checklist.id,
          description: checklist.description,
        });
      }

      return result;
    },
    {
      recordOptions: {
        categoryName: 'Completed Questlists',
        showInDefaultEmptyState: true,
        categorySortKey: Infinity,
        maxOptionsCount: 5,
      },
      descriptionKey: 'description',
    },
  );
  sdk.addRecordAction(recordKey, {
    name: commandName,
    text: 'Re-show questlist',
    template: { type: 'callback', value: callbackKey },
  });

  return () => {
    sdk.removeContext(recordKey);
    sdk.removeCallback(callbackKey);
    sdk.removeCommand(commandName);
  };
};

export const seenNudges = (_: EngineState) => {
  const nudgeServices = getAllNudgeServices(_);
  const allNudgeInteractions = getAllNudgeDataFromUserStore(_);

  if (!nudgeServices || !allNudgeInteractions) return;

  return Object.entries(allNudgeInteractions)
    .reduce<Array<INudgeType>>((acc, [id, context]) => {
      if (nudgeServices.has(id) && context.nudgeSeen) {
        const nudgeContext = nudgeServices.get(id)?.getSnapshot()?.context;

        if (nudgeContext && nudgeContext.nudge.is_live) {
          acc.push(nudgeContext.nudge);
        }
      }

      return acc;
    }, [])
    .sort((a, b) => {
      const getLastSeenTime = (id: INudgeType['id']) => {
        const nudgeInteractions = allNudgeInteractions[Number(id)];
        return nudgeInteractions.seenTs?.[nudgeInteractions.seenTs.length - 1];
      };

      const lastSeenA = getLastSeenTime(a.id);
      const lastSeenB = getLastSeenTime(b.id);

      return lastSeenA && lastSeenB ? lastSeenB - lastSeenA : Infinity;
    });
};

const seenNudgesRecords = (_: State) => {
  if (!_.active || _.engine.organization?.bar_hide_completed_nudges_questlists) return;

  const sdk = getSDK();

  const recordKey = SDK_INTERNAL_PREFIX + 'seen_nudges';
  const callbackKey = SDK_INTERNAL_PREFIX + 'show-nudge';
  const commandName = SDK_INTERNAL_PREFIX + 'show-nudge command';

  sdk.addCallback(callbackKey, (item) => {
    const nudgeId = (item as any)?.record?.nudgeId;
    const nudge = getNudgeById(_, nudgeId);
    if (!nudge) return;

    // We don't want to prevent a nudge from showing if certain conditions aren't want when triggering from the bar.
    triggerSingleNudge(_, nudge, {
      // You should be able to trigger a nudge manually from the bar even when logged into the editor
      admin: true,
      // The nudge should show regardless of what page you're on
      page: true,
      // The nudge should show regardless of what audience it's targeted to. If you've seen the nudge before, you should be able to see it again.
      audience: true,
      // Don't limit how many times the nudge can be seen if manually triggered.
      frequency: true,
      // Ignore the global limit if manually triggered.
      globalLimit: true,
    });
  });
  sdk.addRecords(
    recordKey,
    () =>
      seenNudges(_)?.map((nudge) => ({
        label: nudge?.steps[0].title || nudge?.slug,
        icon: _.engine.organization?.resource_options[recordKey]?.icon || 'notification',
        nudgeId: nudge?.id,
        description:
          !!nudge && nudge.steps.length > 1
            ? nudge?.steps.map((step, index) => `Step ${index + 1}: ${step.title}`).join(' - ')
            : undefined,
      })) ?? [],
    {
      recordOptions: {
        categoryName: 'Nudges you saw',
        showInDefaultEmptyState: true,
        categorySortKey: Infinity,
        maxOptionsCount: 5,
      },
      descriptionKey: 'description',
    },
  );
  sdk.addRecordAction(recordKey, {
    name: commandName,
    text: 'Re-show nudge',
    template: { type: 'callback', value: callbackKey },
  });

  return () => {
    sdk.removeContext(recordKey);
    sdk.removeCallback(callbackKey);
    sdk.removeCommand(commandName);
  };
};

export const initAppSubs = (_: State) => [
  sub(_, LocalStorage.get('logChanges', false) ? (_, ops) => logChanges(ops) : () => null, '*'),
  sub(_, loadingIndicator, [['loadingByKey'], ['inMinLoadingWindow']]),
  sub(_, autoChoose, [['engine', 'initialOptions']]),
  sub(_, applyFilterIfMatchesSlashFilter, [['engine', 'rawInput']]),
  sub(_, refreshCurrentGroups, [
    ['engine', 'categories'],
    ['engine', 'initialOptions'],
    ['engine', 'localContextSettings'],
    ['engine', 'serverContextSettings'],
    ['engine', 'theme', 'categoryHeader'],
  ]),
  sub(_, minLoadingTimer, [['loadingByKey']]),
  sub(_, commandLoader, [['engine', 'commandsLoaded']]),
  sub(_, resetOnCloseDashboard, [['dashboard']]),
  sub(_, persistTestMode, [['engine', 'testMode']]),
  sub(_, viewLatestViaEnvOverrideOnEditorOpen, [['isEditorVisible']]),
  sub(_, debounce(reloadCommandsOnEnvChange, 25), [['env'], ['envOverride']]),
  sub(_, categories, [['serverCategories'], ['localCategories'], ['categoryConfig']]),
  sub(_, processClientSideIntegrations, [['engine', 'organization', 'integrations']]),
  sub(_, completedChecklistRecords, [['active']]),
  sub(_, seenNudgesRecords, [['active']]),
];
