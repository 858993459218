/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Star01 } from '@commandbar/design-system/icons/react';
import { useState } from 'react';
import { SurveyResponseEvent } from '@commandbar/internal/client/EventHandler';
import type { INudgeContentSurveyRatingBlockType } from '@commandbar/internal/middleware/types';
import { isMobile } from '@commandbar/internal/util/operatingSystem';
import { useTheme } from 'emotion-theming';
import { ITheme } from '@commandbar/internal/client/theme';

const StarRatingInputBlock = ({
  setSurveyResponse,
  ratingBlock,
}: {
  setSurveyResponse: (response: SurveyResponseEvent['response'] | undefined) => void;
  ratingBlock: INudgeContentSurveyRatingBlockType['meta'];
}) => {
  const { theme }: { theme: ITheme } = useTheme();

  const [currentHovered, setcurrentHovered] = useState<number>(-1);
  const [currentRating, setCurrentRating] = useState<number>(-1);

  const inputAddition = (i: number, hoverMarked: boolean, selectedMarked: boolean) => {
    switch (ratingBlock.type) {
      case 'stars':
        return (
          <Star01
            width={24}
            height={24}
            color={hoverMarked || selectedMarked ? '#DD9E23' : undefined}
            css={{
              opacity: hoverMarked || selectedMarked ? undefined : 0.25,
            }}
          />
        );
      case 'emojis':
        const zeroBasedIndex = i - 1;
        return (
          <div
            style={{
              lineHeight: '26px',
              fontSize: hoverMarked || selectedMarked ? '26px' : '24px',
              textShadow: hoverMarked || selectedMarked ? '0px 4px 7px rgba(0, 0, 0, 0.35)' : undefined,
              transition: `all ${theme.main.transitionTime} ease-in-out`,
              width: '26px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {ratingBlock.emojis.length > zeroBasedIndex ? ratingBlock.emojis[zeroBasedIndex] : ' '}
          </div>
        );
      case 'numbers':
        return (
          <span
            style={{
              color: hoverMarked || selectedMarked ? '#000000' : theme.surveyRatingBox.color,
              fontSize: '14px',
              fontWeight: '600px',
            }}
          >
            {i}
          </span>
        );
    }
  };

  const setRating = (rating: number) => {
    if (rating === currentRating) {
      setCurrentRating(-1);
      setSurveyResponse(undefined);
    } else {
      setCurrentRating(rating);
      setSurveyResponse({
        type: 'number',
        value: rating,
        max: ratingBlock.options,
        emoji:
          ratingBlock.type === 'emojis'
            ? ratingBlock.emojis[rating - 1]
            : ratingBlock.type === 'stars'
            ? '⭐'
            : undefined,
      });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '8px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-start',
          padding: '0px',
          gap: '8px',
          flex: 'none',
          order: 0,
          flexGrow: 1,
          alignSelf: 'stretch',
          flexWrap: isMobile() ? 'wrap' : 'nowrap',
        }}
      >
        {[...Array(ratingBlock.type === 'numbers' ? ratingBlock.options + 1 : ratingBlock.options)].map((_, i) => {
          /* INFO: For numbers, we want to start at 0, but for stars and emojis, we want to start at 1.
           * This is to make sure that if you choose a number of options in the editor, then you will see the correct
           * number depending on the type.
           * For example: 5 number options should show 6 options (0 - 5)
           * 5 number or emoji options should show 5 options */
          const index = ratingBlock.type === 'numbers' ? i : i + 1;

          const hoverMarked = (ratingBlock.type === 'stars' && index <= currentHovered) || index === currentHovered;
          const selectedMarked = (ratingBlock.type === 'stars' && index <= currentRating) || index === currentRating;

          return (
            <div
              onMouseEnter={() => setcurrentHovered(index)}
              onMouseLeave={() => setcurrentHovered(-1)}
              onClick={() => setRating(index)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setRating(index);
                }
              }}
              tabIndex={0}
              key={index}
              role="button"
              style={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '8px',
                gap: '6px',

                height: '40px',
                width: ratingBlock.type !== 'emojis' ? '40px' : 'auto',

                background:
                  hoverMarked || selectedMarked
                    ? theme.surveyRatingBox.selectionBackGround
                    : theme.surveyRatingBox.background,
                border:
                  hoverMarked || selectedMarked ? theme.surveyRatingBox.selectionBorder : theme.surveyRatingBox.border,
                boxShadow:
                  hoverMarked && !selectedMarked
                    ? theme.surveyRatingBox.boxShadow
                    : selectedMarked
                    ? theme.surveyRatingBox.selectionBoxShadow
                    : theme.surveyRatingBox.boxShadow,
                borderRadius: theme.surveyRatingBox.borderRadius,
                flex: 'none',
                order: 0,
                flexGrow: 1,
              }}
            >
              {inputAddition(index, hoverMarked, selectedMarked)}
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: ratingBlock.lower_label || ratingBlock.upper_label ? 'flex' : 'none',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          padding: '0px',
          order: 1,
          flexGrow: 0,
          width: '100%',
        }}
      >
        <span
          style={{
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '15px',
            color: ' #A2A2A9',
          }}
        >
          {ratingBlock.lower_label}
        </span>
        <span
          style={{
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '15px',
            color: ' #A2A2A9',
          }}
        >
          {ratingBlock.upper_label}
        </span>
      </div>
    </div>
  );
};

export default StarRatingInputBlock;
