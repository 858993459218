/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { useStore } from '../../hooks/useStore';
import { ITheme } from '@commandbar/internal/client/theme';
import getPlayer from '../video/players';
import { BsArrowLeft } from 'react-icons/bs';
import ExecutionPath, { isDashboardStep } from '../../engine/ExecutionPath';
import * as Reporting from '../../analytics/Reporting';
import { useAction } from '../../hooks/useAction';
import * as Engine from '../../store/engine/actions';
import { StepType } from '../../engine/step/Step';
import VideoDashboard from '../video/VideoDashboard';
import React, { Fragment } from 'react';
import HtmlDashboard from '../HtmlDashboard';
import NextStepCommandsMenu from '../NextStepCommandsMenu';
import { selectIsFinalStep } from '../../store/app';
import StatusBar from '../select/input/StatusBar';
import * as Command from '@commandbar/internal/middleware/command';
import { interpolate } from '../../engine/Interpolate';
import Footer from '../select/footer/Footer';
import { submitDashboardStep } from '../../store/engine/actions';

export interface DashboardStepDisplayProps {
  source: string;
  goBack: () => void;
  goForward: () => void;
  title: string;
  description?: string;
  url?: string;
}

const DashboardStepDisplay = ({ source, title, description, url }: DashboardStepDisplayProps) => {
  const _ = useStore();
  const engine = _.engine;
  const { currentStep } = ExecutionPath.currentStepAndIndex(engine);
  const triggerNudges = useAction(Engine.sendTriggerToAllNudges);
  const updateChecklists = useAction(Engine.triggerChecklists);

  const goBack = useAction(Engine.rollback);

  const goForward = useAction(submitDashboardStep);

  const { theme }: { theme: ITheme } = useTheme();
  const isLastStep = selectIsFinalStep(useStore());
  const activeCommand = ExecutionPath.activeCommand(_.engine);

  React.useEffect(() => {
    // If component is shown in the last step, then report execution
    if (isLastStep) {
      // Report execution since for Dashboard commands, this will be the last step
      const commandToReport = activeCommand || (isDashboardStep(currentStep) && currentStep.command);
      if (commandToReport) {
        Reporting.execution(commandToReport, engine.inputText || engine.previousInputText, {
          categoryText: engine.categories.find((c) => c.id === commandToReport.category)?.name,
        });
      }

      if (activeCommand) {
        triggerNudges({
          type: 'on_command_execution',
          meta: { command: Command.commandUID(activeCommand) },
        });
        updateChecklists({
          type: 'on_command_execution',
          meta: { command: Command.commandUID(activeCommand) },
        });
      }
    }
  }, [activeCommand]);

  const handleEscape = useAction((_, e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      goBack();
    }
  });
  const handleEnter = useAction((_, e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      goForward();
    }
  });

  React.useEffect(() => {
    document.addEventListener('keyup', handleEscape, true);
    return () => {
      document.removeEventListener('keyup', handleEscape, true);
    };
  }, [handleEscape]);

  React.useEffect(() => {
    document.addEventListener('keydown', handleEnter, true);
    return () => {
      document.removeEventListener('keydown', handleEnter, true);
    };
  }, [handleEnter]);

  if (!currentStep || !engine.visible) {
    return null;
  }

  return (
    <div
      css={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        fontSize: '14px',
        background: theme.input.background,
        borderRadius: theme.input.borderRadius,
      }}
    >
      {isLastStep && (
        <div
          css={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            background: 'rgba(255, 255, 255, 0.35)',
            boxShadow: '0 4px 30px rgb(0 0 0 / 10%)',
            borderRadius: '4px',
            backdropFilter: 'blur(6px)',
            color: 'rgba(0,0,0,0.5)',
            padding: '3px 12px',
            zIndex: 2,
            cursor: 'pointer',
            fontSize: '0.85em',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            transition: 'all 0.3s ease-in',
            '&:hover': {
              background: 'rgba(255, 255, 255, 0.7)',
              color: 'rgba(0, 0, 0, 1)',
            },
          }}
          tabIndex={0}
          onClick={goBack}
          role="button"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              goBack();
            }
          }}
        >
          <BsArrowLeft style={{ marginRight: '4px' }} />
          Back
        </div>
      )}

      {!!isLastStep ? (
        <Fragment>
          {!!url && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              css={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                marginLeft: '6px',
                padding: '3px 12px',
                borderRadius: '4px',
                background: 'rgba(255, 255, 255, 0.35)',
                boxShadow: '0 4px 30px rgb(0 0 0 / 10%)',
                fontFamily: theme.main.fontFamily,
                color: 'rgba(0,0,0,0.5)',
                fontSize: '0.85em',
                '&:hover': {
                  background: 'rgba(255, 255, 255, 0.7)',
                  color: 'rgba(0, 0, 0, 1)',
                },
              }}
              onClick={goForward}
              role="button"
              tabIndex={0}
            >
              Open
            </div>
          )}
          <NextStepCommandsMenu isHidden={!isLastStep} offset={!!url ? 50 : 0} />
        </Fragment>
      ) : (
        <StatusBar />
      )}

      {currentStep?.type === StepType.Dashboard && currentStep.argument.type === 'video' ? (
        <VideoDashboard source={getPlayer(source) || source} />
      ) : (
        <HtmlDashboard
          source={source}
          style={{
            ...(isLastStep && { marginTop: '48px', borderTop: theme.categoryHeader.borderTop }),
          }}
        />
      )}
      {!!title || !!description ? (
        <div
          style={{
            width: '100%',
            padding: '16px 16px',
            background: theme.base.background,
            borderRadius: `0px 0px ${theme.main.borderRadius} ${theme.main.borderRadius}`,
            color: theme.base.fontColor,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            css={{
              width: '100%',
            }}
          >
            <div
              style={{
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              {!!title ? interpolate(title, _.engine, true, true) : ''}
            </div>
            {!!description && (!isLastStep || activeCommand?.template.type !== 'helpdoc') && (
              <p
                style={{
                  fontSize: '14px',
                  opacity: 0.9,
                  fontWeight: 400,
                  marginBottom: 0,
                  whiteSpace: 'nowrap',
                  width: '100%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  lineHeight: '1.7em',
                }}
              >
                {!!description ? interpolate(description, _.engine, true, true) : ''}
              </p>
            )}
          </div>
        </div>
      ) : (
        <Footer />
      )}
    </div>
  );
};

export default DashboardStepDisplay;
