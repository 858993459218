import { Option, CommandOption } from '../option';
import { ICommandType, IRuleExpression, RequestType } from '@commandbar/internal/middleware/types';
import { IRule } from '@commandbar/internal/middleware/helpers/rules';
import evaluateRules, { evaluateRuleExpression } from '@commandbar/internal/client/RulesParser';

import _get from 'lodash/get';
import { interpolate, interpolateObject } from '../Interpolate';
import { checkSelector } from '@commandbar/internal/util/dom';
import ClientSearch from '../ClientSearch';
import { EngineState } from '../../store/engine/state';
import { isCommandOption } from '../../store/engine';
import { InternalError } from '../Errors';
import Logger from '@commandbar/internal/util/Logger';
import { isStandaloneEditor } from '@commandbar/internal/util/location';
import Analytics from '../../analytics/Analytics';
import { isRemoteEnduserStoreEnabled } from '../../store/engine/end-user/selectors';
import { getSentry } from '@commandbar/internal/util/sentry';

export const hasInitialValueFnDefined = (key: string, engine: EngineState['engine']) =>
  Object.keys(engine.callbacks).some((callbackKey: string) => callbackKey.includes(`commandbar-initialvalue-${key}`));

const isValidArg = (
  command: ICommandType,
  arg: string,
  engine: EngineState['engine'],
): { isValid: boolean; isValidReason: string } => {
  const invalid = (msg: string) => {
    return { isValid: false, isValidReason: msg };
  };

  const valid = () => {
    return { isValid: true, isValidReason: '' };
  };
  const argumentConfig = command.arguments[arg];

  if (!argumentConfig) {
    getSentry()?.captureMessage('Config for argument is missing.', 'error', {
      captureContext: {
        contexts: {
          command,
        },
      },
    });

    return invalid(`Config for ${arg} is missing.`);
  }

  switch (argumentConfig.type) {
    case 'context':
      const options = _get(engine.context, argumentConfig.value);

      // If object search is defined for an argument, validate the arg
      // Don't invalidate if empty. Otherwise this could invalidate commands
      // when the context keys are empty on search
      if (ClientSearch.isDefined(argumentConfig.value, engine)) {
        return valid();
      }

      if (hasInitialValueFnDefined(argumentConfig.value, engine)) {
        return valid();
      }

      if (options === undefined) {
        return invalid(`${argumentConfig.value} isn't defined in context.`);
      }

      if (!Array.isArray(options)) {
        return invalid(`${argumentConfig.value} isn't an array in context.`);
      }

      if (options.length === 0) {
        return invalid(`${argumentConfig.value} is an empty array in context.`);
      }

      return valid();
    default:
      return valid();
  }
};

const isClickableArg = (
  command: ICommandType,
  arg: string,
  engine: EngineState['engine'],
): { isClickable: boolean; isClickableReason: string } => {
  const notClickable = (msg: string) => {
    return { isClickable: false, isClickableReason: msg };
  };

  const clickable = () => {
    return { isClickable: true, isClickableReason: '' };
  };

  if (['clickByXpath', 'clickBySelector', 'click'].includes(command.template.type)) {
    let values;
    try {
      values = command.template.value.map((el: any) => interpolate(el, engine, true, true));
    } catch (e) {
      return notClickable(String(e));
    }

    if (!checkSelector(values[0])) {
      return notClickable(`Cannot find element to click: [${values[0]}]`);
    }
  }
  return clickable();
};

export const runBooleanExpression = (expr: IRuleExpression, engine: EngineState['engine'], prefix: string) => {
  const failed = (msg: string, reasonIsUserDefined = false) => {
    return { passed: false, reason: msg, reasonIsUserDefined };
  };

  const passed = () => {
    return { passed: true, reason: '', reasonIsUserDefined: false };
  };
  try {
    const isSilentMode = Analytics.isSilentMode();
    const isEndUserAnalyticsAvailable = isRemoteEnduserStoreEnabled(engine) && isSilentMode !== true;
    const result = evaluateRuleExpression(
      expr,
      engine.context,
      engine.location,
      engine.endUserStore.data.analytics,
      engine.endUserStore.data.properties,
      {
        nudges: engine.endUserStore.data.nudges_interactions,
        checklists: engine.endUserStore.data.checklist_interactions.checklists,
      },
      isEndUserAnalyticsAvailable,
      Object.keys(engine?.organization?.integrations?.heap?.segments || {}),
    );
    if (result.passed) {
      return passed();
    } else {
      if (result.userDefinedReason) {
        return failed(result.userDefinedReason, true);
      } else {
        return failed(`${prefix}: Rules were not met.`);
      }
    }
  } catch (e) {
    return failed(`${prefix}: Something went wrong in parsing rules. ${String(e)}`);
  }
};

// DEPRECATED
export const runBooleanConditions = (conditions: IRule[], engine: EngineState['engine'], prefix: string) => {
  const failed = (msg: string, reasonIsUserDefined = false) => {
    return { passed: false, reason: msg, reasonIsUserDefined };
  };

  const passed = () => {
    return { passed: true, reason: '', reasonIsUserDefined: false };
  };

  try {
    const result = evaluateRules(
      conditions,
      engine.context,
      engine.location,
      engine.endUserStore.data.analytics,
      engine.endUserStore.data.properties,
      {
        nudges: engine.endUserStore.data.nudges_interactions,
        checklists: engine.endUserStore.data.checklist_interactions.checklists,
      },
    );
    if (result.passed) {
      return passed();
    } else {
      const userDefinedReason = result.failedRules?.find((rule) => !!rule.reason)?.reason;

      if (userDefinedReason) {
        return failed(userDefinedReason, true);
      } else {
        return failed(`${prefix}: Rules were not met.`);
      }
    }
  } catch (e) {
    return failed(`${prefix}: Something went wrong in parsing rules. ${String(e)}`);
  }
};

export const isCommandAvailable = (
  command: ICommandType,
  engine: EngineState['engine'],
): { isAvailable: boolean; isAvailableReason: string; reasonIsUserDefined: boolean } => {
  if (command.availability_expression) {
    const expr = command.availability_expression;

    const { passed, reason, reasonIsUserDefined } = runBooleanExpression(expr, engine, 'Availability condition');
    return { isAvailable: passed, isAvailableReason: reason, reasonIsUserDefined };
  } else {
    return isCommandAvailableLegacy(command, engine);
  }
};

export const isAvailable = (
  option: Option,
  engine: EngineState['engine'],
): { isAvailable: boolean; isAvailableReason: string; reasonIsUserDefined: boolean } => {
  if (!isCommandOption(option)) {
    return { isAvailable: true, isAvailableReason: '', reasonIsUserDefined: false };
  }

  return isCommandAvailable(option.command, engine);
};

// DEPRECATED
const isCommandAvailableLegacy = (
  command: ICommandType,
  engine: EngineState['engine'],
): { isAvailable: boolean; isAvailableReason: string; reasonIsUserDefined: boolean } => {
  const conditions: IRule[] = command.availability_rules;

  if (conditions.length === 0) {
    // no conditions defined
    return { isAvailable: true, isAvailableReason: '', reasonIsUserDefined: false };
  }
  const { passed, reason, reasonIsUserDefined } = runBooleanConditions(conditions, engine, 'Availability condition');
  return { isAvailable: passed, isAvailableReason: reason, reasonIsUserDefined };
};

const isExecutable = (command: ICommandType, _engine: EngineState['engine']) => {
  const engine = {
    ..._engine,
    simulation: true,
  };

  switch (command.template.type) {
    case 'helpdoc':
    // falls through
    case 'link':
      // Is the interpolated URL valid?
      const _url = interpolate(command.template.value, engine, true, true, true);

      switch (command.template.operation) {
        case 'router':
          const routerFunc = engine.callbacks['commandbar-router'];
          if (!routerFunc) {
            throw new InternalError('Link is of router type, but router is not defined.');
          }
          return;
        case 'self':
        // falls through
        case 'blank':
        // falls through
        default:
          return;
      }
    case 'admin':
    // falls through
    case 'builtin':
    // falls through
    case 'callback':
      const callbackName = command.template.value;
      const callback = engine.callbacks[callbackName];

      if (!callback) {
        throw new InternalError(`Callback is not available: [${callbackName}]`);
      }

      return;
    case 'clickByXpath':
    // falls through
    case 'clickBySelector':
    // falls through
    case 'click':
      const values = command.template.value.map((el: any) => interpolate(el, engine, true, true));
      if (!checkSelector(values[0])) {
        throw new InternalError(`Cannot find element to click: [${values[0]}]`);
      }
      return;
    case 'appcues':
      if (!(window as any).Appcues || !(window as any).Appcues.show) {
        throw new InternalError('Appcues is not available');
      }
      return;
    case 'request':
      const { url, headers, body, onSuccess, onError } = command.template.value as RequestType;

      // XXX: The interpolateObject function throws an error if the object is not valid.
      const _interpolatedRequest = interpolateObject({
        s: {
          url,
          headers,
          body,
        },
        engine,
        interpolateContext: true,
        interpolateArgs: true,
      }) as Omit<RequestType, 'method'>;

      let onSuccessFunc: any = undefined;
      if (onSuccess) {
        onSuccessFunc = engine.callbacks[onSuccess];
        if (!onSuccessFunc) {
          Logger.error(`onSuccess callback for request is not available, [${onSuccess}]`);
        }
      }

      let onErrorFunc: any = undefined;
      if (onError) {
        onErrorFunc = engine.callbacks[onError];
        if (!onErrorFunc) {
          Logger.error(`onError callback for request is not available, [${onError}]`);
        }
      }
      return;
    case 'webhook':
    // falls through
    case 'video':
    // falls through
    case 'trigger':
    // falls through
    case 'script':
      return;
    default:
      throw new InternalError('Invalid command execute type.');
  }
};

/**
 * Checks whether all of a command's arguments are presently valid,
 * in order to filter out commands which can't be executed from
 * the user's viewable options.
 */
export const isValid = (
  commandOption: CommandOption,
  engine: EngineState['engine'],
): { isValid: boolean; isValidReason: string } => {
  const args = Object.keys(commandOption.command.arguments);

  // AND across args
  for (const arg of args) {
    const { isValid, isValidReason } = isValidArg(commandOption.command, arg, engine);
    if (!isValid) {
      return { isValid, isValidReason };
    }
    const { isClickable, isClickableReason } = isClickableArg(commandOption.command, arg, engine);
    if (!isClickable) {
      return { isValid: isClickable, isValidReason: isClickableReason };
    }
  }

  // we don't need to validate commands on the standalone editor
  if (isStandaloneEditor) {
    return { isValid: true, isValidReason: '' };
  }

  try {
    isExecutable(commandOption.command, engine);
  } catch (err) {
    if (err instanceof InternalError) {
      return { isValid: false, isValidReason: err.message };
    } else {
      getSentry()?.captureException(err);

      return { isValid: false, isValidReason: 'Failed to validate if command is executable' };
    }
  }

  return { isValid: true, isValidReason: '' };
};
