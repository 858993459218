import { TUpdateEditorRouteDetails } from '../util/dispatchCustomEvent';
import {
  CommandBarClientSDK,
  InstanceAttributes,
  MetaAttributes,
  Metadata,
  ProductConfig,
  ProductDebugOptions,
} from './CommandBarClientSDK';
import { EVENT_NAME } from './AnalyticsEventTypes';
import type { Hub } from '@sentry/browser';
import {
  _access,
  _configuration,
  _configure,
  _configUser,
  _dispose,
  _disposed,
  _eventSubscriptions,
  _userAttributes,
  _isProxy,
  _loadEditor,
  _onEditorPathChange,
  _orgConfig,
  _perf,
  _programmaticTheme,
  _reload,
  _reloadCommands,
  _reloadOrganization,
  _reloadPlaceholders,
  _reloadNudges,
  _reloadHelpHub,
  _previewNudge,
  _showNudgeStepMock,
  _closeNudgeMock,
  _stopChecklistPreview,
  _stopNudgePreview,
  _reloadChecklists,
  _report,
  _search,
  _sentry,
  _setDashboard,
  _setPreviewMode,
  _setTestMode,
  _showGuide,
  _showMessage,
  _user,
  _instanceAttributes,
  _setEditorVisible,
  _shareConfig,
  _shareContextSettings,
  _shareProgrammaticCommands,
  _metaAttributes,
  _previewChecklist,
  _shareEditorRouteWithBar,
  _shareInitialEditorPath,
  _fingerprint,
  _updateEditorRoute,
  _startDebug,
  _stopDebug,
  _previewRecommendationSet,
  _stopRecommendationSetPreview,
  _getDebugSnapshot,
} from './symbols';

import { SDKConfig } from './SDKConfig';
import { IChecklist, ICommandType, IConfigType, INudgeType, IResourceSettingsByContextKey } from '../middleware/types';

import { OrgConfig } from './OrgConfig';
import { EventSubscriber } from './EventHandler';

export type { SDKConfig } from './SDKConfig';
export const _reloadTargets = [
  'reloadCommands',
  'reloadOrganization',
  'reloadPlaceholders',
  'reloadNudges',
  'reloadChecklists',
  'reloadHelpHub',
];
export interface CommandBarInternalSDK {
  [_access]: string | undefined;
  [_instanceAttributes]: InstanceAttributes;
  [_metaAttributes]: MetaAttributes;
  // FIXME: There does not appear to be a way to set this flag!
  [_perf]: boolean;
  [_programmaticTheme]: string | undefined;
  [_sentry]: Hub | undefined;
  [_search]: string;
  [_user]: string | null | undefined; // null -> anonymous user, undefined -> not yet booted
  [_fingerprint]: string | undefined; // string -> anonymous user, undefined -> not yet booted or identified user
  [_userAttributes]: Metadata | undefined;
  [_eventSubscriptions]: Map<symbol, EventSubscriber> | undefined;

  /** @deprecated */
  readonly [_configure]: (uuid: string) => void;
  readonly [_configuration]: SDKConfig;
  readonly [_configUser]: () => Promise<void>;
  readonly [_dispose]: () => void;
  readonly [_disposed]: false;
  readonly [_isProxy]: false;
  readonly [_loadEditor]: () => void;
  readonly [_onEditorPathChange]: (notify: (path: string) => void) => void;
  readonly [_shareEditorRouteWithBar]: (path: string) => void;
  readonly [_orgConfig]: OrgConfig;
  readonly [_reload]: (reloadTargets: (typeof _reloadTargets)[number][]) => void;
  readonly [_reloadCommands]: (preLoadedConfig?: IConfigType) => void;
  readonly [_reloadOrganization]: (preLoadedConfig?: IConfigType) => void;
  readonly [_reloadPlaceholders]: (preLoadedConfig?: IConfigType) => void;
  readonly [_reloadNudges]: (preLoadedConfig?: IConfigType) => void;
  readonly [_reloadHelpHub]: (preLoadedConfig?: IConfigType) => void;
  readonly [_previewRecommendationSet]: (data: { recommendationSetId?: number }) => void;
  readonly [_stopRecommendationSetPreview]: () => void;
  readonly [_previewNudge]: (data: { nudge: INudgeType }) => void;
  readonly [_showNudgeStepMock]: (data: { nudge: INudgeType; stepIndex: number }) => void;
  readonly [_closeNudgeMock]: (data: { nudge: INudgeType }) => void;
  readonly [_stopChecklistPreview]: () => void;
  readonly [_stopNudgePreview]: (data: { index?: number }) => void;
  readonly [_previewChecklist]: (data: { checklist: IChecklist; clearData?: boolean }) => void;
  readonly [_reloadChecklists]: () => void;

  /** @deprecated  */
  readonly [_report]: (event: EVENT_NAME, data?: Metadata) => void;
  /** @deprecated  */
  readonly [_setDashboard]: (element: HTMLElement) => void;
  readonly [_setPreviewMode]: (on: boolean) => void;
  readonly [_setTestMode]: (on: boolean) => void;
  readonly [_setEditorVisible]: (visible: boolean) => void;
  readonly [_shareContextSettings]: () => {
    local: IResourceSettingsByContextKey;
    server: IResourceSettingsByContextKey;
  };
  readonly [_shareConfig]: () => any;
  readonly [_shareProgrammaticCommands]: () => ICommandType[];
  readonly [_shareInitialEditorPath]: () => string | null;
  readonly [_startDebug]: (data: ProductDebugOptions) => void;
  readonly [_stopDebug]: (product?: ProductConfig[number]) => void;
  readonly [_getDebugSnapshot]: (data?: { nudgeId?: INudgeType['id']; stepIndex?: number }) => void;
  readonly [_updateEditorRoute]: (data: TUpdateEditorRouteDetails) => void;
  /** @deprecated */
  readonly [_showGuide]: (eventName: string, preview?: boolean) => void;
  /** @deprecated */
  readonly [_showMessage]: (eventName: string, preview?: boolean) => void;
}

export type CommandBarSDK = CommandBarInternalSDK & CommandBarClientSDK;
