import { CommandExecutionEvent } from '@commandbar/internal/client/EventHandler';
import { getSDK } from '@commandbar/internal/client/globals';
import { _eventSubscriptions } from '@commandbar/internal/client/symbols';
import { IChecklist, IChecklistItem } from '@commandbar/internal/middleware/types';
import { isMobile } from '@commandbar/internal/util/operatingSystem';
import isEqual from 'lodash/isEqual';
import { updateEndUserStore } from '../end-user/actions';

import { runBooleanExpression } from '../../../engine/option/OptionValidate';
import { clickExecutable, commandExecutable, linkExecutable, nudgeExecutable } from '../steps/executables';
import { State } from '../../../store';
import {
  dontShowChecklist,
  getChecklistProgress,
  hasIncompleteItems,
  isChecklistInPreviewMode,
  previouslyShownChecklist,
} from './selectors';
import { isItemCompleted } from '../../../store/engine/checklists/selectors';
import { getElement } from '@commandbar/internal/util/dom';
import Logger from '@commandbar/internal/util/Logger';
import { ref } from 'valtio';
import { EngineState } from '../state';
import * as Reporting from '../../../analytics/Reporting';
import { releaseConfetti } from '../../../components/Confetti';
import { ChecklistInteractionState } from '@commandbar/internal/middleware/types';
import { isStandaloneEditor } from '@commandbar/internal/util/location';
import { getConditions } from '@commandbar/internal/middleware/helpers/rules';
import { deconstructShareLink } from '@commandbar/internal/proxy-editor/share_links';
import { isAvailableToEndUser } from '../helpers';
import { openEditorIfLoaded } from '../../util/editorUtils';

export const setChecklists = (_: State, checklists: IChecklist[]) => {
  for (const checklist of checklists) {
    if (checklist.trigger.type === 'when_element_appears') {
      _.engine.triggerableSelectors.push(checklist.trigger.meta.selector);
    }
  }

  const hasChanged = !isEqual(_.engine.checklists, checklists);

  if (hasChanged) {
    _.engine.checklists = ref(checklists);
    if (_.engine.activeChecklist !== null) {
      // re-set the activeChecklist after updating the list of checklists
      _.engine.activeChecklist = checklists.find((c) => c.id === _.engine.activeChecklist?.id) || null;
    } else {
      // Check if conditions have changed
      triggerChecklists(_, { type: 'when_conditions_pass' });
    }
  }
};

const activateChecklist = (_: EngineState, checklist: IChecklist) => {
  _.engine.activeChecklist = checklist;
  _.engine.queuedChecklist = null;
  updateEndUserStore(_, { activeChecklistId: checklist.id }, 'checklist_interactions');
  const completed = !hasIncompleteItems(_, checklist);
  Reporting.checklistShown(checklist, completed);
};

export const triggerChecklists = (_: EngineState, trigger: IChecklist['trigger']) => {
  if (
    typeof _.engine.endUser === 'undefined' ||
    _.engine.checklists.length === 0 ||
    isMobile() ||
    !_.engine.products.includes('questlists') ||
    isStandaloneEditor
  )
    return;

  const passesAudienceConditions = (checklist: IChecklist) => {
    if (checklist.audience) {
      switch (checklist.audience.type) {
        case 'all_users':
          return true;
        case 'rule_expression':
          return runBooleanExpression(checklist.audience.expression, _.engine, '').passed;
        default:
          return false;
      }
    }
    return true;
  };

  const passesPageConditions = (checklist: IChecklist) => {
    return !checklist.show_expression || runBooleanExpression(checklist.show_expression, _.engine, '').passed;
  };

  const deconstructedShareLink = deconstructShareLink(_.engine.location.search);
  const isBeingShared = (checklist: IChecklist): boolean => {
    if (!deconstructedShareLink) return false;

    return (
      trigger.type === 'when_conditions_pass' &&
      _.engine.location.href.startsWith(checklist.share_page_url) &&
      deconstructedShareLink?.type === 'questlist' &&
      checklist.id === deconstructedShareLink.id
    );
  };
  const isTriggered = (checklist: IChecklist): boolean => {
    /**
     * Note: the 'when_share_link_viewed' trigger does not have to be set for share linking to work.
     * Rather, nudges/QLs with this trigger cannot be viewed unless it is via share link
     */
    if (checklist.trigger.type === 'when_share_link_viewed') return false;
    if (trigger?.type === 'when_page_reached' && checklist.trigger.type === 'when_page_reached') {
      return trigger.meta.url.includes(checklist.trigger.meta.url);
    }
    return isEqual(checklist.trigger, trigger);
  };

  if (deconstructedShareLink) {
    const sharedChecklist = _.engine.checklists.find(isBeingShared);

    if (!!sharedChecklist) {
      const passesConditions = passesPageConditions(sharedChecklist) && isAvailableToEndUser(_, sharedChecklist);
      if (_.engine.activeChecklist?.id === sharedChecklist.id) {
        return;
      }

      if (passesConditions) {
        clearChecklistFields(_, sharedChecklist, ['isSkipped', 'isCompleted']);
        activateChecklist(_, sharedChecklist);
        return;
      }
    }
  }

  // show a previously shown checklist immediately even if remote interactions haven't been loaded yet
  const previouslyShown = previouslyShownChecklist(_);

  if (!!previouslyShown) {
    const previousPassesConditions = passesPageConditions(previouslyShown) && isAvailableToEndUser(_, previouslyShown);

    if (!_.engine.activeChecklist && previousPassesConditions) {
      _.engine.activeChecklist = previouslyShown;
    } else if (
      !!_.engine.activeChecklist &&
      _.engine.activeChecklist?.id === previouslyShown.id &&
      !previousPassesConditions
    ) {
      // if the conditions are not met anymore, we need to hide the checklist
      // if the active checklist does not match the previously shown checklist, it means that it is an editor preview that we don't want to hide
      // we do not clear the activeChecklistId flag in end-user store because we want to show this checklist again once conditions are met again
      _.engine.activeChecklist = null;
    }
  }

  if (!!_.engine.activeChecklist) {
    // if we found a previously shown checklist in the remote end-user store and enqueued a checklist while we waited for the remote data to load, we clear it
    if (_.engine.endUserStore.hasRemoteLoaded && !!_.engine.queuedChecklist) {
      _.engine.queuedChecklist = null;
    }

    if (dontShowChecklist(_, _.engine.activeChecklist)) {
      _.engine.activeChecklist = null;
    } else {
      return;
    }
  }

  if (!!_.engine.queuedChecklist && dontShowChecklist(_, _.engine.queuedChecklist)) {
    _.engine.queuedChecklist = null;
  }

  if (!_.engine.queuedChecklist) {
    // check if there is a new (=not previously shown) checklist to activate
    for (let i = 0; i < _.engine.checklists.length; i++) {
      const c = _.engine.checklists[i];

      if ((!c.is_live && !_.engine.isAdmin) || dontShowChecklist(_, c)) {
        continue;
      }

      if (isTriggered(c) && passesAudienceConditions(c) && passesPageConditions(c)) {
        // we only want to activate a new checklist once the end user store has been fully loaded
        // so we queue the checklist first and activate it once the end user store is loaded and no previously shown checklist has been found
        _.engine.queuedChecklist = c;
        break;
      }
    }
  }

  // Activate a new checklist and modify end user store accordingly
  // we only do this once the end user store has been fully loaded
  if (_.engine.endUserStore.hasRemoteLoaded && !!_.engine.queuedChecklist) {
    activateChecklist(_, _.engine.queuedChecklist);
  }
};

export const updateChecklistItemConditionsGoals = (_: EngineState) => {
  const activeChecklist = _.engine.activeChecklist;

  if (!activeChecklist) {
    return;
  }

  activeChecklist.items
    .filter((item) => !isItemCompleted(_, activeChecklist, item))
    .forEach((item) => {
      switch (item.goal.type) {
        case 'page_visited':
          if (!!item.goal.value && _.engine.location.href.includes(item.goal.value)) {
            onChecklistItemComplete(_, item);
          }
          break;
        case 'conditions_met':
          if (
            getConditions(item.goal.expression).length > 0 &&
            runBooleanExpression(item.goal.expression, _.engine, '').passed
          ) {
            onChecklistItemComplete(_, item);
          }
          break;
        default:
          //other goals are listened to in the component or processed after CTA click
          break;
      }
    });
};

export const updateChecklistItemEventGoals = (_: EngineState, event: string) => {
  const activeChecklist = _.engine.activeChecklist;

  if (!activeChecklist) {
    return;
  }

  activeChecklist.items.forEach((item) => {
    if (!isItemCompleted(_, activeChecklist, item) && item.goal.type === 'event_tracked' && item.goal.event === event) {
      onChecklistItemComplete(_, item);
    }
  });
};

export const onChecklistItemSelect = (_: State, item: IChecklistItem) => {
  if (!!_.engine.activeChecklist) {
    const checklist = _.engine.activeChecklist;
    Reporting.checklistItemEngagement(item, checklist.id, isItemCompleted(_, checklist, item), 'cta_clicked');
  }

  if (isStandaloneEditor) {
    _.engine.callbacks['__standalone-editor-checklist-action-cta-clicked']();
    return;
  }

  let executable: (() => unknown) | null = null;

  switch (item.action.type) {
    case 'open_bar':
      getSDK().open(item.action.value, { categoryFilter: item.action.categoryFilter });
      break;
    case 'link':
      executable = linkExecutable(_.engine, item.action);
      break;
    case 'click':
      executable = clickExecutable(_.engine, item.action);
      break;
    case 'execute_command':
      executable = commandExecutable(_, item.action, { type: 'questlist', id: item.id });
      break;
    case 'nudge':
      executable = nudgeExecutable(_, item.action);
      break;
  }

  if (executable) {
    executable();
  }

  if (item.goal.type === 'cta_clicked') {
    onChecklistItemComplete(_, item);
  }
};

export const onChecklistItemComplete = (_: EngineState, item: IChecklistItem, skipped = false) => {
  if (!_.engine.activeChecklist) {
    return;
  }
  if (item.celebrate) releaseConfetti();

  const checklist = _.engine.activeChecklist;
  if (skipped) {
    Reporting.checklistItemEngagement(item, checklist.id, true, 'skipped');
  }
  Reporting.checklistItemEngagement(item, checklist.id, true, 'completed');

  const allChecklistData = { ..._.engine.endUserStore.data.checklist_interactions.checklists } || {};
  const thisChecklistData = allChecklistData[_.engine.activeChecklist.id] || {};
  const items = { ...thisChecklistData?.items } || {};
  items[item.id] = { completedTS: new Date().toISOString() };
  const newChecklistData = { ...allChecklistData, [_.engine.activeChecklist.id]: { ...thisChecklistData, items } };
  updateEndUserStore(_, { checklists: newChecklistData }, 'checklist_interactions');

  if (!hasIncompleteItems(_, checklist)) {
    Reporting.checklistEngagement(checklist, checklist.id, 'completed', true);
  }

  if (checklist.celebrate && !hasIncompleteItems(_, checklist)) {
    releaseConfetti();
  }
};

export const addGoalListeners = (_: EngineState, checklist: IChecklist, items: IChecklistItem[]): (() => void) => {
  const removeListenerFns: (() => void)[] = [];

  items
    .filter((item) => !isItemCompleted(_, checklist, item))
    .forEach((item) => {
      switch (item.goal.type) {
        case 'command_executed':
          const command = item.goal.meta.command;
          const sdk = getSDK();
          if (!!sdk[_eventSubscriptions] && sdk[_eventSubscriptions] !== undefined) {
            const symbol = Symbol(`checklistItemListener-${item.id}`);
            sdk[_eventSubscriptions]?.set(symbol, (name, data) => {
              if (name === 'command_execution') {
                if (`${(data as CommandExecutionEvent).command}` === command && _.engine.activeChecklist) {
                  onChecklistItemComplete(_, item);
                }
              }
            });

            removeListenerFns.push(() => {
              sdk[_eventSubscriptions]?.delete(symbol);
            });
          }
          break;
        case 'element_clicked':
          const element = getElement(item.goal.value);
          if (!!element) {
            const clickListener = () => {
              onChecklistItemComplete(_, item);
            };
            element.addEventListener('click', clickListener);

            removeListenerFns.push(() => {
              element.removeEventListener('click', clickListener);
            });
          } else {
            if (item.goal.value) {
              Logger.warn(`Couldn't find element for click goal: ${item.goal.value}`);
            }
          }
          break;
        case 'page_visited':
          // handled in updateChecklists as subscription to location and context change
          break;
        case 'conditions_met':
          // handled in updateChecklists as subscription to location and context change
          break;
        case 'cta_clicked':
          // handled in onChecklistItemSelect
          break;
      }
    });

  return () => {
    removeListenerFns.forEach((fn) => fn());
  };
};

export const markChecklistAsCompleted = (_: State, checklist: IChecklist) => {
  _.engine.activeChecklist = null;
  const allChecklistData = { ..._.engine.endUserStore.data.checklist_interactions.checklists } || {};
  const thisChecklistData = allChecklistData[checklist.id] || {};
  const newChecklistData = { ...allChecklistData, [checklist.id]: { ...thisChecklistData, isCompleted: true } };

  updateEndUserStore(_, { checklists: newChecklistData, activeChecklistId: null }, 'checklist_interactions');
  const completed = !hasIncompleteItems(_, checklist);

  Reporting.checklistEngagement(checklist, checklist.items.length, 'dismissed', completed);

  if (isChecklistInPreviewMode(checklist)) {
    /** This is part of the "Preview" experience. The editor closes when a QL is previewed and re-opens when the preview is completed. */
    openEditorIfLoaded();
  }
};

export const markChecklistAsSkipped = (_: State, checklist: IChecklist) => {
  _.engine.activeChecklist = null;
  const allChecklistData = { ..._.engine.endUserStore.data.checklist_interactions.checklists } || {};
  const thisChecklistData = allChecklistData[checklist.id] || {};
  const newChecklistData = {
    ...allChecklistData,
    [checklist.id]: { ...thisChecklistData, isSkipped: true },
  };

  updateEndUserStore(_, { checklists: newChecklistData, activeChecklistId: null }, 'checklist_interactions');
  const completed = !hasIncompleteItems(_, checklist);

  Reporting.checklistEngagement(checklist, getChecklistProgress(_, checklist).completedItems, 'dismissed', completed);

  if (isChecklistInPreviewMode(checklist)) {
    /** This is part of the "Preview" experience. The editor closes when a QL is previewed and re-opens when the preview is completed. */
    openEditorIfLoaded();
  }
};

export const markChecklistAsSeen = (_: State, checklist: IChecklist) => {
  const allChecklistData = { ..._.engine.endUserStore.data.checklist_interactions.checklists } || {};
  const thisChecklistData = allChecklistData[checklist.id] || {};
  const newChecklistData = {
    ...allChecklistData,
    [checklist.id]: { ...thisChecklistData, isSeen: true },
  };

  updateEndUserStore(_, { checklists: newChecklistData }, 'checklist_interactions');
};

export const setChecklistExpandedState = (_: State, checklist: IChecklist, value: boolean) => {
  const allChecklistData = { ..._.engine.endUserStore.data.checklist_interactions.checklists } || {};
  const thisChecklistData = allChecklistData[checklist.id] || {};
  const newChecklistData = {
    ...allChecklistData,
    [checklist.id]: { ...thisChecklistData, isExpanded: value },
  };

  updateEndUserStore(_, { checklists: newChecklistData }, 'checklist_interactions');
};

export const clearChecklistData = (_: EngineState, checklist: IChecklist) => {
  const allChecklistData = { ..._.engine.endUserStore.data.checklist_interactions.checklists } || {};
  delete allChecklistData[checklist.id];
  updateEndUserStore(_, { checklists: allChecklistData }, 'checklist_interactions');
};

export const clearChecklistFields = (
  _: EngineState,
  checklist: IChecklist,
  fieldsToClear: Array<keyof ChecklistInteractionState>,
) => {
  const allChecklistData = { ..._.engine.endUserStore.data.checklist_interactions.checklists } || {};
  if (!allChecklistData[checklist.id]) return;
  for (const field of fieldsToClear) {
    if (allChecklistData[checklist.id][field] !== undefined) {
      delete allChecklistData[checklist.id][field];
    }
  }
  updateEndUserStore(_, { checklists: allChecklistData }, 'checklist_interactions');
};
