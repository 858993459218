// GENERATE BY icons/script/react-scripts.mjs
// DO NOT EDIT IT MANUALLY

import * as React from 'react';
import { SVGProps } from 'react';
const Send03 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M2.5739 4.7293c-.1815-.5468-.2722-.8201-.2062-.9885a.5.5 0 0 1 .3315-.2992c.1742-.0485.4369.0697.9623.3062l16.7172 7.5227c.5144.2314.7715.3472.851.5079a.4997.4997 0 0 1 0 .4432c-.0795.1608-.3366.2765-.851.508L3.6673 20.2497c-.527.2372-.7904.3557-.9649.307a.4997.4997 0 0 1-.3314-.2998c-.0658-.1688.026-.4427.2094-.9907l2.3349-6.9747c.0314-.0938.0471-.1407.0534-.1887a.5003.5003 0 0 0 .0001-.1284c-.0061-.048-.0217-.0949-.0529-.1888L2.574 4.7293Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.4999 12h-5.5m-.0846.2915-2.335 6.9747c-.1834.548-.275.8219-.2093.9907a.4997.4997 0 0 0 .3314.2998c.1745.0487.438-.0698.9649-.307l16.7114-7.5201c.5144-.2315.7715-.3472.851-.508a.4997.4997 0 0 0 0-.4432c-.0795-.1607-.3366-.2765-.851-.5079L3.6615 3.7478c-.5254-.2364-.788-.3546-.9623-.3062a.5.5 0 0 0-.3315.2992c-.066.1684.0247.4417.2062.9885l2.342 7.0563c.0312.0939.0468.1408.053.1888a.5003.5003 0 0 1-.0002.1284c-.0063.048-.022.0949-.0534.1887Z"
    />
  </svg>
);
export default Send03;
