import React, { useEffect, Dispatch, SetStateAction } from 'react';

import { useStore } from '../../hooks/useStore';
import { AnimationTransition } from '../../hooks/useDelayUnmount';
import { ChatMessage, NO_ANSWER } from '../../client_api/search';

import Chat from '../copilot/Chat';
import { isHelpDocWithLink } from './Doc';

interface ChatOnlyProps {
  containerStyles: any;
  chatID: string | undefined;
  chatHistory: Array<ChatMessage>;
  setChatID: React.Dispatch<React.SetStateAction<string | undefined>>;
  setChatHistory: Dispatch<SetStateAction<ChatMessage[]>>;
  setIsChatMode: (isChatMode: boolean) => void;
}

const ChatOnly = ({
  containerStyles,
  chatID,
  chatHistory,
  setChatID,
  setChatHistory,
  setIsChatMode,
}: ChatOnlyProps) => {
  const { engine } = useStore();

  useEffect(() => {
    if (!chatHistory.length) {
      setChatHistory([
        {
          message: {
            ...NO_ANSWER,
            answer: engine.organization?.helphub_chat_welcome_message || 'Hey there! What can we help with?',
          },
          type: 'bot',
        },
      ]);
    }
  }, []);

  return (
    <div style={containerStyles}>
      <AnimationTransition isMounted style={{ height: '100%' }}>
        <Chat
          chatID={chatID}
          chatHistory={chatHistory}
          setCurrentDoc={(doc) => {
            if (doc) {
              const docTemplate = doc.command.template;
              if (isHelpDocWithLink(docTemplate)) {
                window.open(docTemplate.value, '_blank');
              }
            }
          }}
          setChatID={setChatID}
          setChatHistory={setChatHistory}
          setIsChatMode={setIsChatMode}
        />
      </AnimationTransition>
    </div>
  );
};

export default ChatOnly;
