import React from 'react';
import { toast as toastHandler } from 'react-hot-toast';
import { ref } from 'valtio';

import { getElement } from '@commandbar/internal/util/dom';
import { EngineState } from '../../store/engine';
import { interpolateObject } from '../../engine/Interpolate';
import { DraggablePopover } from './Popover';
import Pin from './Pin';

import type { INudgeType } from '@commandbar/internal/middleware/types';
import { isStandaloneEditor } from '@commandbar/internal/util/location';

export enum RenderMode {
  DEFAULT,
  MOCK,
}

type RenderNudge = (
  _: EngineState,
  nudge: INudgeType,
  options?: { renderMode?: RenderMode.MOCK; stepIndex?: number },
) => void;

export const renderNudge: RenderNudge = (_, nudge, options) => {
  const renderMode = options?.renderMode ?? RenderMode.DEFAULT;
  const stepIndex = options?.stepIndex ?? 0;

  nudge = interpolateObject({ s: nudge, engine: _.engine, interpolateContext: true, interpolateArgs: false });

  const step = nudge.steps[options?.stepIndex ?? 0];

  const stepCount =
    nudge.show_step_counter && options?.stepIndex !== undefined && nudge.steps.length > 1
      ? `${options?.stepIndex + 1}/${nudge.steps.length}`
      : undefined;

  switch (step.form_factor.type) {
    case 'popover':
      if (step.form_factor.position === 'center' || isStandaloneEditor) {
        toastHandler.custom(
          () => (
            <DraggablePopover
              nudge={nudge}
              stepIndex={stepIndex}
              stepCount={stepCount}
              renderMode={renderMode}
              center
            />
          ),
          {
            id: `${nudge.id}-${String(step.id)}${renderMode === RenderMode.MOCK ? '-mock' : ''}`,
            duration: Infinity,
          },
        );
      } else {
        toastHandler.custom(
          () => <DraggablePopover nudge={nudge} stepIndex={stepIndex} stepCount={stepCount} renderMode={renderMode} />,
          {
            id: `${nudge.id}-${String(step.id)}${renderMode === RenderMode.MOCK ? '-mock' : ''}`,
            position: step.form_factor.position,
            duration: Infinity,
          },
        );
      }
      break;
    case 'modal':
      _.engine.currentModalNudge = ref({
        nudge,
        stepIndex,
        stepCount,
        renderMode,
      });
      break;
    case 'pin':
      const selector = step.form_factor.anchor;
      const { offset: nudgeOffset, is_open_by_default } = step.form_factor;
      const isOpenByDefault = is_open_by_default === undefined ? true : is_open_by_default;
      const offset = nudgeOffset === undefined ? { x: '0px', y: '0px' } : nudgeOffset;
      const targetEl = getElement(selector);

      toastHandler.custom(
        () => (
          <Pin pin={{ nudge, step, targetEl, isOpenByDefault, offset }} stepCount={stepCount} renderMode={renderMode} />
        ),
        {
          id: `${nudge.id}-${String(step.id)}${renderMode === RenderMode.MOCK ? '-mock' : ''}`,
          duration: Infinity,
        },
      );
      break;
    default:
      toastHandler.custom(
        () => <DraggablePopover nudge={nudge} stepIndex={stepIndex} stepCount={stepCount} renderMode={renderMode} />,
        {
          id: `${nudge.id}-${String(step.id)}${renderMode === RenderMode.MOCK ? '-mock' : ''}`,
          position: 'top-right',
          duration: Infinity,
        },
      );
  }
};
