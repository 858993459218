/** @jsx jsx  */
import { css, jsx } from '@emotion/core';
import React, { useRef } from 'react';
import LoadingIndicator from '../select/input/LoadingIndicator';
import { useStyles } from '../helphub/useStyles';
import { Send03, RefreshCw01 } from '@commandbar/design-system/icons/react';
import { CB_COLORS } from '@commandbar/design-system/colors';

type MessageInputProps = {
  isLoading: boolean;
  resetChat: () => void;
  onSubmit: (query: string) => void;
};

export const MessageInput: React.FC<MessageInputProps> = (props) => {
  const { isLoading, onSubmit } = props;
  const styles = useStyles();

  const chatInput = useRef<HTMLTextAreaElement>(null);
  const [query, setQuery] = React.useState('');

  React.useEffect(() => {
    chatInput.current?.focus();
  }, []);

  return (
    <div css={styles.chatTextAreaContainer}>
      <div style={styles.chatTextAreaWrapper}>
        <textarea
          suppressContentEditableWarning={true}
          ref={chatInput}
          placeholder={styles.strings.chatInputPlaceholder}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !isLoading && !!query.length) {
              e.preventDefault();
              setQuery('');
              onSubmit(query);

              chatInput.current?.focus();
            }
          }}
          onChange={(e) => {
            setQuery(e.currentTarget.value);
            e.currentTarget.style.height = 'auto';
            e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
          }}
          css={styles.chatTextArea}
          rows={1}
          value={query ?? ''}
        />

        <div
          style={{
            display: 'flex',
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
          css={css`
            & button {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 32px;
              height: 32px;
              background: none;
              border: none;
              color: ${CB_COLORS.neutral600};
              cursor: pointer;
            }
          `}
        >
          <button onClick={() => props.resetChat()}>
            <RefreshCw01 width={16} height={16} />
          </button>

          {isLoading ? (
            <div
              style={{ width: '32px', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <LoadingIndicator size={16} isLoading />
            </div>
          ) : (
            <button
              onClick={() => {
                if (query) {
                  setQuery('');
                  onSubmit(query);
                }
              }}
            >
              <Send03
                width={16}
                height={16}
                style={{
                  ...(!query.length && { opacity: '0.6' }),
                }}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
