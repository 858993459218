import React from 'react';
import { ThumbsDown, ThumbsUp } from '@commandbar/design-system/icons/react';
import { IInstantAnswerType } from '@commandbar/internal/middleware/types';
import { useAction } from '../../hooks/useAction';
import { setFeedbackGiven } from '../../store/engine';

type FeedbackButtonProps = {
  liveAnswer: IInstantAnswerType | null;
};

export const FeedbackButtons: React.FC<FeedbackButtonProps> = ({ liveAnswer }) => {
  const [givenFeedback, setGivenFeedback] = React.useState<1 | -1 | null>(null);
  const markFeedbackGiven = useAction(setFeedbackGiven);

  const styles: React.CSSProperties = {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 12px',
    gap: '16px',
    width: '40px',
    height: '32px',
    background: '#FFFFFF',
    border: '1px solid rgba(10, 10, 15, 0.24)',
    borderRadius: '4px',
    flex: 'none',
    order: '0',
    flexGrow: '0',
    cursor: 'pointer',
  };

  const feedbackGivenStyles: React.CSSProperties = {
    ...styles,
    border: '1px solid #dd9d23',
    background: '#fff9ef',
    boxShadow: '0px 0px 0px 3px #dd9d2230',
    color: '#dd9d23',
  };

  const giveFeedback = (feedback: 1 | -1) => {
    if (!liveAnswer) {
      return;
    }

    if (feedback === givenFeedback) {
      setGivenFeedback(null);
    } else {
      setGivenFeedback(feedback);
      markFeedbackGiven(liveAnswer, feedback);
    }
  };

  return liveAnswer ? (
    <div
      className="chat-feedback"
      style={{
        display: 'flex',
        gap: '8px',
        marginTop: '12px',
        // Override hover styles when feedback has been given
        opacity: givenFeedback !== null ? 1 : undefined,
      }}
    >
      <button tabIndex={0} style={givenFeedback === 1 ? feedbackGivenStyles : styles} onClick={() => giveFeedback(1)}>
        <ThumbsUp height={16} width={16} />
      </button>

      <button tabIndex={0} style={givenFeedback === -1 ? feedbackGivenStyles : styles} onClick={() => giveFeedback(-1)}>
        <ThumbsDown height={16} width={16} />
      </button>
    </div>
  ) : null;
};

export default FeedbackButtons;
