/** @jsx jsx */
import React, { CSSProperties, SyntheticEvent } from 'react';
import Dialog from 'rc-dialog';
import { jsx } from '@emotion/core';
import { useTheme } from 'emotion-theming';

import Z from '@commandbar/internal/client/Z';
import { isStandaloneEditor } from '@commandbar/internal/util/location';
import LocalStorage from '@commandbar/internal/util/LocalStorage';

import CloseButton from './CloseButton';
import { PoweredBy } from './PoweredBy';
import { DraftFooter } from '../DraftFooter';
import { useStore } from '../../hooks/useStore';

import type { ITheme } from '@commandbar/internal/client/theme';
import type { INudgeType } from '@commandbar/internal/middleware/types';
import ContentContainer from './ContentContainer';
import { isMobile } from '@commandbar/internal/util/operatingSystem';
import { isEditorOpen } from '../../store/util/editorUtils';
import { RenderMode } from './RenderNudge';
import { getNudgeService, isNudgeDismissible } from '../../store/engine/nudges/selectors';
import { useAction } from '../../hooks/useAction';
import { closeNudgeMock } from '../../store/engine';

const getStyles = (theme: ITheme, autoWidth: boolean): Record<string, CSSProperties> => ({
  container: {
    width: autoWidth ? 'auto' : theme.nudgeModal.width,
    height: theme.nudgeModal.height,
    margin: 0,
    borderRadius: theme.nudgeModal.borderRadius,
    boxShadow: theme.nudgeModal.boxShadow,
    backgroundColor: theme.nudgeModal.background,
    fontFamily: theme.nudgeModal.fontFamily,
    color: theme.nudgeModal.color,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.nudgeModal.gap,
    padding: theme.nudgeModal.padding,
  },
  header: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  title: {
    fontFamily: theme.nudgeModal.titleFontFamily,
    fontWeight: theme.nudgeModal.titleFontWeight,
    fontSize: theme.nudgeModal.titleFontSize,
    lineHeight: theme.nudgeModal.titleLineHeight,
    color: theme.nudgeModal.titleColor,
    margin: '0',
  },
  content: {
    fontFamily: theme.nudgeModal.contentFontFamily,
    fontWeight: theme.nudgeModal.contentFontWeight,
    fontSize: theme.nudgeModal.contentFontSize,
    lineHeight: theme.nudgeModal.contentLineHeight,
  },
  ctaButton: {
    padding: theme.nudgeModal.ctaPadding,
    background: theme.nudgeModal.ctaBackground,
    color: theme.nudgeModal.ctaColor,
    border: theme.nudgeModal.ctaBorder,
    boxShadow: theme.nudgeModal.ctaBoxShadow,
    textShadow: theme.nudgeModal.ctaTextShadow,
    borderRadius: theme.nudgeModal.ctaBorderRadius,
    fontFamily: theme.nudgeModal.ctaFontFamily,
    fontSize: theme.nudgeModal.ctaFontSize,
    lineHeight: theme.nudgeModal.ctaLineHeight,
    fontWeight: theme.nudgeModal.ctaFontWeight,
  },
  ctaSecondaryButton: {
    fontFamily: theme.nudgeModal.ctaSecondaryFontFamily,
    padding: theme.nudgeModal.ctaSecondaryPadding,
    background: theme.nudgeModal.ctaSecondaryBackground,
    color: theme.nudgeModal.ctaSecondaryColor,
    border: theme.nudgeModal.ctaSecondaryBorder,
    boxShadow: theme.nudgeModal.ctaSecondaryBoxShadow,
    textShadow: theme.nudgeModal.ctaSecondaryTextShadow,
    borderRadius: theme.nudgeModal.ctaSecondaryBorderRadius,
    fontSize: theme.nudgeModal.ctaSecondaryFontSize,
    lineHeight: theme.nudgeModal.ctaSecondaryLineHeight,
    fontWeight: theme.nudgeModal.ctaSecondaryFontWeight,
  },
  ctaButtonWithStepCounter: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
  },
  closeButtonOverrides: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  draft: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 10px 4px 8px',
    background: '#FFFCF2',
    borderTop: '1px solid #F0EDE3',
    fontSize: '12px',
    borderBottomLeftRadius: theme.nudges.borderRadius,
    borderBottomRightRadius: theme.nudges.borderRadius,
  },
  draftStatus: {
    boxSizing: 'border-box',
    width: '8px',
    height: '8px',
    background: '#EDBE19',
    border: '1px solid #B18F15',
    boxShadow: '0px 0px 4px #EDBE19',
    borderRadius: '10px',
    marginRight: '5px',
  },
  stepCount: {
    color: theme.nudgeModal.stepCountColor,
    fontFamily: theme.nudgeModal.stepCountFontFamily,
    fontSize: theme.nudgeModal.stepCountFontSize,
    fontWeight: theme.nudgeModal.stepCountFontWeight,
  },
});

interface ModalProps {
  nudge: INudgeType;
  stepIndex: number;
  renderMode: RenderMode;
  stepCount?: string;
}

const Modal = ({ nudge, stepIndex, renderMode, stepCount }: ModalProps) => {
  const { theme }: { theme: ITheme } = useTheme();
  const _ = useStore();
  const {
    engine: { organization },
  } = _;
  const step = nudge.steps[stepIndex];
  const styles = getStyles(
    theme,
    !isMobile() && step.content.some((block) => block?.type === 'survey_rating' && block.meta.options === 10),
  );
  const dismissible = isNudgeDismissible(nudge);

  const getService = useAction(getNudgeService);
  const close = useAction(closeNudgeMock);
  const service = renderMode !== RenderMode.MOCK ? getService(nudge.id) : undefined;

  const showBranding = organization?.branding === 'branded';

  const dismissNudge = React.useCallback(
    (e: React.MouseEvent | SyntheticEvent) => {
      if (dismissible) {
        e.preventDefault();
        e.stopPropagation();

        if (renderMode === RenderMode.MOCK) {
          close(nudge, true);
        } else {
          service?.send('DISMISS');
        }
      }
    },
    [service],
  );

  const commandBarWrapper = document.getElementById('commandbar-wrapper');
  const commandBarWrapperRect = commandBarWrapper?.getBoundingClientRect();
  const editorWidth = Number(LocalStorage.get('width', '590'));
  const openEditorAdjustment = renderMode === RenderMode.MOCK ? editorWidth + 8 : 0;
  const editorOpen = Number(LocalStorage.get('editor.open', false)) && isEditorOpen();

  return (
    <Dialog
      title={step?.title}
      zIndex={Z.Z_EDITOR - 1}
      wrapClassName={`commandbar-nudge-modal${renderMode === RenderMode.MOCK ? '-mock' : ''}`}
      wrapStyle={{
        marginLeft: isStandaloneEditor ? commandBarWrapperRect?.left : undefined,
        marginRight:
          // modal should be centered halfway between the open editor and the left side of the page
          !isStandaloneEditor && editorOpen ? openEditorAdjustment : 0,
      }}
      maskStyle={{
        backgroundColor: theme.nudgeModal.maskBackgroundColor,
        zIndex: Z.Z_EDITOR - 2,
        marginLeft: isStandaloneEditor ? commandBarWrapperRect?.left : undefined,
      }}
      style={styles.container}
      modalRender={() => {
        return (
          <div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            <div role="dialog" style={styles.body} aria-modal="true">
              <div style={styles.header}>
                <h2 style={styles.title}>{step?.title}</h2>
                {dismissible && <CloseButton onClick={dismissNudge} />}
              </div>

              <ContentContainer
                type="modal"
                styleOverrides={{
                  margin: '8px 0',
                }}
                step={step}
                service={service}
                markdownStyles={styles.content}
                primaryButtonStyles={styles.ctaButton}
                secondaryButtonStyles={styles.ctaSecondaryButton}
                stepCountStyles={styles.stepCount}
                stepCount={stepCount}
                renderMode={renderMode}
              />
            </div>
            {showBranding && <PoweredBy organization={organization} />}
            {!step?.is_live && renderMode === RenderMode.MOCK && (
              <DraftFooter details={{ type: 'nudge', nudgeId: nudge.id, stepId: step.id }} />
            )}
          </div>
        );
      }}
      visible
    />
  );
};

export default Modal;
