import * as t from 'io-ts';
import * as axiosInstance from './network';
import { decodeToPromise } from './generics';
import { DetailPreviewV } from './detailPreview';
import { CommandV } from './command';
import { IOrganizationType, ISearchHelpDocsPayloadType, ISearchHelpDocsResponseType } from './types';
import { AxiosRequestConfig } from 'axios';

export const HelpDocHitV = t.type({
  score: t.array(t.number),
  title: t.string,
  excerpt: t.string,
  content: t.union([t.null, t.undefined, DetailPreviewV]),
  command: CommandV,
});

export const SearchHelpDocsResponseV = t.type({
  data: t.array(t.type({ hit: HelpDocHitV })),
});

export const SearchHelpDocsPayloadV = t.partial({
  query: t.string,
  include_additional_docs: t.union([t.boolean, t.undefined]),
  doc_id: t.union([t.number, t.string, t.undefined]),
});

export class HelpDocsSearch {
  public static search = async (
    orgUID: IOrganizationType['id'],
    payload: ISearchHelpDocsPayloadType,
    axiosConfig?: AxiosRequestConfig<ISearchHelpDocsPayloadType>,
  ) => {
    const result = await axiosInstance.post<ISearchHelpDocsResponseType>(
      `search/helpexplorer/${orgUID}/`,
      payload,
      axiosConfig,
    );

    return await decodeToPromise(t.exact(SearchHelpDocsResponseV), result.data);
  };
}
